import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  getHomeFilter,
  getTravelTypes,
  getProducts,
  getProductById,
  filter,
  getCountrylist,
  getStatictext,
  getStaticValidation,
  getInsuranceStaticText,


} from "../../actions";
import isEmpty from "../../isEmpty";
import "react-multi-carousel/lib/styles.css";
import "../../index.css";
import ReactHtmlParser from "react-html-parser";

import { DateRangePicker } from "react-dates";
import { Modal } from "react-bootstrap";
import "react-dates/lib/css/_datepicker.css";
import moment from "moment";
import "react-dates/initialize";
import SelectSearch, { fuzzySearch } from "react-select-search";
import { Select } from 'react-select';
import { getHomeCategory2 } from './../../actions/home.actions';
import { setSelectedLanguage } from './../../actions/menu.actions';

import { bounce, flipInX, flipInY, flipOutX, flipOutY, flip } from 'react-animations';
import Radium, { StyleRoot } from 'radium';
import { useParams } from "react-router-dom";
import { ProductPop } from "../Comman/ProductPop";

function FilterSection(props) {
  let history = useHistory();

  useEffect(() => {

    const params = new URLSearchParams(window.location.search) // id=123
    let id = params.get('filter');
    if (id == 1) {
      var pl = localStorage.getItem('wizard');
      if (pl) {
        var wizard = JSON.parse(pl);

        SetFormData({
          depPoint: wizard.depPoint,
          desPoint: wizard.desPoint,
          no_of_people: wizard.no_of_people,
          over60: wizard.over60,
          travel_type: wizard.travel_type,
          start_date: moment(wizard.start_date).format("YYYY-MM-DD"),
          end_date: moment(wizard.end_date).format("YYYY-MM-DD"),
          submitted: false,
        });

        SetStartDate(moment(wizard.start_date));
        SetEndDate(moment(wizard.end_date));
      }
    }

  }, []);

  const [selectProId, setSelectProId] = useState();

  const {
    filterSection,
    countryList,
    travelType,
    statictext,
    product,
    productList,
    staticValidation,
    travelCategory,
    insstatictext
  } = useSelector((state) => {
    // console.log(state.home);
    return state.Home;
  });

  const dispatch = useDispatch();

  const styles = {
    bounce: {
      animation: '1s',
      animationName: Radium.keyframes(flipInY, 'flip')
    }
  }

  const [TravelTypeList, setTravelTypeList] = useState([]);
  const [TravelTypeData, setTravelTypeData] = useState([]);
  const [startDate, SetStartDate] = useState();
  const [startDateText, SetStartDateText] = useState();
  const [endDateText, SetEndDateText] = useState();
  const [departureText, SetDepartureText] = useState();
  const [destinationText, SetDestinationText] = useState();
  const [peopleText, SetPeopleText] = useState();
  const [travelText, SetTravelText] = useState();
  const [endDate, SetEndDate] = useState();
  const [focusedInput, setFocusedInput] = useState();
  const [heading, SetHeading] = useState("");
  const [heading2, SetHeading2] = useState("");
  const [text3, SetText3] = useState("");
  const [text4, SetText4] = useState("");
  const [departureplace, SetDepartureplace] = useState("");
  const [destinationplace, SetDestinationplace] = useState("");
  const [coverageplace, SetCoverageplace] = useState("");

  const [travelplace, SetTravelplace] = useState("");
  const [selectnoplace, SetSelectnoplace] = useState("");
  const [anytravellerplace, SetAnytravellerplace] = useState("");
  const [plantypeplace, SetPlantypeplace] = useState("");
  const [slctproductplace, SetSlctproductplace] = useState("");

  const [backImage, SetBackImage] = useState("");
  const [products, setProducts] = useState([]);
  const [productData, setProductData] = useState([]);
  const [productDucments, setProductDucments] = useState([]);
  const [productFeatures, setProductFeatures] = useState([]);
  const [productCategoryFeatures, setProductCategoryFeatures] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [countries, setCountries] = useState([]);
  const [validatestart, SetValidatestart] = useState("");
  const [validateend, SetValidateend] = useState("");
  const [validatedepart, SetValidatedepart] = useState("");
  const [validatedest, SetValidatedest] = useState("");
  const [validatenopeople, SetValidatenopeople] = useState("");
  const [validatetravel, SetValidatetravel] = useState("");
  const [go, SetGo] = useState("");
  const [categoryList, setCategoryList] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState();

  const [planplace, SetPlanplace] = useState("");
  const [cmprplace, SetCmprplace] = useState("");
  const [slctdplanplace, SetSlctdplanplace] = useState("");
  const [cmpareplanplace, SetCmpareplanplace] = useState("");
  const [nosearchplace, SetNosearchplace] = useState("");
  const [plansumryplace, SetPlansumryplace] = useState("");
  const [benefitplace, SetBenefitplace] = useState("");
  const [detailsplace, SetDetailsplace] = useState("");
  const [slctnote, SetSlctnote] = useState("");

  const [emailId, SetEmailId] = useState("");
  const [formData, SetFormData] = useState({
    depPoint: "",
    desPoint: "",
    no_of_people: "",
    over60: "0",
    travel_type: "0",
    start_date: "",
    end_date: "",
    submitted: false,
  });

  // end suggestions

  const handleDatesChange = ({ startDate, endDate }) => {
    SetStartDate(startDate);
    SetEndDate(endDate);
    SetFormData({
      ...formData,
      start_date: moment(startDate).format("YYYY-MM-DD"),
      end_date: moment(endDate).format("YYYY-MM-DD"),
    });
  };

  const handleInputChange = (e) => {

    let name = e.target.name;
    let value = e.target.value;

    SetFormData({ ...formData, [name]: value });
  };

  const handleCategory = (e) => {
    let name = e.target.name;
    let cat_id = e.target.value;
    setSelectedCategory("0");
    //alert(cat_id);
    if (cat_id !== '0') {

      setSelectProId(0);

      dispatch(getProducts(cat_id));
      window.$("#selectproductModal").modal("show");
    }

  };

  const handlePlanCategory = (e) => {
    // console.log(e)
    let cat_id = e;
    setSelectedCategory("0");
    //alert(cat_id);
    if (cat_id !== '0') {

      setSelectProId(0);

      dispatch(getProducts(cat_id));
      window.$("#selectproductModal").modal("show");
    }
  }


  const submit = (e) => {
    e.preventDefault();
    SetFormData({ ...formData, submitted: true });

    if (
      startDate &&
      endDate &&
      formData.depPoint &&
      formData.desPoint &&
      formData.no_of_people &&
      formData.travel_type != "0"
    ) {
      dispatch(filter(formData));

      localStorage.setItem('wizard', JSON.stringify(formData));

      history.push("/shop-insurance-filter");
    }
  };

  const handleProduct = (e) => {
    let product_id = e.target.value;
    setSelectProId(product_id);

    if (product_id !== '0') {
      dispatch(getProductById(product_id));

      setTimeout(() => openModal(), 1000);
    }

  };

  const openModal = () => setIsOpen(true);
  const closeModal = () => setIsOpen(false);

  useEffect(() => {
    dispatch(getHomeFilter(1));
    dispatch(getTravelTypes());
    dispatch(getProducts());
    dispatch(getCountrylist());
    dispatch(getStatictext(208));
    dispatch(getStaticValidation(210));
    dispatch(getInsuranceStaticText());
  }, [dispatch]);

  useEffect(() => {
    if (!isEmpty(filterSection)) {
      const items = filterSection.items;
      if (!isEmpty(items)) {
        SetHeading(items[0].name_en);
        SetHeading2(items[1].name_en);
        SetText3(items[2].name_en);
        SetText4(items[3].name_en);
        SetStartDateText(items[5].name);
        SetEndDateText(items[6].name);
        SetDepartureText(items[7].name);
        SetDestinationText(items[8].name);
        SetPeopleText(items[9].name);
        SetTravelText(items[11].name);
        SetGo(items[12].name);

        SetBackImage(items[4].image);
      }
    }
    if (!isEmpty(staticValidation)) {
      SetValidatestart(staticValidation.items[0].name);
      SetValidateend(staticValidation.items[1].name);
      SetValidatedepart(staticValidation.items[2].name);
      SetValidatedest(staticValidation.items[3].name);
      SetValidatetravel(staticValidation.items[4].name);
      SetValidatenopeople(staticValidation.items[5].name);
    }
  }, [filterSection, statictext, staticValidation]);

  useEffect(() => {
    if (!isEmpty(travelType)) {
      setTravelTypeList(travelType.items);
    }
    if (!isEmpty(statictext)) {
      SetDepartureplace(statictext.items[0].name);
      SetDestinationplace(statictext.items[1].name);
      SetCoverageplace(statictext.items[2].name);
      SetTravelplace(statictext.items[3].name);
      SetSelectnoplace(statictext.items[4].name);
      SetAnytravellerplace(statictext.items[5].name);
      SetPlantypeplace(statictext.items[6].name);
      SetSlctproductplace(statictext.items[7].name);
      SetSlctproductplace(statictext.items[7].name);
    }

    if (!isEmpty(insstatictext)) {
      SetPlanplace(insstatictext.items[0].name);
      SetCmprplace(insstatictext.items[1].name);
      SetSlctdplanplace(insstatictext.items[2].name);
      SetCmpareplanplace(insstatictext.items[3].name);
      SetNosearchplace(insstatictext.items[4].name);
      SetPlansumryplace(insstatictext.items[5].name);
      SetBenefitplace(insstatictext.items[6].name);
      SetDetailsplace(insstatictext.items[7].name);
      SetSlctnote(insstatictext.items[8].name);
    }
  }, [travelType, statictext, insstatictext]);

  useEffect(() => {
    if (!isEmpty(productList)) {

      var pro = productList;

      setProducts(productList);
    } else {
      setProducts([]);

    }


  }, [productList]);

  useEffect(() => {
    if (!isEmpty(travelCategory)) {


      var pro = travelCategory;

      if (!isEmpty(pro)) {
        setCategoryList(pro.categories);
      }
    }
  }, [travelCategory]);

  useEffect(() => {
    if (!isEmpty(product)) {
      if (!isEmpty(product)) {
        setProductDucments(product.documents);
        setProductFeatures(product.features);
        setProductCategoryFeatures(product.category_feature);

        setProductData(product);
      }
    }

  }, [product]);

  useEffect(() => {
    if (!isEmpty(countryList)) {
      if (!isEmpty(countryList)) {
        setCountries(countryList);
      }
    }
  }, [countryList]);

  const handledDep = (e) => {
    var vl = e;

    SetFormData({ ...formData, depPoint: vl });
  };

  const handledDes = (e) => {
    var vl = e;
    SetFormData({ ...formData, desPoint: vl });
  };

  const handledTrav = (e) => {

    var vl = e;

    SetFormData({ ...formData, travel_type: vl });
  };

  // console.log(formData)

  const [formsData, SetFormsData] = useState({
    email: "",
    itemid: "",
  });

  const [show, setShow] = useState(false);

  const showQuote = (d) => (e) => {
    //alert(d);
    SetEmailId(d);
    closeModal();
    window.$("#selectproductModal").modal("hide");
    setShow(true);
  };

  let yearList = () => {
    let minOffset = 0, maxOffset = 20;
    let thisYear = (new Date()).getFullYear();
    let allYears = [];
    for (let x = 0; x <= maxOffset; x++) {
      allYears.push(thisYear + x)
    }

    return allYears.map((x) => { return (<option key={x}>{x}</option>) });
  }

  const handleClose = () => setShow(false);

  let travelTypeData = TravelTypeList.map((element) => {
    let data = {
      "name": element.name_en,
      "value": element.id
    }
    return data;
  }
  )

  let planTypeData = categoryList.map((element) => {
    let planData = {
      "name": element.name_en,
      "value": element.id
    }
    return planData;
  }
  )

  const [emailLabel, SetEmailLabel] = useState("");
  const [emailValidation, SetEmailValidation] = useState("");
  const [submitText, SetSubmitText] = useState("");
  const [cmessage, SetCMessage] = useState("");

  return (
    <section
      className="section_space banner_outer"
      style={{ backgroundImage: `url(${backImage}` }}
    >
      <div className="overlay"></div>
      <div className="container">
        <div className="row align-items-center">
          <div className="col-md-12">
            <div className="banner_inner">
              <div className="travel_title aos-item" data-aos="fade-up">
                {heading}
              </div>
              <div className="travel_subtitle aos-item" data-aos="fade-up">
                {ReactHtmlParser(heading2)}
              </div>
              <div
                className="banner_date_time_outer aos-item"
                data-aos="zoom-in"
              >
                <form>
                  <div className="banner_datetime_upper">
                    <div className="banner_datetime_first first_div">
                      <div className="start_upper">
                        <div className="start_date">
                          <label className="custom_label">
                            {startDateText}
                          </label>
                        </div>
                        <div className="end_date">
                          <label className="custom_label">{endDateText}</label>
                        </div>
                      </div>

                      <DateRangePicker

                        startDate={startDate} // momentPropTypes.momentObj or null,
                        startDateId="your_unique_start_date_id" // PropTypes.string.isRequired,
                        endDate={endDate} // momentPropTypes.momentObj or null,
                        endDateId="your_unique_end_date_id" // PropTypes.string.isRequired,
                        onDatesChange={handleDatesChange} // PropTypes.func.isRequired,
                        focusedInput={focusedInput} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
                        onFocusChange={(focusedInput) =>
                          setFocusedInput(focusedInput)
                        } // PropTypes.func.isRequired,
                        horizontalMonthPadding="3"
                        horizontalMargin="3"
                        withPortal={false}
                        numberOfMonths="1"
                        // placeholder="nu"
                        startDatePlaceholderText={startDateText}
                        endDatePlaceholderText={endDateText}
                        renderMonthElement={({ month, onMonthSelect, onYearSelect }) => (
                          <div style={{ display: 'flex', justifyContent: 'center' }}>
                            <div>
                              <select className="select_custom2"
                                value={month.month()}
                                onChange={(e) => { onMonthSelect(month, e.target.value); }}
                              >
                                {moment.months().map((label, value) => (
                                  <option value={value}>{label}</option>
                                ))}
                              </select>
                            </div>
                            <div>
                              <select className="select_custom2"
                                value={month.year()}
                                onChange={(e) => { onYearSelect(month, e.target.value); }}
                              >

                                {yearList()}
                              </select>
                            </div>
                          </div>
                        )}

                      />
                      <div className="start_upper">
                        <div className="start_date">
                          {formData.submitted && !startDate && (
                            <div className="help-block"> {validatestart}</div>
                          )}
                        </div>
                        <div className="end_date">
                          {formData.submitted && !endDate && (
                            <div className="help-block"> {validateend}</div>
                          )}
                        </div>
                      </div>
                    </div>

                    <div className="banner_datetime_first">
                      <div className="start_date">
                        <label className="custom_label">{departureText}</label>

                        <SelectSearch
                          options={countries}
                          value={formData.depPoint}
                          search
                          filterOptions={fuzzySearch}
                          onChange={handledDep}
                          name="depPoint"
                          placeholder={departureplace}
                        />
                      </div>
                      {formData.submitted && !formData.depPoint && (
                        <div className="help-block"> {validatedepart}</div>
                      )}
                    </div>
                    <div className="banner_datetime_first">
                      <div className="start_date">
                        <label className="custom_label">
                          {destinationText}
                        </label>
                        <SelectSearch
                          options={countries}
                          value={formData.desPoint}
                          search
                          filterOptions={fuzzySearch}
                          onChange={handledDes}
                          name="desPoint"
                          placeholder={destinationplace}
                        />
                      </div>
                      {formData.submitted && !formData.desPoint && (
                        <div className="help-block"> {validatedest}</div>
                      )}
                    </div>
                  </div>
                  <div className="banner_datetime_lower">
                    <div className="banner_datetime_firstlower help_block_first_lower">
                      <button

                        type="button"
                        onfocus="dropdown(0)"
                        onblur="set()"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseExample"
                        aria-expanded="false"
                        aria-controls="collapseExample"
                      >
                        <label className="custom_label" >
                          {peopleText}
                          <i className="fal fa-chevron-down"  ></i>
                        </label>
                        <input
                          className="custom_input cpr"
                          type="text"
                          value={formData.no_of_people}
                          placeholder={selectnoplace}
                          name="no_of_people"
                          onChange={handleInputChange}
                          data-u="cpr"


                        />
                      </button>

                      {formData.submitted && !formData.no_of_people && (
                        <div className="help-block"> {validatenopeople}</div>
                      )}

                      <div className="collapse c-new" id="collapseExample">
                        <div className="card card-body card_custom">

                          <label class="custom_label">{anytravellerplace}</label>

                          <div className="custom_radio">
                            <label className="radio-container">
                              Yes
                              <input
                                type="radio"
                                name="over60"
                                checked={formData.over60 === "1"}
                                value="1"
                                onChange={handleInputChange}
                                className="cpr"
                              />
                              <span className="checkmark cpr"></span>
                            </label>
                            <label className="radio-container">
                              No
                              <input
                                type="radio"
                                checked={formData.over60 === "0"}
                                name="over60"
                                value="0"
                                onChange={handleInputChange}
                                className="cpr"
                              />
                              <span className="checkmark cpr"></span>
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="banner_datetime_firstlower center">
                      <label className="custom_label">{travelText} </label>
                      <SelectSearch
                        options={travelTypeData}
                        value={formData.travel_type}
                        name="travel_type"
                        search
                        filterOptions={fuzzySearch}
                        onChange={handledTrav}
                        placeholder={travelplace}
                      />

                      {formData.submitted && formData.travel_type == 0 && (
                        <div className="help-block"> {validatetravel}</div>
                      )}
                    </div>
                    <div className="banner_datetime_firstlower center right">
                      <button className="go_btn" onClick={submit}>
                        <i className="fal fa-long-arrow-right"></i>{go}
                      </button>
                    </div>
                  </div>
                </form>
              </div>

              <div className="already_know_plan aos-item" data-aos="fade-up" id="category">
                <span className="plan_span">{text3}</span>

                <SelectSearch
                  options={planTypeData}
                  value={selectedCategory}
                  name="category_id"
                  onChange={handlePlanCategory}
                  placeholder={plantypeplace}
                />
              </div>

              <div className="travel_subtitle aos-item" data-aos="fade-up" >
                {text4}
              </div>
              <a className="down_arrow" href="#category">
                <img
                  className="bounce"
                  src="assets/images/d_arrow.png"
                  alt="d_arrow"
                />
              </a>
            </div>
          </div>
        </div>
      </div>

      {/* product modal */}

      <StyleRoot>

        <div
          className="modal custom_modal fade"
          id="selectproductModal"
          tabindex="-1"
          aria-labelledby="selectproductModalLabel"
          aria-hidden="true"
          style={styles.bounce}
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
              <div className="modal-body select_plan_body">
                <div className="agent_title buytravel_title">
                  {slctproductplace}
                </div>
                <select
                  className="select_custom selectplanselect"
                  name="product_id"
                  onChange={handleProduct}
                  value={selectProId}
                >
                  <option value="0" >
                    {slctproductplace}
                  </option>
                  {products.map((item, index) => (
                    <option value={item.id}>{item.name_en}</option>
                  ))}
                </select>
              </div>
            </div>
          </div>
        </div>
      </StyleRoot>

      {/* product detail */}
      <StyleRoot>
        <Modal
          show={isOpen}
          onHide={closeModal}
          className="custom_modal custom_selectplan fade"
        >
          <div style={styles.bounce}>
            <button
              type="button"
              className="btn-close"
              onClick={closeModal}
            ></button>

            <div className="modal-body select_plan_body select_plan_body1">
              <div className="agent_title buytravel_title">
                {productData.name_en}
              </div>
              <p className="p_text">
                {productData.category_name}, {productData.insurance_name},{" "}
                {productData.term_name}
              </p>
              <div className="plan_tag_outer">
                {productDucments.map((item, index) => (
                  <a
                    className="policy_wording"
                    href={item.link_url}
                    target="_blank"
                    key={index}
                  >
                    <i className="fal fa-long-arrow-down"></i>
                    {item.name}
                  </a>
                ))}
              </div>
            </div>

            <div className="modal-body select_plan_body select_plan_body1 select_plan_body2">
              <div className="row">
                {productCategoryFeatures.map((item, index) => (
                  <div className="col-md-6">
                    <div className="hospital_div">
                      <img src={item.image} alt="image" />
                      <p>{item.name}</p>
                    </div>
                  </div>
                ))}
              </div>
            </div>

            <div className="modal-body select_plan_body select_plan_body1 plan_summary">
              <div className="row">
                <div className="col-md-12">
                  <div className="plan_summary_title">{plansumryplace}</div>
                  <table className="table table_custom">
                    <thead>
                      <tr>
                        <th style={{ width: "40%" }} scope="col">
                          {benefitplace}                    </th>
                        <th style={{ width: "60%" }} scope="col">
                          {detailsplace}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {productFeatures.map((item, index) => (
                        <tr key={index}>
                          <td style={{ width: "40%" }}>
                            <b>{item.feature_name}</b>
                          </td>
                          <td style={{ width: "60%" }}>{item.value}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>

            <div className="modal-body select_plan_body select_plan_body1 select_plan_body2">
              <div className="row">
                <div className="col-md-12">
                  <div className="view_plan_inner">
                    {productData.purchase_type != 1 ? (
                      <>
                        <a
                          onClick={showQuote(productData.id)}
                          className="go_btn view_plan_btn"
                        >
                          <i className="fal fa-long-arrow-right"></i>
                          {productData.btn_name}
                        </a>
                      </>
                    ) : (
                      <a
                        className="go_btn view_plan_btn"
                        href={productData.url}
                        target="_blank"
                      >
                        <i className="fal fa-long-arrow-right"></i>
                        {productData.btn_name}
                      </a>
                    )}
                  </div>
                </div>
              </div>
            </div>

          </div>
        </Modal>
      </StyleRoot>

      <ProductPop formsData={formsData} itemId={emailId} key={emailId} show={show} closeShow={handleClose} />

    </section>
  );
}

export { FilterSection };
