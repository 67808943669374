import React, { Fragment, useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  getBusinessGroups,
  getBusinessGroups1,
  getBusinessGroups2,
  getBusinessGroupsAll
} from "../../actions";
import isEmpty from "../../isEmpty";
import { useHistory } from "react-router-dom";

import "react-multi-carousel/lib/styles.css";
import "../../index.css";
import { Modal } from "react-bootstrap";
import { getContactForm, saveContactForm } from "../../actions";
import { ServicePopup } from "../Comman/ServicePopup";


import ReactHtmlParser from "react-html-parser";

function BusinessGroups(props) {
  let history = useHistory();
  const {
    businessGroups, 
    businessGroups1,
    businessGroups2,
    businessGroupsAll,
    businessTab,
  } = useSelector((state) => {
    return state.Business;
  });
  

  const dispatch = useDispatch();









  const [head1, SetHead1] = useState([]);
  const [head2, SetHead2] = useState([]);
  const [head3, SetHead3] = useState([]);

  const [subHead1, SetSubHead1] = useState([]);
  const [subHead2, SetSubHead2] = useState([]);
  const [subHead3, SetSubHead3] = useState([]);


  const [imgg1, SetImgg1] = useState([]);
  const [imgg2, SetImgg2] = useState([]);
  const [imgg3, SetImgg3] = useState([]);


  const [popid1, setPopid1] = useState([]);
  const [popid2, setPopid2] = useState([]);
  const [popid3, setPopid3] = useState([]);


  const [btn1, SetBtn1] = useState([]);
  const [btn2, SetBtn2] = useState([]);
  const [btn3, SetBtn3] = useState([]);

  const [url1, SetUrl1] = useState([]);
  const [url2, SetUrl2] = useState([]);
  const [url3, SetUrl3] = useState([]);

  const [isOpen, SetIsOpen] = useState(false);
  // const openModal = () => SetIsOpen(true);
  // const closeModal = () => SetIsOpen(false);

  const closeModal = () => {
    SetIsOpen(false);
  };

  const openModal = () => {
    SetIsOpen(true);
  };


  const [isOpen2, SetIsOpen2] = useState(false);
  const openModal2 = () => SetIsOpen2(true);
  const closeModal2 = () => SetIsOpen2(false);

  const [responsive2, SetResponsive2] = useState({
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 2,
      slidesToSlide: 1, // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1024, min: 768 },
      items: 2,
      slidesToSlide: 1, // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 550, min: 1 },
      items: 1,
      slidesToSlide: 1, // optional, default to 1.
    },
  });
  const [sliderHeading, SetSliderHeading] = useState("");
  const [currentSection, SetCurrentSection] = useState(1);

  const [slides, SetSlides] = useState([]);

  const [slides1, SetSlides1] = useState([]);
  const [slides2, SetSlides2] = useState([]);

  const [refresh, doRefresh] = useState(0);


  const popupProcess = (id) => {

    SetCurrentSection(id);
    // alert(id);
    doRefresh(prev => prev + 1);
    // alert(isOpen2);
    openModal();
  }



  useEffect(() => {
    dispatch(getBusinessGroups());
    dispatch(getBusinessGroups1());
    dispatch(getBusinessGroups2());
    dispatch(getBusinessGroupsAll());
  }, [dispatch]);

  useEffect(() => {
    if (
      !isEmpty(businessGroups) &&
      !isEmpty(businessGroups1) &&
      !isEmpty(businessGroups2)  &&
      !isEmpty(businessGroupsAll)  
    ) {
      console.log("all data is ",businessGroupsAll);
      const items = businessGroups.items;
      const slides = businessGroups.reviews;

      const items1 = businessGroups1.items;
      const slides1 = businessGroups1.reviews;

      const items2 = businessGroups2.items;
      const slides2 = businessGroups2.reviews;

      if (!isEmpty(items)) {
        SetSliderHeading(items[0].name_en);
        SetHead1(items[0].name);
        SetSubHead1(items[1].name);
        SetImgg1(items[2].image);
        SetBtn1(items[3].name);
        SetUrl1(items[3].url);
        setPopid1(items[0].pop_id);

        SetHead2(items1[0].name);
        SetSubHead2(items1[1].name);
        SetImgg2(items1[2].image);
        SetBtn2(items1[3].name);
        SetUrl2(items1[3].url);
        setPopid2(items1[0].pop_id);


        SetHead3(items2[0].name);
        SetSubHead3(items2[1].name);
        SetImgg3(items2[2].image);
        SetBtn3(items2[3].name);
        SetUrl3(items2[3].url);
        setPopid3(items2[0].pop_id);

      }

      if (!isEmpty(slides) && !isEmpty(slides2) && !isEmpty(slides1)) {
        SetSlides(slides);
        SetSlides2(slides2);
        SetSlides1(slides1);
      }
    }
  }, [businessGroups, businessGroups1, businessGroups2,businessGroupsAll]);

  useEffect(() => {
    if (!isEmpty(businessTab)) {
      const items = businessTab.items;
      const ser = businessTab.slides;


    }

  }, [businessTab]);





  return (
    <>
    {businessGroupsAll?.map((item,index)=>(
      
      
         <section className={`section_space business_group_outer ${item.class} `} id={`businessGroup${index++}`}>
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-5">
            <div class="business_left">
              <img src={item.sectionitems[2].image} alt="business1" />
            </div>
          </div>
          <div class="col-md-7">
            <div class="business_right">
              <div
                class="agent_title buytravel_title brief_title aos-item aos-init aos-animate"
                data-aos="fade-up"
              >
                {item.sectionitems[0].name}
              </div>
              {/* <p class="p_small">Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat. Duis autem vel eum iriure dolor in hendrerit in vulputate velit esse molestie consequat, vel illum dolore eu feugiat nulla facilisis at vero eros et accumsan et iusto odio dignissim qui blandit praesent luptatum zzril delenit augue duis dolore te feugait nulla facilisi.</p> */}
              <p class="p_small" style={{ whiteSpace:'break-spaces'}} >{ReactHtmlParser(item.sectionitems[1].name)}</p>
              <ul class="service_ul">
                {item.tablist.map((tabs, index) => (
                  <li>
                    <img src={tabs.image} alt="icon1" />
                    <span></span>
                    {ReactHtmlParser(tabs.name_en)}{" "}
                  </li>
                ))}
              </ul>
              <div class="view_plan_inner">
                <a class="go_btn view_plan_btn chat_btn" onClick={() => popupProcess(item.sectionitems[0].pop_id)} >
                  <i class="fal fa-long-arrow-right"></i>
                  {item.sectionitems[3].name}
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    ))}
  

   
     

      <ServicePopup refresh={refresh} key={refresh} section_id={currentSection} isOpen={isOpen} isOpen2={isOpen2} closeModal={closeModal} closeModal2={closeModal2} openModal2={openModal2} />



      {/* <Modal
        show={isOpen}
        onHide={closeModal}
        className="custom_modal"
        tabindex="-1"
        aria-labelledby="selectplanModalLabel"
        aria-hidden="true"
      >
        <button type="button" class="btn-close" onClick={closeModal}></button>
        <div class="modal-body select_plan_body select_plan_body1 request_info_body">
          <div class="agent_title buytravel_title">{pophead}</div>
          <p class="p_text">{popsubhead}</p>
        </div>
        <div class="modal-body select_plan_body select_plan_body1 select_plan_body2">
          <div class="row">
            <div class="col-md-12">
              <form method="post" >
              <div class="check_apply">
                <p class="p_text">{poptitle}</p>

                <div class="request_check_outer">
                  <div class="request_check row">
                    {service.map((item, index) => (
                      <label class="signup_check col-md-6" key={index}>
                        {item.name_en}
                        <input name="service" type="radio" value={item.id} checked={check==item.id ? true:false}
                      onChange={handleChange} />
                        <span class="checkmark"></span>
                      </label>
                      
                    ))}
                    <br/>
                    <span class="" style={{ color: "red" }}>
                      {errors["check"]}
                    </span>
                  </div>
                </div>
                <br/>
                <div class="contact_input_inner">
                  <div class="contact_info_first ">
                    <label class="custom_label">{popname}</label>
                    <div className="email_input_div2">
                      <input
                        class="contact_input_field"
                        type="text"
                        placeholder=""
                        name="name"
                        onChange={handleChange}
                        value={name}
                      />
                      <br/>
                      <span class="error_contact2" style={{ color: "red" }}>
                      {errors["name"]}
                      </span>
                    </div> 
                  </div>
                 
                  <div class="contact_info_first">
                    <label class="custom_label">{popemail}</label>
                    <div className="email_input_div2">
                      <input
                        class="contact_input_field"
                        type="text"
                        placeholder=""
                        name="email"
                        onChange={handleChange}
                        value={email}
                      />
                      <br/>
                      <span class="error_contact2" style={{ color: "red" }}>
                        {errors["email"]}
                      </span>
                   </div>
                  </div>
                  

                </div>
                <div class="view_plan_inner submit_outr">
                  <a
                    class="go_btn view_plan_btn"
                    data-aos="fade-up"
                   
                    onClick={handleSubmit}
                  >
                    {popsub}
                  </a>
                </div>
              </div>
            </form>
            </div>
          </div>
        </div>
      </Modal>
   

      <div
        className="modal custom_modal fade "
        id="thankyouModal"
        tabindex="-1"
        aria-labelledby="thankyouModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
            <div className="modal-body select_plan_body">
              <div className="agent_title buytravel_title">{thanks}</div>
              <p className="thankyou_p">{ReactHtmlParser(thanksdesc)}</p>
            </div>
          </div>
        </div>
      </div> */}
    </>
  );
}

export { BusinessGroups };
