import { appConstants } from "../helpers/app-constants";

const intialState = {
  faqSection: [],
  expertSection: [],
  faqDynamic: [],
  faqmetatag: [],
  legalmetatag: [],
  faqLandingDynamic: [],
  faqLandingSection: [],
  landingexpertSection: [],
  landingfaqmetatag: [],
};

// contact reducer
export const Faq = (state = intialState, action) => {
  switch (action.type) {
    case appConstants.GET_FAQ_BANNER:
      return {
        ...state,
        faqSection: action.payload,
      };
    case appConstants.GET_LANDING_FAQ_BANNER:
      return {
        ...state,
        faqLandingSection: action.payload,
      };

    case appConstants.GET_EXPERT_SECTION:
      return {
        ...state,
        expertSection: action.payload,
      };
    case appConstants.GET_FAQ_DYNAMIC:
      return {
        ...state,
        faqDynamic: action.payload,
      };
    case appConstants.GET_FAQ_METATAG:
      return {
        ...state,
        faqmetatag: action.payload,
      };
    case appConstants.GET_LANDING_FAQ_METATAG:
      return {
        ...state,
        landingfaqmetatag: action.payload,
      };

    case appConstants.GET_LANDING_FAQ_DYNAMIC:
      return {
        ...state,
        faqLandingDynamic: action.payload,
      };
    case appConstants.GET_LANDING_EXPERT_SECTION:
      return {
        ...state,
        landingexpertSection: action.payload,
      };
    default:
      return state;
  }
};
