import axios from "axios";
import { returnErrors } from "../actions/error.action";
import { appConstants, config } from "../helpers/app-constants";


import { loaderDispatch } from "../helpers/app-constants";

export const getChooseBanner = () => (dispatch) => {
  loaderDispatch(dispatch, true);
  axios
    .get(appConstants.BACKEND_URL + "getHomeSectionItem/17", config)
    .then((res) => {
      loaderDispatch(dispatch, false);
      dispatch({
        type: appConstants.GET_CHOOSE_BANNER_SECTION,
        payload: res.data.data,
      });
    })
    .catch((err) => {
      loaderDispatch(dispatch, false);
      dispatch(returnErrors(err.response, err.response));
    });
};
export const getLandingChooseBanner = (id) => (dispatch) => {
  loaderDispatch(dispatch, true);
  axios
    .get(appConstants.BACKEND_URL + "getHomeSectionItem/" + id, config)
    .then((res) => {
      loaderDispatch(dispatch, false);
      dispatch({
        type: appConstants.GET_LANDING_CHOOSE_BANNER_SECTION,
        payload: res.data.data,
      });
    })
    .catch((err) => {
      loaderDispatch(dispatch, false);
      dispatch(returnErrors(err.response, err.response));
    });
};

export const getChooseMetatag = () => (dispatch) => {
  loaderDispatch(dispatch, true);
  axios
    .get(appConstants.BACKEND_URL + "getHomeSectionItem/215", config)
    .then((res) => {
      loaderDispatch(dispatch, false);
      dispatch({
        type: appConstants.GET_CHOOSE_METATAG,
        payload: res.data.data,
      });
    })
    .catch((err) => {
      loaderDispatch(dispatch, false);
      dispatch(returnErrors(err.response, err.response));
    });
};
export const getLandingChooseMetatag = (id) => (dispatch) => {
  loaderDispatch(dispatch, true);
  axios
    .get(appConstants.BACKEND_URL + "getHomeSectionItem/" + id, config)
    .then((res) => {
      loaderDispatch(dispatch, false);
      dispatch({
        type: appConstants.GET_LANDING_CHOOSE_METATAG,
        payload: res.data.data,
      });
    })
    .catch((err) => {
      loaderDispatch(dispatch, false);
      dispatch(returnErrors(err.response, err.response));
    });
};

export const getChooseMap = () => (dispatch) => {
  loaderDispatch(dispatch, true);
  axios
    .get(appConstants.BACKEND_URL + "getHomeSectionItem/18", config)
    .then((res) => {
      loaderDispatch(dispatch, false);
      dispatch({ type: appConstants.GET_CHOOSE_MAP, payload: res.data.data });
    })
    .catch((err) => {
      loaderDispatch(dispatch, false);
      dispatch(returnErrors(err.response, err.response));
    });
};
export const getLandingChooseMap = (id) => (dispatch) => {
  loaderDispatch(dispatch, true);
  axios
    .get(appConstants.BACKEND_URL + "getHomeSectionItem/" + id, config)
    .then((res) => {
      loaderDispatch(dispatch, false);
      dispatch({
        type: appConstants.GET_LANDING_CHOOSE_MAP,
        payload: res.data.data,
      });
    })
    .catch((err) => {
      loaderDispatch(dispatch, false);
      dispatch(returnErrors(err.response, err.response));
    });
};

export const getLandingInhouseSection = (id) => (dispatch) => {
  loaderDispatch(dispatch, true);
  axios
    .get(appConstants.BACKEND_URL + "getHomeSectionItem/" + id, config)
    .then((res) => {
      loaderDispatch(dispatch, false);
      dispatch({
        type: appConstants.GET_LANDING_INHOUSE_SECTION,
        payload: res.data.data,
      });
    })
    .catch((err) => {
      loaderDispatch(dispatch, false);
      dispatch(returnErrors(err.response, err.response));
    });
};
export const getInhouseSection = () => (dispatch) => {
  loaderDispatch(dispatch, true);
  axios
    .get(appConstants.BACKEND_URL + "getHomeSectionItem/21", config)
    .then((res) => {
      loaderDispatch(dispatch, false);
      dispatch({
        type: appConstants.GET_INHOUSE_SECTION,
        payload: res.data.data,
      });
    })
    .catch((err) => {
      loaderDispatch(dispatch, false);
      dispatch(returnErrors(err.response, err.response));
    });
};

export const getLandingMshStory = (id) => (dispatch) => {
  loaderDispatch(dispatch, true);
  axios
    .get(appConstants.BACKEND_URL + "getHomeSectionItem/" + id, config)
    .then((res) => {
      loaderDispatch(dispatch, false);
      dispatch({
        type: appConstants.GET_LANDING_MSH_STORY,
        payload: res.data.data,
      });
    })
    .catch((err) => {
      loaderDispatch(dispatch, false);
      dispatch(returnErrors(err.response, err.response));
    });
};

export const getMshStory = () => (dispatch) => {
  loaderDispatch(dispatch, true);
  axios
    .get(appConstants.BACKEND_URL + "getHomeSectionItem/22", config)
    .then((res) => {
      loaderDispatch(dispatch, false);
      dispatch({ type: appConstants.GET_MSH_STORY, payload: res.data.data });
    })
    .catch((err) => {
      loaderDispatch(dispatch, false);
      dispatch(returnErrors(err.response, err.response));
    });
};

export const getLandingLiveStory = (id) => (dispatch) => {
  loaderDispatch(dispatch, true);
  axios
    .get(appConstants.BACKEND_URL + "getHomeSectionItem/" + id, config)
    .then((res) => {
      loaderDispatch(dispatch, false);
      dispatch({
        type: appConstants.GET_LANDING_LIVE_STORY,
        payload: res.data.data,
      });
    })
    .catch((err) => {
      loaderDispatch(dispatch, false);
      dispatch(returnErrors(err.response, err.response));
    });
};
export const getLiveStory = () => (dispatch) => {
  loaderDispatch(dispatch, true);
  axios
    .get(appConstants.BACKEND_URL + "getHomeSectionItem/23", config)
    .then((res) => {
      loaderDispatch(dispatch, false);
      dispatch({ type: appConstants.GET_LIVE_STORY, payload: res.data.data });
    })
    .catch((err) => {
      loaderDispatch(dispatch, false);
      dispatch(returnErrors(err.response, err.response));
    });
};

export const getChooseSlider = () => (dispatch) => {
  loaderDispatch(dispatch, true);
  axios
    .get(appConstants.BACKEND_URL + "getChooseSilder", config)
    .then((res) => {
      // console.log(res);
      loaderDispatch(dispatch, false);
      dispatch({
        type: appConstants.GET_CHOOSE_SLIDER,
        payload: res.data.data,
      });
    })
    .catch((err) => {
      // console.log(err);
      loaderDispatch(dispatch, false);
      dispatch(returnErrors(err.response, err.response));
    });
};
export const getLandingChooseSlider = (id) => (dispatch) => {
  loaderDispatch(dispatch, true);
  axios
    .get(appConstants.BACKEND_URL + "getLandingChooseSilder/" + id, config)
    .then((res) => {
      // console.log(res);
      loaderDispatch(dispatch, false);
      dispatch({
        type: appConstants.GET_LANDING_CHOOSE_SLIDER,
        payload: res.data.data,
      });
    })
    .catch((err) => {
      // console.log(err);
      loaderDispatch(dispatch, false);
      dispatch(returnErrors(err.response, err.response));
    });
};

export const getLandingChooseReviews = (id) => (dispatch) => {
  loaderDispatch(dispatch, true);
  axios
    .get(appConstants.BACKEND_URL + "getLandingChooseReviews/" + id, config)
    .then((res) => {
      // console.log(res);
      loaderDispatch(dispatch, false);
      dispatch({
        type: appConstants.GET_LANDING_CHOOSE_REVIEWS,
        payload: res.data.data,
      });
    })
    .catch((err) => {
      loaderDispatch(dispatch, false);
      dispatch(returnErrors(err.response, err.response));
    });
};

export const getChooseReviews = () => (dispatch) => {
  loaderDispatch(dispatch, true);
  axios
    .get(appConstants.BACKEND_URL + "getChooseReviews", config)
    .then((res) => {
      // console.log(res);
      loaderDispatch(dispatch, false);
      dispatch({
        type: appConstants.GET_CHOOSE_REVIEWS,
        payload: res.data.data,
      });
    })
    .catch((err) => {
      loaderDispatch(dispatch, false);
      dispatch(returnErrors(err.response, err.response));
    });
};

export const getLandingStorySection = (id) => (dispatch) => {
  loaderDispatch(dispatch, true);
  axios
    .get(appConstants.BACKEND_URL + "getLandingStoryReviews/" + id, config)
    .then((res) => {
      // console.log(res);
      loaderDispatch(dispatch, false);
      dispatch({
        type: appConstants.GET_LANDING_STORY_SECTION,
        payload: res.data.data,
      });
    })
    .catch((err) => {
      loaderDispatch(dispatch, false);
      dispatch(returnErrors(err.response, err.response));
    });
};
export const getStorySection = () => (dispatch) => {
  loaderDispatch(dispatch, true);
  axios
    .get(appConstants.BACKEND_URL + "getStoryReviews", config)
    .then((res) => {
      // console.log(res);
      loaderDispatch(dispatch, false);
      dispatch({
        type: appConstants.GET_STORY_SECTION,
        payload: res.data.data,
      });
    })
    .catch((err) => {
      loaderDispatch(dispatch, false);
      dispatch(returnErrors(err.response, err.response));
    });
};
