import React, { Fragment, useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getHomeViewPlan } from "../../actions";
import isEmpty from "../../isEmpty";
import "react-multi-carousel/lib/styles.css";
import "../../index.css";

function ViewPlanSection(props) {
  const { viewPlanSection } = useSelector((state) => {
    return state.Home;
  });

  const dispatch = useDispatch();
  const [btn, SetBtn] = useState({});

  useEffect(() => {
    dispatch(getHomeViewPlan());
  }, [dispatch]);

  useEffect(() => {
    if (!isEmpty(viewPlanSection)) {
      const items = viewPlanSection.items;
      if (!isEmpty(items)) {
        SetBtn(items[0]);
      }
    }
  }, [viewPlanSection]);

  return (
    <section className="section_space view_plan_outer">
      <div className="container custom_container">
        <div className="row">
          <div className="col-md-12">
            <div className="view_plan_inner aos-item" data-aos="zoom-in">
              <a href={btn.url} className="go_btn view_plan_btn">
                <i className="fal fa-long-arrow-right"></i>
                {btn.name_en}
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export { ViewPlanSection };
