export const appConstants = {
  //BACKEND_URL: "http://127.0.0.1:8000/api/",
  BACKEND_URL: 'https://msh.itechnolabs.tech/api/api/',
  //FRONTEND_URL: "http://127.0.0.1:3000",
  FRONTEND_URL: "https://msh.itechnolabs.tech",

  LOGIN_REQUEST: "USERS_LOGIN_REQUEST",
  FORGOT_REQUEST: "USERS_FORGOT_REQUEST",
  FORGOT_SUCCESS: "USERS_FORGOT_SUCCESS",
  FORGOT_FAILURE: "USERS_FORGOT_FAILURE",
  LOGIN_SUCCESS: "USERS_LOGIN_SUCCESS",
  LOGIN_FAILURE: "USERS_LOGIN_FAILURE",
  SUCCESS: "ALERT_SUCCESS",
  ERROR: "ALERT_ERROR",
  CLEAR: "ALERT_CLEAR",
  LOGOUT: "USERS_LOGOUT",
  REGISTER_REQUEST: "USERS_REGISTER_REQUEST",
  REGISTER_SUCCESS: "USERS_REGISTER_SUCCESS",
  REGISTER_FAILURE: "USERS_REGISTER_FAILURE",
  GET_MENU_LISTING: "GET_MENU_LISTING",
  SET_LANGUAGE: "SET_LANGUAGE",
  GET_LANGUAGE: "GET_LANGUAGE",
  GET_HOME_SLIDER: "GET_HOME_SLIDER",
  GET_HOME_REVIEWS: "GET_HOME_REVIEWS",
  GET_HOME_TRAVELCATGORIES: "GET_HOME_TRAVELCATGORIES",
  GET_HOME_PARTNERS: "GET_HOME_PARTNERS",
  GET_HOME_FILTER: "GET_HOME_FILTER",
  GET_HOME_AGENT: "GET_HOME_AGENT",
  GET_HOME_VIEWPLAN: "GET_HOME_VIEWPLAN",
  GET_HOME_CONTACT: "GET_HOME_CONTACT",
  GET_HOME_JOIN: "GET_HOME_JOIN",
  GET_TRAVEL_TYPE: "GET_TRAVEL_TYPE",
  GET_PRODUCTS: "GET_PRODUCTS",
  GET_PRODUCT_BY_ID: "GET_PRODUCT_BY_ID",
  GET_FILTER_PAGE: "GET_FILTER_PAGE",
  FILTER_PRODUCT: "FILTER_PRODUCT",
  GET_FILTER_PRODUCT: "GET_FILTER_PRODUCT",
  SAVE_CONTACT_FORM: "SAVE_CONTACT_FORM",
  GET_COUNTRY_LIST: "GET_COUNTRY_LIST",
  GET_ERRORS: "GET_ERRORS",
  CLEAR_ERRORS: "CLEAR_ERRORS",
  GET_CONTACT_BANNER: "GET_CONTACT_BANNER",
  GET_CONTACT_TITLE: "GET_CONTACT_TITLE",
  GET_CONTACT_ENQUIRY: "GET_CONTACT_ENQUIRY",
  GET_CONTACT_FORM: "GET_CONTACT_FORM",
  GET_CLAIM_SECTION: "GET_CLAIM_SECTION",
  GET_AGENT_SECTION: "GET_AGENT_SECTION",
  GET_LEGAL_BANNER: "GET_LEGAL_BANNER",
  GET_TERMS_POLICY: "GET_TERMS_POLICY",
  GET_REGULATE_INFO: "GET_REGULATE_INFO",
  GET_TERMS_SERVICE: "GET_TERMS_SERVICE",
  GET_CHOOSE_BANNER_SECTION: "GET_CHOOSE_BANNER_SECTION",
  GET_CHOOSE_MAP: "GET_CHOOSE_MAP",
  GET_CHOOSE_SLIDER: "GET_CHOOSE_SLIDER",
  GET_CHOOSE_REVIEWS: "GET_CHOOSE_REVIEWS",
  GET_INHOUSE_SECTION: "GET_INHOUSE_SECTION",
  GET_STORY_SECTION: "GET_STORY_SECTION",
  GET_MSH_STORY: "GET_MSH_STORY",
  GET_LIVE_STORY: "GET_LIVE_STORY",
  GET_BUSINESS_BANNER: "GET_BUSINESS_BANNER",
  GET_BUSINESS_SERVICE: "GET_BUSINESS_SERVICE",
  GET_BUSINESS_BOTTOM: "GET_BUSINESS_BOTTOM",
  GET_BUSINESS_BOTTOM2: "GET_BUSINESS_BOTTOM2",
  GET_BUSINESS_BOTTOM3: "GET_BUSINESS_BOTTOM3",
  GET_BUSINESS_TAB: "GET_BUSINESS_TAB",
  GET_BUSINESS_GROUPS: "GET_BUSINESS_GROUPS",
  GET_BUSINESS_GROUPS1: "GET_BUSINESS_GROUPS1",
  GET_BUSINESS_GROUPS2: "GET_BUSINESS_GROUPS2",
  GET_BUSINESS_GROUPS_ALL: "GET_BUSINESS_GROUPSALL",
  GET_FAQ_BANNER: "GET_FAQ_BANNER",
  GET_EXPERT_SECTION: "GET_EXPERT_SECTION",
  GET_FAQ_DYNAMIC: "GET_FAQ_DYNAMIC",
  GET_STATIC_TEXT: "GET_STATIC_TEXT",
  GET_INS_STATIC_TEXT: "GET_INS_STATIC_TEXT",
  GET_STATIC_VALIDATION: "GET_STATIC_VALIDATION",
  GET_HOME_METATAGS: "GET_HOME_METATAGS",
  GET_FAQ_METATAG: "GET_FAQ_METATAG",
  GET_CONTACT_METATAG: "GET_CONTACT_METATAG",
  GET_LEGAL_METATAG: "GET_LEGAL_METATAG",
  GET_SERVICE_METATAG: "GET_SERVICE_METATAG",
  GET_FILTER_METATAG: "GET_FILTER_METATAG",
  GET_CHOOSE_METATAG: "GET_CHOOSE_METATAG",
  GET_PAGE_BY_SLUG: "GET_PAGE_BY_SLUG",
  GET_LANDING_VIEWPLAN: "GET_LANDING_VIEWPLAN",
  GET_LANDING_AGENGT: "GET_LANDING_AGENGT",
  GET_LANDING_SLIDER: "GET_LANDING_SLIDER",
  GET_LANDING_REVIEWS: "GET_LANDING_REVIEWS",
  GET_LANDING_PARTNERS: "GET_LANDING_PARTNERS",
  GET_LANDING_CONTACT: "GET_LANDING_CONTACT",
  GET_LANDING_JOIN: "GET_LANDING_JOIN",
  GET_LANDING_FAQ_DYNAMIC: "GET_LANDING_FAQ_DYNAMIC",
  GET_LANDING_FAQ_BANNER: "GET_LANDING_FAQ_BANNER",
  GET_LANDING_EXPERT_SECTION: "GET_LANDING_EXPERT_SECTION",
  GET_LANDING_CONTACT_BANNER: "GET_LANDING_CONTACT_BANNER",
  GET_LANDING_CONTACT_TITLE: "GET_LANDING_CONTACT_TITLE",
  GET_LANDING_CONTACT_ENQUIRY: "GET_LANDING_CONTACT_ENQUIRY",
  GET_LANDING_CONTACT_FORM: "GET_LANDING_CONTACT_FORM",
  GET_LANDING_CLAIM_SECTION: "GET_LANDING_CLAIM_SECTION",
  GET_LANDING_CONT_AGENT_SECTION: "GET_LANDING_CONT_AGENT_SECTION",
  GET_LANDING_LEGAL_BANNER: "GET_LANDING_LEGAL_BANNER",
  GET_LANDING_REGULATE_INFO: "GET_LANDING_REGULATE_INFO",
  GET_LANDING_TERMS_POLICY: "GET_LANDING_TERMS_POLICY",
  GET_LANDING_TERMS_SERVICE: "GET_LANDING_TERMS_SERVICE",
  GET_LANDING_CHOOSE_BANNER_SECTION: "GET_LANDING_CHOOSE_BANNER_SECTION",
  GET_LANDING_CHOOSE_MAP: "GET_LANDING_CHOOSE_MAP",
  GET_LANDING_CHOOSE_SLIDER: "GET_LANDING_CHOOSE_SLIDER",
  GET_LANDING_INHOUSE_SECTION: "GET_LANDING_INHOUSE_SECTION",
  GET_LANDING_CHOOSE_REVIEWS: "GET_LANDING_CHOOSE_REVIEWS",
  GET_LANDING_STORY_SECTION: "GET_LANDING_STORY_SECTION",
  GET_LANDING_MSH_STORY: "GET_LANDING_MSH_STORY",
  GET_LANDING_LIVE_STORY: "GET_LANDING_LIVE_STORY",
  GET_LANDING_BUSINESS_BANNER: "GET_LANDING_BUSINESS_BANNER",
  GET_LANDING_BUSINESS_SERVICE: "GET_LANDING_BUSINESS_SERVICE",
  GET_LANDING_BUSINESS_BOTTOM: "GET_LANDING_BUSINESS_BOTTOM",
  GET_LANDING_BUSINESS_BOTTOM2: "GET_LANDING_BUSINESS_BOTTOM2",
  GET_LANDING_BUSINESS_BOTTOM3: "GET_LANDING_BUSINESS_BOTTOM3",
  GET_LANDING_BUSINESS_TAB: "GET_LANDING_BUSINESS_TAB",
  GET_LANDING_BUSINESS_GROUPS2: "GET_LANDING_BUSINESS_GROUPS2",
  GET_LANDING_BUSINESS_GROUPS1: "GET_LANDING_BUSINESS_GROUPS1",
  GET_LANDING_BUSINESS_GROUPS: "GET_LANDING_BUSINESS_GROUPS",
  GET_LANDING_CHOOSE_METATAG: "GET_LANDING_CHOOSE_METATAG",
  GET_LANDING_HOME_METATAGS: "GET_LANDING_HOME_METATAGS",
  GET_LANDING_FAQ_METATAG: "GET_LANDING_FAQ_METATAG",
  GET_LANDING_CONTACT_METATAG: "GET_LANDING_CONTACT_METATAG",
  GET_LANDING_LEGAL_METATAG: "GET_LANDING_LEGAL_METATAG",
  GET_LANDING_SERVICE_METATAG: "GET_LANDING_SERVICE_METATAG",
  SAVE_SUBSCRIBE_FORM: "SAVE_SUBSCRIBE_FORM",
  GET_BUSINESS_POPUP: "GET_BUSINESS_POPUP",
  SEND_QUOTE_MAIL: "SEND_QUOTE_MAIL",
  GET_SOCIAL_MEDIA: "GET_SOCIAL_MEDIA",
  GET_LANDING_HOME_FILTER: "GET_LANDING_HOME_FILTER",
  GET_LANDING_STATIC: "GET_LANDING_STATIC",
  GET_LANDING_VALIDATION: "GET_LANDING_VALIDATION",
};

export const loaderDispatch = (dispatch, condition, ftype = "") => {
  dispatch({
    type: "LOADER",
    payload: condition,
    ftype: ftype,
  });
};

var lang = 1;
if (localStorage.getItem("selectedLang") !== null) {
  var langv = JSON.parse(localStorage.getItem("selectedLang"));
  lang = langv.id;
}

export const config = {
  headers: {
    "Content-type": "application/json",
    "X-localization": `${lang}`,
  },
};
