import React, { Fragment, useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getLegalMetatag } from "../../actions";

import  Header  from "../Comman/Header";
import { Footer } from "../Comman/Footer";
import { LegalBanner } from "./LegalBanner";
import { TermsPolicy } from "./TermsPolicy";
import isEmpty from "../../isEmpty";

function Legal(props) {
  const { legalmetatag } = useSelector((state) => {
    return state.Legal;
  });

  const dispatch = useDispatch();
  const [metakey, SetMetakey] = useState("");
  const [metadesc, SetMetadesc] = useState("");
  const [metatitle, SetMetatitle] = useState("");
  const [metalang, SetMetalang] = useState("");
  const [metatags, SetMetatags] = useState("");

  useEffect(() => {
    dispatch(getLegalMetatag());
  }, [dispatch]);

  useEffect(() => {
    if (!isEmpty(legalmetatag)) {
      const items = legalmetatag.items;
      if (!isEmpty(items)) {
        SetMetakey(legalmetatag.items[0].name);
        SetMetadesc(legalmetatag.items[1].name);
        SetMetatitle(legalmetatag.items[2].name);
        SetMetalang(legalmetatag.items[3].name);
        SetMetatags(legalmetatag.items[4].name);
      }
    }
  }, [legalmetatag]);
  return (
    <div>
      <Header
        metatitle={metatitle}
        metadesc={metadesc}
        metakey={metakey}
        metalang={metalang}
        metatags={metatags}
      />
      <LegalBanner />
      <TermsPolicy />

      <Footer />
    </div>
  );
}

export { Legal };
