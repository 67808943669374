import React, { Fragment, useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
// import { connect,useSelector, } from 'react-redux';
import { Link } from "react-router-dom";
import { getHomeCategory2, getStatictext } from "../../../actions";
import Item from "../../../item";
import { appConstants } from "../../../helpers/app-constants";
import isEmpty from "../../../isEmpty";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import "../../../index.css";

const LIMIT = 10;
const LENGTH = 50;

function TravelCategorys(props) {
  console.log(props.itemid);
  const { travelCategory } = useSelector((state) => {
    return state.Home;
  });

  const dispatch = useDispatch();

  const [section, SetSection] = useState([]);
  const [visible, SetVisible] = useState(6);
  const [heading, SetHeading] = useState("");

  const [loadmoreplace, SetLoadmoreplace] = useState("");
  const [loadlessplace, SetLoadlessplace] = useState("");

  const [slides, SetSlides] = useState([]);

  const loadMore = () => {
    var newste = visible + 6;
    SetVisible(newste);
  };

  const loadLess = () => {
    SetVisible(6);
  };
  const { statictext } = useSelector((state) => {
    return state.Home;
  });

  useEffect(() => {
    // dispatch(getLanuageList());
    dispatch(getHomeCategory2());
    dispatch(getStatictext());
  }, [dispatch]);

  useEffect(() => {
    if (!isEmpty(travelCategory)) {
      const slides = travelCategory.categories;
      if (!isEmpty(slides)) {
        SetSlides(slides);
      }
    }
    if (!isEmpty(statictext)) {
      SetLoadmoreplace(statictext.items[8].name);
      SetLoadlessplace(statictext.items[9].name);
    }
  }, [travelCategory, statictext]);

  return (
    <section className="section_space service_outer">
      <div className="container custom_container">
        <div className="row">
          {slides.slice(0, visible).map((item, index) => (
            <div className="col-md-2 mb-4" key={index}>
              <div className="service_inner aos-item" data-aos="zoom-in">
                <div className="service_img">
                  <img src={item.back_image} alt="sbg1" />
                </div>
                <div className="service_content">
                  <img src={item.image} alt="s1" />
                  <div className="service_title">{item.name_en}</div>
                </div>
                <div className="service_overlay"></div>
              </div>
            </div>
          ))}
        </div>
        <div className="row">
          {visible < slides.length && (
            <button onClick={loadMore} className="go_btn view_plan_btn">
              {loadmoreplace}
            </button>
          )}
          {visible > 6 && (
            <button onClick={loadLess} className="go_btn view_plan_btn">
              {loadlessplace}
            </button>
          )}
        </div>
      </div>
    </section>
  );
}

export { TravelCategorys };
