import React, { Fragment, useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { getLandingContact } from "../../../actions";
import Item from "../../../item";
import { appConstants } from "../../../helpers/app-constants";
import isEmpty from "../../../isEmpty";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import "../../../index.css";
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';

function ContactSection(props) {
  const { landingcontactSection } = useSelector((state) => {
    return state.Home;
  });

  const dispatch = useDispatch();
  const [section, SetSection] = useState([]);
  const [heading, SetHeading] = useState("");
  const [heading2, SetHeading2] = useState("");
  const [heading3, SetHeading3] = useState("");
  const [btn, SetBtn] = useState({});
  const [backImage, SetBackImage] = useState("");
  const [slides, SetSlides] = useState([]);

  useEffect(() => {
    // dispatch(getLanuageList());
    dispatch(getLandingContact(props.itemid));
  }, [dispatch]);

  useEffect(() => {
    if (!isEmpty(landingcontactSection)) {
      const items = landingcontactSection.items;
      if (!isEmpty(items)) {
        SetHeading(items[0].name);
        SetHeading2(items[1].name);
        SetHeading3(items[2].name);
        SetBtn(items[3]);
      }
    }
  }, [landingcontactSection]);

  return (
    <section className="section_space view_plan_outer question_outer">
      <div className="container custom_container">
        <div className="row">
          <div className="col-md-12">
            <div
              className="agent_title buytravel_title question_title aos-item"
              data-aos="fade-up"
            >
              {heading}
            </div>
            <div className="insurance_title aos-item" data-aos="fade-up">
              {ReactHtmlParser(heading2)}
            </div>
            <div className="call_title aos-item" data-aos="fade-up">
              {heading3}
            </div>
            <div className="view_plan_inner aos-item" data-aos="fade-up">
              <a href={btn.url} className="go_btn view_plan_btn chat_btn">
                <i className="fal fa-long-arrow-right"></i>
                {btn.name_en}
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export { ContactSection };
