import axios from "axios";
import { returnErrors } from "../actions/error.action";
import { appConstants,config } from "../helpers/app-constants";
import { loaderDispatch } from "../helpers/app-constants";

export const getFaqBanner = () => (dispatch) => {
  loaderDispatch(dispatch, true);
  axios
    .get(appConstants.BACKEND_URL + "getHomeSectionItem/25",config)
    .then((res) => {
      loaderDispatch(dispatch, false);
      dispatch({ type: appConstants.GET_FAQ_BANNER, payload: res.data.data });
    })
    .catch((err) => {
      loaderDispatch(dispatch, false);
      dispatch(returnErrors(err.response, err.response));
    });
};
export const getLandingFaqBanner = (id) => (dispatch) => {
  loaderDispatch(dispatch, true);
  axios
    .get(appConstants.BACKEND_URL + "getHomeSectionItem/" + id,config)
    .then((res) => {
      loaderDispatch(dispatch, false);
      dispatch({
        type: appConstants.GET_LANDING_FAQ_BANNER,
        payload: res.data.data,
      });
    })
    .catch((err) => {
      loaderDispatch(dispatch, false);
      dispatch(returnErrors(err.response, err.response));
    });
};

export const getFaqMetatag = () => (dispatch) => {
  loaderDispatch(dispatch, true);
  axios
    .get(appConstants.BACKEND_URL + "getHomeSectionItem/212",config)
    .then((res) => {
      loaderDispatch(dispatch, false);
      dispatch({ type: appConstants.GET_FAQ_METATAG, payload: res.data.data });
    })
    .catch((err) => {
      loaderDispatch(dispatch, false);
      dispatch(returnErrors(err.response, err.response));
    });
};
export const getLandingFaqMetatag = (id) => (dispatch) => {
  loaderDispatch(dispatch, true);
  axios
    .get(appConstants.BACKEND_URL + "getHomeSectionItem/" + id,config)
    .then((res) => {
      loaderDispatch(dispatch, false);
      dispatch({
        type: appConstants.GET_LANDING_FAQ_METATAG,
        payload: res.data.data,
      });
    })
    .catch((err) => {
      loaderDispatch(dispatch, false);
      dispatch(returnErrors(err.response, err.response));
    });
};

export const getExpertSection = () => (dispatch) => {
  loaderDispatch(dispatch, true);
  axios
    .get(appConstants.BACKEND_URL + "getHomeSectionItem/26",config)
    .then((res) => {
      loaderDispatch(dispatch, false);
      dispatch({
        type: appConstants.GET_EXPERT_SECTION,
        payload: res.data.data,
      });
    })
    .catch((err) => {
      loaderDispatch(dispatch, false);
      dispatch(returnErrors(err.response, err.response));
    });
};
export const getLandingExpertSection = (id) => (dispatch) => {
  loaderDispatch(dispatch, true);
  axios
    .get(appConstants.BACKEND_URL + "getHomeSectionItem/" + id,config)
    .then((res) => {
      loaderDispatch(dispatch, false);
      dispatch({
        type: appConstants.GET_LANDING_EXPERT_SECTION,
        payload: res.data.data,
      });
    })
    .catch((err) => {
      loaderDispatch(dispatch, false);
      dispatch(returnErrors(err.response, err.response));
    });
};

export const getLandingFaqDynamic = (id) => (dispatch) => {
  loaderDispatch(dispatch, true);
  axios
    .get(appConstants.BACKEND_URL + "getLandingFaqDynamic/" + id,config)
    .then((res) => {
      // console.log(res);
      loaderDispatch(dispatch, false);
      dispatch({
        type: appConstants.GET_LANDING_FAQ_DYNAMIC,
        payload: res.data.data,
      });
    })
    .catch((err) => {
      loaderDispatch(dispatch, false);
      dispatch(returnErrors(err.response, err.response));
    });
};

export const getFaqDynamic = () => (dispatch) => {
  loaderDispatch(dispatch, true);
  axios
    .get(appConstants.BACKEND_URL + "getFaqDynamic",config)
    .then((res) => {
      // console.log(res);
      loaderDispatch(dispatch, false);
      dispatch({ type: appConstants.GET_FAQ_DYNAMIC, payload: res.data.data });
    })
    .catch((err) => {
      loaderDispatch(dispatch, false);
      dispatch(returnErrors(err.response, err.response));
    });
};
