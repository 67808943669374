import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getHomeContact } from "../../actions";
import isEmpty from "../../isEmpty";
import "react-multi-carousel/lib/styles.css";
import "../../index.css";
import ReactHtmlParser from "react-html-parser";

function ContactSection(props) {
  const { contactSection } = useSelector((state) => {
    return state.Home;
  });

  const dispatch = useDispatch();
  const [heading, SetHeading] = useState("");
  const [heading2, SetHeading2] = useState("");
  const [heading3, SetHeading3] = useState("");
  const [btn, SetBtn] = useState({});

  useEffect(() => {
    dispatch(getHomeContact());
  }, [dispatch]);

  useEffect(() => {
    if (!isEmpty(contactSection)) {
      const items = contactSection.items;
      if (!isEmpty(items)) {
        SetHeading(items[0].name_en);
        SetHeading2(items[1].name_en);
        SetHeading3(items[2].name_en);
        SetBtn(items[3]);
      }
    }
  }, [contactSection]);

  return (
    <section className="section_space view_plan_outer question_outer">
      <div className="container custom_container">
        <div className="row">
          <div className="col-md-12">
            <div
              className="agent_title buytravel_title question_title aos-item"
              data-aos="fade-up"
            >
              {heading}
            </div>
            <div className="insurance_title aos-item" data-aos="fade-up">
              {ReactHtmlParser(heading2)}
            </div>
            <div className="call_title aos-item" data-aos="fade-up">
              {ReactHtmlParser(heading3)}
            </div>
            <div className="view_plan_inner aos-item" data-aos="fade-up">
              <a href={btn.url} className="go_btn view_plan_btn chat_btn">
                <i className="fal fa-long-arrow-right"></i>
                {btn.name_en}
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export { ContactSection };
