import React, { Fragment, useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import Item from "../../item";
import isEmpty from "../../isEmpty";
import "react-multi-carousel/lib/styles.css";
import "../../index.css";
import { getPageId } from "../../actions";
import { useParams } from "react-router-dom";
import { HomeLanding } from "./HomeLanding";
import { FaqLanding } from "./FaqLanding";
import { ContactLanding } from "./ContactLanding";
import { LegalLanding } from "./LegalLanding";
import { ChooseLanding } from "./ChooseLanding";
import { ShopLanding } from "./ShopLanding";
import  PageNotFound  from "./404-page";
import { Loader } from "./Loader";
import { type } from "jquery";

function LandingPage(props) {
  let { slug } = useParams();
  const [typeId, SetTypeId] = useState("");
  const [page, SetPage] = useState("");
  const [pageSections, SetPageSections] = useState([]);

  const { pageId,loader } = useSelector((state) => {
    return state.Home;
  });

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getPageId(slug));
  }, [dispatch]);

  useEffect(() => {
    if (!isEmpty(pageId)) {
      SetTypeId(pageId.type_id);
      SetPage(pageId.id);
      SetPageSections(pageId.pgsections);
    }
    console.log(loader);
  }, [pageId]);

  console.log(pageId);
  return (
    
    <div>
      {(() => {
        switch (typeId) {
          case 1:
            return (
              <HomeLanding typeid={typeId} id={page} sections={pageSections} />
            );
          case 4:
            return (
              <FaqLanding typeid={typeId} id={page} sections={pageSections} />
            );
          case 5:
            return (
              <ContactLanding
                typeid={typeId}
                id={page}
                sections={pageSections}
              />
            );
          case 6:
            return (
              <LegalLanding typeid={typeId} id={page} sections={pageSections} />
            );
          case 3:
            return (
              <ChooseLanding
                typeid={typeId}
                id={page}
                sections={pageSections}
              />
            );
          case 2:
            return (
              <ShopLanding typeid={typeId} id={page} sections={pageSections} />
            );
          case 111111111111:
              return <PageNotFound></PageNotFound>
                
                
          default:
            return <Loader></Loader>;
        }
      })()}
    </div>

    
    
  );
}

export { LandingPage };
