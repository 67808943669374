import React, { Fragment, useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
// import { connect,useSelector, } from 'react-redux';
import { Link } from "react-router-dom";
import { getLandingInhouseSection } from "../../../actions";
import Item from "../../../item";
import { appConstants } from "../../../helpers/app-constants";
import isEmpty from "../../../isEmpty";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import "../../../index.css";

function InhouseSection(props) {
  const { landinginhouseSection } = useSelector((state) => {
    return state.Choose;
  });

  const dispatch = useDispatch();

  const [section, SetSection] = useState([]);
  const [heading, SetHeading] = useState("");

  useEffect(() => {
    // dispatch(getLanuageList());
    dispatch(getLandingInhouseSection(props.itemid));
  }, [dispatch]);

  useEffect(() => {
    if (!isEmpty(landinginhouseSection)) {
      const items = landinginhouseSection.items;
      console.log(items);
      if (!isEmpty(items)) {
        SetHeading(items[0].name_en);
      }
    }
  }, [landinginhouseSection]);
  return (
    <section class="section_space buytravel_outer">
      <div class="container custom_container">
        <div class="row">
          <div class="col-md-12">
            <div
              class="agent_title buytravel_title in_house_title aos-item"
              data-aos="fade-up"
            >
              {heading}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export { InhouseSection };
