import React, { Fragment, useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
// import { connect,useSelector, } from 'react-redux';
import { Link } from "react-router-dom";
import { getLandingChooseSlider } from "../../../actions";
import Item from "../../../item";
import { appConstants } from "../../../helpers/app-constants";
import isEmpty from "../../../isEmpty";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import "../../../index.css";

function ChooseSlider(props) {
  const { landingchooseSlider } = useSelector((state) => {
    return state.Choose;
  });

  const dispatch = useDispatch();

  const [homeSlider2, SetHomeSlider2] = useState([]);
  const [responsive, SetResponsive] = useState({
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4,
      slidesToSlide: 1, // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1024, min: 768 },
      items: 2,
      slidesToSlide: 1, // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 768, min: 0 },
      items: 1,
      slidesToSlide: 1, // optional, default to 1.
    },
  });

  const [responsive2, SetResponsive2] = useState({
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 2,
      slidesToSlide: 1, // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1024, min: 768 },
      items: 2,
      slidesToSlide: 1, // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 550, min: 0 },
      items: 1,
      slidesToSlide: 1, // optional, default to 1.
    },
  });
  const [sliderHeading, SetSliderHeading] = useState("");
  const [slides, SetSlides] = useState([]);
  const [sliderSpeed, SetSliderSpeed] = useState(0);


  useEffect(() => {
    // dispatch(getLanuageList());
    dispatch(getLandingChooseSlider(props.itemid));
  }, [dispatch]);

  useEffect(() => {
    if (!isEmpty(landingchooseSlider)) {
      const items = landingchooseSlider.items;
      const slides = landingchooseSlider.slides;
      if (!isEmpty(items)) {
        SetSliderSpeed(items[0].name_en);
      }

      if (!isEmpty(slides)) {
        SetSlides(slides);
      }
    }
  }, [landingchooseSlider]);

  return (
    <section className="section_space view_plan_outer question_outer why_msh_ques">
      <div className="container custom_container">
        <div className="row">
          <div className="col-md-12">
            <Carousel
              responsive={responsive}
              additionalTransfrom={0}
              arrows
              autoPlay={true}
              autoPlaySpeed={parseInt(sliderSpeed)}
              centerMode={false}
              draggable
              focusOnSelect={false}
              infinite
              keyBoardControl
              minimumTouchDrag={80}
              renderButtonGroupOutside={false}
              renderDotsOutside={false}
              showDots
              sliderClass=""
              slidesToSlide={1}
              swipeable
              containerclassName="carousel-container"
              removeArrowOnDeviceType={["tablet", "mobile"]}
              deviceType={props.deviceType}
              dotListclassName="rec-dot"
              itemclassName="carousel-item-padding-40-px"
            >
              {slides.map((item, index) => (
                <div className="buyer_inner" key={index}>
                  <img src={item.image} alt="b1" />
                  <div className="buyer_title">{item.name_en} </div>
                </div>
              ))}
            </Carousel>
          </div>
        </div>
      </div>
    </section>
  );
}

export { ChooseSlider };
