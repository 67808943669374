import React, { Fragment, useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import MetaTags from "react-meta-tags";
import { useLocation } from 'react-router-dom'

import {
  getMenus,
  getSelectedLanguage,
  setSelectedLanguage,
} from "../../actions";
import Item from "../../item";
import { appConstants } from "../../helpers/app-constants";
import isEmpty from "../../isEmpty";
import { Select } from "react-select";

function Header(props) {
  const { menuList, SelectedLang } = useSelector((state) => {
    return state.MenuReducer;
  });


  

  const dispatch = useDispatch();
  const location = useLocation();
  const [menuTopLeft, setMenuTopLeft] = useState([]);
  const [menuTopRight, setMenuTopRight] = useState([]);
  const [menuTopRightFirst, setMenuTopRightFirst] = useState([]);
  const [menuLogo, setMenuLogo] = useState([]);
  const [logo, setLogo] = useState([]);
  const [favIcon, setFavIcon] = useState('');
  const [menuFooter, setMenuFooter] = useState([]);
  const [menuLanguage, setMenuLanguage] = useState([]);
  const [selectLanguage, setSelectLanguage] = useState([]);
  const [locationUrl, setLocationUrl] = useState(location.pathname);

  //document.getElementById("sandy").href = favIcon;

  useEffect(() => {
    // dispatch(getLanuageList());
    dispatch(getSelectedLanguage());

    dispatch(getMenus());

  }, [dispatch]);

  useEffect(() => {
    if (!isEmpty(SelectedLang)) {
      setSelectLanguage(SelectedLang);
    }
  }, [SelectedLang]);

  useEffect(() => {
    if (!isEmpty(menuList)) {
      // console.log(menuList);
      console.log(menuList)
      let rightmenu = menuList.menu_top_right;

      setMenuTopLeft(menuList.menu_top_left);
      setMenuTopRight(rightmenu.splice(1));
      setMenuTopRightFirst(menuList.menu_top_right_first);
      setMenuLogo(menuList.menu_logo);
      setMenuFooter(menuList.menu_footer);
      setLogo(menuList.logo);
      setFavIcon(menuList.favicon?.image)
      
      setMenuLanguage(menuList.menu_languages);
    }

  }, [menuList]);

  const changeLanguage = (lang) => {
    /// console.log(lang.id);
    const l = {};
    l.id = lang.id;
    l.name = lang.name;
    // console.log(l);
    dispatch(setSelectedLanguage(l));
  };

  let TopRigthMenuLogin = () => {
    const item = menuTopRightFirst;
    var f = item.is_external == 1 ? true : false;
    var child = false;
    if (!isEmpty(item.childs)) {
      child = true;
    }

    return child ? (
      <li className="nav-item dropdown dropdown_custom language_dropdown">
        {" "}
        <a
          className="dropdown-toggle login_btn_user"
          href="#"
          id="navbarDropdowne"
          role="button"
          data-bs-toggle="dropdown"
          aria-expanded="false"
        >
          <i className="fal fa-user-circle"></i>
          {item.title_en}
        </a>
        <ul
          className="dropdown-menu language_inner_drodown lan_drop"
          aria-labelledby="navbarDropdowne"
        >
          {item.childs.map((itemChild, index2) => {
            var f2 = itemChild.is_external == 1 ? true : false;
            return (
              <li key={index2}>
                {f2 ? (
                  <a
                    className="dropdown-item"
                    href={itemChild.link_url}
                    target="_blank"
                  >
                    {itemChild.title_en}
                  </a>
                ) : (
                  <a
                    className="nav-link"
                    href={appConstants.FRONTEND_URL + itemChild.link_url}
                  >
                    {itemChild.title_en}
                  </a>
                )}
              </li>
            );
          })}
        </ul>
      </li>
    ) : (
      <li className="nav-item">
        {f ? (
          <a className="nav-link" href={item.link_url} target="_blank">
            {item.title_en}
          </a>
        ) : (
          <a
            className="nav-link"
            href={appConstants.FRONTEND_URL + item.link_url}
          >
            {item.title_en}
          </a>
        )}
      </li>
    );
  };

  let TopRigthMenuLoginMobile = () => {
    const item = menuTopRightFirst;
    var f = item.is_external == 1 ? true : false;
    var child = false;
    if (!isEmpty(item.childs)) {
      child = true;
    }

    return child ? (
      <ul className="dropdown-menu" aria-labelledby="navbarDropdowne">
        {item.childs.map((itemChild, index2) => {
          var f2 = itemChild.is_external == 1 ? true : false;
          return (
            <li key={index2}>
              {f2 ? (
                <a
                  className="dropdown-item"
                  href={itemChild.link_url}
                  target="_blank"
                >
                  {itemChild.title_en}
                </a>
              ) : (
                <a
                  className="nav-link"
                  href={appConstants.FRONTEND_URL + itemChild.link_url}
                >
                  {itemChild.title_en}
                </a>
              )}
            </li>
          );
        })}
      </ul>
    ) : (
      <li className="nav-item">
        {f ? (
          <a className="nav-link" href={item.link_url} target="_blank">
            {item.title_en}
          </a>
        ) : (
          <a
            className="nav-link"
            href={appConstants.FRONTEND_URL + item.link_url}
          >
            {item.title_en}
          </a>
        )}
      </li>
    );
  };
  let logoMenu = menuLogo.map((item, index) => {
    var f = item.is_external == 1 ? true : false;
    var child = false;
    if (!isEmpty(item.childs)) {
      child = true;
    }

    return child ? (
      <li key={index} className="nav-item dropdown dropdown_custom">
        {" "}
        <a
          className="nav-link dropdown-toggle"
          href="#"
          id="navbarDropdown"
          role="button"
          data-bs-toggle="dropdown"
          aria-expanded="false"
        >
          {item.title_en}
        </a>
        <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
          {item.childs.map((itemChild, index2) => {
            var f2 = itemChild.is_external == 1 ? true : false;
            return (
              <li key={index2}>
                {f2 ? (
                  <a
                    className="dropdown-item"
                    href={itemChild.link_url}
                    target="_blank"
                  >
                    <b>{itemChild.title_en}</b>
                    <ul className="new p-0" aria-labelledby="navbarDropdowne">
                      {itemChild.submenu &&
                        itemChild.submenu.map((subChild2, index4) => {
                          return <li key={index4}> <a
                            className="dropdown-item"
                            href={appConstants.FRONTEND_URL + subChild2.link_url}
                          >{subChild2.title_en}</a> </li>;
                        })}
                    </ul>
                  </a>
                ) : (
                  <a
                    className="dropdown-item"
                    href={appConstants.FRONTEND_URL + itemChild.link_url}
                  >
                    <b>{itemChild.title_en}</b>
                    <ul className="new p-0" aria-labelledby="navbarDropdowne">
                      {itemChild.submenu &&
                        itemChild.submenu.map((subChild, index3) => {
                          return <li key={index3}> <a
                            className="dropdown-item"
                            href={appConstants.FRONTEND_URL + subChild.link_url}
                          >{subChild.title_en}</a> </li>;
                        })}
                    </ul>
                  </a>
                )}
              </li>
            );
          })}
        </ul>
      </li>
    ) : (
      <li className="nav-item">
        {f ? (
          <a className="nav-link" href={item.link_url} target="_blank">
            {item.title_en}
          </a>
        ) : (
          <a
            className="nav-link"
            href={appConstants.FRONTEND_URL + item.link_url}
          >
            {item.title_en}
          </a>
        )}
      </li>
    );
  });

  let TopMenuRight = menuTopRight.map((item, index) => {
    var f = item.is_external == 1 ? true : false;
    var child = false;
    if (!isEmpty(item.childs)) {
      child = true;
    }

    return child ? (
      <li
        key={index}
        className="nav-item dropdown dropdown_custom language_dropdown"
      >
        {" "}
        <a
          className="dropdown-toggle"
          href="#"
          id="navbarDropdowne"
          role="button"
          data-bs-toggle="dropdown"
          aria-expanded="false"
        >
          {item.title_en}
          <i className="fal fa-chevron-right"></i>
        </a>
        <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
          {item.childs.map((itemChild, index2) => {
            var f2 = itemChild.is_external == 1 ? true : false;
            return (
              <li key={index2}>
                {f2 ? (
                  <a
                    className="dropdown-item"
                    href={itemChild.link_url}
                    target="_blank"
                  >
                    {itemChild.title_en}
                  </a>
                ) : (
                  <a
                    className="nav-link"
                    href={appConstants.FRONTEND_URL + itemChild.link_url}
                  >
                    {itemChild.title_en}
                  </a>
                )}
              </li>
            );
          })}
        </ul>
      </li>
    ) : (
      <li className="nav-item">
        {f ? (
          <a className="nav-link" href={item.link_url} target="_blank">
            {item.title_en}
          </a>
        ) : (
          <a
            className="nav-link"
            href={appConstants.FRONTEND_URL + item.link_url}
          >
            {item.title_en}
          </a>
        )}
      </li>
    );
  });

  let TopMenuLanguage = () => {
    const item = menuLanguage;

    return (
      <li className="nav-item dropdown dropdown_custom language_dropdown">
        <a
          className="dropdown-toggle"
          href="#"
          id="navbarDropdowne"
          role="button"
          data-bs-toggle="dropdown"
          aria-expanded="false"
        >
          {selectLanguage.name}
          <i className="fal fa-chevron-right"></i>
        </a>
        <ul className="dropdown-menu" aria-labelledby="navbarDropdowne">
          {menuLanguage &&
            menuLanguage.map((item, index2) => {
              return (
                <li key={index2}>
                  <a
                    className="dropdown-item"
                    onClick={() =>
                      changeLanguage({ id: item.id, name: item.name })
                    }
                  >
                    {item.name}
                  </a>
                </li>
              );
            })}
        </ul>
      </li>
    );

  };

  let TopMenuLanguageMobile = () => {
    const item = menuLanguage;

    var ml = !isEmpty(selectLanguage) ? selectLanguage.name : "Eng";

    return (
      <li className=" dropdown dropdown_custom language_dropdown">
        <a
          className="dropdown-toggle"
          href="#"
          id="language2"
          role="button"
          data-bs-toggle="dropdown"
          aria-expanded="false"
        >
          {ml.substring(0, 2)}
          <i className="fal fa-chevron-down"></i>
        </a>
        <ul className="dropdown-menu" aria-labelledby="navbarDropdowne">
          {menuLanguage &&
            menuLanguage.map((item, index2) => {
              return (
                <li key={index2}>
                  <a
                    className="dropdown-item"
                    onClick={() =>
                      changeLanguage({ id: item.id, name: item.name })
                    }
                  >
                    {item.name}
                  </a>
                </li>
              );
            })}
        </ul>
      </li>
    );
  };

  let TopLeftMenu = menuTopLeft.map((item, index) => {
    var f = item.is_external == 1 ? true : false;
    var child = false;
    if (!isEmpty(item.childs)) {
      child = true;
    }

    return child ? (
      <li
        key={index}
        className="nav-item dropdown dropdown_custom language_dropdown"
      >
        {" "}
        <a
          className="dropdown-toggle"
          href="#"
          id="navbarDropdowne"
          role="button"
          data-bs-toggle="dropdown"
          aria-expanded="false"
        >
          {item.title_en}
          <i className="fal fa-chevron-right"></i>
        </a>
        <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
          {item.childs.map((itemChild, index2) => {
            var f2 = itemChild.is_external == 1 ? true : false;

            return (
              <li key={index2} className={item.link_url == location.pathname ? "active" : ""}>
                {f2 ? (
                  <a
                    className="dropdown-item"
                    href={itemChild.link_url}
                    target="_blank"
                  >
                    {itemChild.title_en}
                  </a>
                ) : (
                  <a
                    className="nav-link"
                    href={appConstants.FRONTEND_URL + itemChild.link_url}
                  >
                    {itemChild.title_en}
                  </a>
                )}
              </li>
            );
          })}
        </ul>
      </li>
    ) : (
      <li className={item.link_url == '/shop-insurance-filter' ? "active" : ""}>
        {f ? (
          <a className="" href={item.link_url} target="_blank">
            {item.title_en}
          </a>
        ) : (
          <a className="nav-link" href={item.link_url}>
            {item.title_en}
          </a>
        )}
      </li>
    );
  });

  return (
    <div>
      <MetaTags>
        <title>{props.metatitle}</title>
        <meta name="keywords" content={props.metakey} />
        <meta
          id="meta-description"
          name="description"
          content={props.metadesc}
        />
        <meta id="meta-tags" name="tags" content={props.metatags} />
        <meta name="language" content={props.metalang} />
      </MetaTags>
      <header>
        <div className="top_bar">
          <div className="container custom_container">
            <div className="row desktop_top_row">
              <div className="col-md-6">
                <div className="top_barleft">
                  <ul className="top_ul">{TopLeftMenu}</ul>
                </div>
              </div>
              <div className="col-md-6">
                <div className="top_barright">
                  <ul className="top_ul">

                    {TopMenuRight}

                    {TopMenuLanguage()}

                    {TopRigthMenuLogin()}

                  </ul>
                </div>
              </div>
            </div>
            <div className="row mobile_top_row">
              <div className="col-md-12">
                <ul className="top_ul">
                  <li className="nav-item dropdown dropdown_custom language_dropdown mobile_language_dropdown">
                    <a
                      className="dropdown-toggle"
                      href="#"
                      id="navbarDropdowne"
                      role="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      {!isEmpty(menuTopLeft[0]) ? menuTopLeft[0].title_en : ""}{" "}
                      <i className="fal fa-chevron-down"></i>
                    </a>
                    <ul
                      className="dropdown-menu"
                      aria-labelledby="navbarDropdowne"
                    >
                      {TopLeftMenu}
                    </ul>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <nav className="navbar navbar-expand-lg navbar-light bg-light desktop_menu">
          <div className="container custom_container">
            <a className="navbar-brand" href="/">
              {" "}
              <img
                src={logo.image ? logo.image : +"assets/images/logo.png"}
                alt="logo"
              />
            </a>
            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon"></span>
            </button>
            <div
              className="collapse navbar-collapse"
              id="navbarSupportedContent"
            >
              <ul className="navbar-nav custom_nav">{logoMenu}</ul>
            </div>
          </div>
        </nav>
        <nav className="navbar navbar-expand-lg navbar-light bg-light mobile_menu">
          <div className="container custom_container">
            <div className="mobile_menu_inner">
              <div className="mobile_menu_first">
                <button
                  className="navbar-toggler"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#navbarSupportedContent"
                  aria-controls="navbarSupportedContent"
                  aria-expanded="false"
                  aria-label="Toggle navigation"
                >
                  <span className="navbar-toggler-icon"></span>
                </button>
              </div>
              <div className="mobile_menu_second">
                <a className="navbar-brand" href="/">
                  <img
                    src={logo.image ? logo.image : +"assets/images/logo.png"}
                    alt="logo"
                  />
                </a>
              </div>
              <div className="mobile_menu_right">
                {TopMenuLanguageMobile()}

                <div className="dropdown dropdown_custom language_dropdown">
                  <a
                    className="dropdown-toggle"
                    href="#"
                    id="login2"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <i className="fal fa-user-circle"></i>
                  </a>
                  {TopRigthMenuLoginMobile()}
                </div>

              </div>
            </div>
            <div
              className="collapse navbar-collapse"
              id="navbarSupportedContent"
            >
              <ul className="navbar-nav custom_nav">{logoMenu}</ul>
            </div>
          </div>
        </nav>
      </header>
    </div>
  );
}

export default Header;
