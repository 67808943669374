import React, { Fragment, useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
// import { connect,useSelector, } from 'react-redux';
import { Link } from "react-router-dom";
import { getLandingBusinessService } from "../../../actions";
import Item from "../../../item";
import { appConstants } from "../../../helpers/app-constants";
import isEmpty from "../../../isEmpty";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import "../../../index.css";
import ReactHtmlParser, {
  processNodes,
  convertNodeToElement,
  htmlparser2,
} from "react-html-parser";

function BusinessService(props) {
  const { landingbusinessService } = useSelector((state) => {
    return state.Business;
  });

  const dispatch = useDispatch();

  const [section, SetSection] = useState([]);
  const [heading, SetHeading] = useState("");
  const [subHead, SetSubHead] = useState("");

  useEffect(() => {
    // dispatch(getLanuageList());
    dispatch(getLandingBusinessService(props.itemid));
  }, [dispatch]);

  useEffect(() => {
    if (!isEmpty(landingbusinessService)) {
      const items = landingbusinessService.items;
      if (!isEmpty(items)) {
        SetHeading(items[0].name);
        SetSubHead(items[1].name);
      }
    }
  }, [landingbusinessService]);

  return (
    <section class="section_space contact_title_outer">
      <div class="container custom_container">
        <div class="row">
          <div class="col-md-8 offset-md-2 col-sm-12">
            <div
              class="agent_title buytravel_title brief_title aos-item aos-init aos-animate"
              data-aos="fade-up"
            >
              {heading}
            </div>
            {/* <p class="p_small">Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat. Duis autem vel eum iriure dolor in hendrerit in vulputate velit esse molestie consequat, vel illum dolore eu feugiat nulla facilisis at vero eros et accumsan et iusto odio dignissim qui blandit praesent luptatum zzril delenit augue duis dolore te feugait nulla facilisi.</p> */}

            <p class="p_small">{ReactHtmlParser(subHead)} </p>
          </div>
        </div>
      </div>
    </section>
  );
}

export { BusinessService };
