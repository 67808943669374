import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getClaimSection } from "../../actions";
import isEmpty from "../../isEmpty";
import "react-multi-carousel/lib/styles.css";
import "../../index.css";
import ReactHtmlParser from "react-html-parser";

function ClaimSection(props) {
  const { claimSection } = useSelector((state) => {
    return state.Contact;
  });

  const dispatch = useDispatch();

  const [heading, SetHeading] = useState("");
  const [subHeading, SetSubHeading] = useState("");
  const [claimPhone, SetClaimPhone] = useState("");
  const [claimPhoneNo, SetClaimPhoneNo] = useState("");
  const [claimFax, SetClaimFax] = useState("");
  const [claimFaxNo, SetClaimFaxNo] = useState("");
  const [claimEmail, SetClaimEmail] = useState("");
  const [claimEmailMail, SetClaimEmailMail] = useState("");

  useEffect(() => {
    dispatch(getClaimSection());
  }, [dispatch]);

  useEffect(() => {
    if (!isEmpty(claimSection)) {
      const items = claimSection.items;
      console.log(items[2].name_en);
      if (!isEmpty(items)) {
        SetHeading(items[0].name_en);
        SetSubHeading(items[1].name_en);
        SetClaimPhone(items[2].name_en);
        SetClaimPhoneNo(items[3]);
        SetClaimFax(items[4].name_en);
        SetClaimFaxNo(items[5]);
        SetClaimEmail(items[6].name_en);
        SetClaimEmailMail(items[7]);
      }
    }
  }, [claimSection]);


  return (
    <>
      <section class="section_space buytravel_outer testimonial_outer general_inquires">
        <div class="container custom_container">
          <div class="row">
            <div class="col-md-12">
              <div class="general_inquiry_title">{heading}</div>
            </div>
          </div>
        </div>
      </section>

      <section class="section_space contact_title_outer">
        <div class="container custom_container">
          <div class="row">
            <div class="col-md-8 offset-md-2 col-sm-12">
              <p class="p_text contact_subtitle">
                {ReactHtmlParser(subHeading)}
              </p>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <div class="contact_info_inner">
                <div class="contact_info_first1">
                  <div class="phone_img">
                    <img src={claimPhoneNo.image} alt="" width="53px" height="53px" />
                  </div>
                  <div class="contact-info-right">
                    <label class="custom_label">{claimPhone}</label>
                    <a href={`tel:+${claimPhoneNo.name}`}><div class="phone_number">{claimPhoneNo.name}</div></a>
                  </div>
                </div>
                <div class="contact_info_first1">
                  <div class="phone_img">
                    <img src={claimFaxNo.image} alt="" width="53px" height="53px" />
                  </div>
                  <div class="contact-info-right">
                    <label class="custom_label">{claimFax}</label>
                    <div class="phone_number">{claimFaxNo.name}</div>
                  </div>
                </div>
                <div class="contact_info_first1">
                  <div class="phone_img">
                    <img src={claimEmailMail.image} alt="" width="53px" height="53px" />
                  </div>
                  <div class="contact-info-right">
                    <label class="custom_label">{claimEmail}</label>
                    <a href={`mailto:${claimEmailMail.name}`}><div class="phone_number">{claimEmailMail.name}</div></a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export { ClaimSection };
