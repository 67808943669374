import React, { Fragment, useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { getContactMetatag } from "../../actions";
import Carousel from "react-elastic-carousel";
import Item from "../../item";
import Header from "../Comman/Header";
import isEmpty from "../../isEmpty";
import { Footer } from "../Comman/Footer";
import { BannerSection } from "./BannerSection";
import { ContactTitle } from "./ContactTitle";
import { ContactEnquiry } from "./ContactEnquiry";
import { ContactForm } from "./ContactForm";
import { ClaimSection } from "./ClaimSection";
import { AgentSection } from "./AgentSection";

function Contact(props) {
  const dispatch = useDispatch();

  const { contactmetatag } = useSelector((state) => {
    return state.Contact;
  });
  const [metakey, SetMetakey] = useState("");
  const [metadesc, SetMetadesc] = useState("");
  const [metatitle, SetMetatitle] = useState("");
  const [metalang, SetMetalang] = useState("");
  const [metatags, SetMetatags] = useState("");
  useEffect(() => {
    // dispatch(getLanuageList());
    dispatch(getContactMetatag());
  }, [dispatch]);

  useEffect(() => {
    if (!isEmpty(contactmetatag)) {
      const items = contactmetatag.items;
      if (!isEmpty(items)) {
        SetMetakey(contactmetatag.items[0].name);
        SetMetadesc(contactmetatag.items[2].name);
        SetMetatitle(contactmetatag.items[1].name);
        SetMetalang(contactmetatag.items[3].name);
        SetMetatags(contactmetatag.items[4].name);
      }
    }
  }, [contactmetatag]);

  return (
    <div>
      <Header
        metatitle={metatitle}
        metadesc={metadesc}
        metakey={metakey}
        metalang={metalang}
        metatags={metatags}
      />
      <BannerSection />
      <ContactTitle />
      <ContactEnquiry />
      <ContactForm />
      <ClaimSection />
      <AgentSection />

      <Footer />
    </div>
  );
}

export { Contact };
