import React, { Fragment, useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import isEmpty from "../../isEmpty";

import { getLandingChooseMetatag } from "../../actions";
import Carousel from "react-multi-carousel";
import Item from "../../item";
import  Header  from "../Comman/Header";
import { Footer } from "../Comman/Footer";
import { ChooseBanner } from "./Sections/ChooseBanner";
import { ChooseMap } from "./Sections/ChooseMap";
import { ChooseSlider } from "./Sections/ChooseSlider";
import { ChooseReviewSlider } from "./Sections/ChooseReviewSlider";
import { InhouseSection } from "./Sections/InhouseSection";
import { StorySection } from "./Sections/StorySection";
import { MshStory } from "./Sections/MshStory";
import { LiveStory } from "./Sections/LiveStory";

const breakPoints = [
  { width: 1, itemsToShow: 1 },
  { width: 550, itemsToShow: 1, itemsToScroll: 2 },
  { width: 768, itemsToShow: 1 },
  { width: 1200, itemsToShow: 1 },
];

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 1,
    slidesToSlide: 1, // optional, default to 1.
  },
  tablet: {
    breakpoint: { max: 1024, min: 768 },
    items: 1,
    slidesToSlide: 1, // optional, default to 1.
  },
  mobile: {
    breakpoint: { max: 550, min: 1 },
    items: 1,
    slidesToSlide: 1, // optional, default to 1.
  },
};

function ChooseLanding(props) {
  const { landingchoosemetatag } = useSelector((state) => {
    return state.Choose;
  });

  const dispatch = useDispatch();
  const [metakey, SetMetakey] = useState("");
  const [metadesc, SetMetadesc] = useState("");
  const [metatitle, SetMetatitle] = useState("");
  const [metalang, SetMetalang] = useState("");
  const [metatags, SetMetatags] = useState("");

  useEffect(() => {
    for (var i = 0; i < props.sections.length; i++) {
      if (props.sections[i].copy_section_id === 215) {
        dispatch(getLandingChooseMetatag(props.sections[i].id));
      }
    }
  }, [dispatch]);

  useEffect(() => {
    if (!isEmpty(landingchoosemetatag)) {
      const items = landingchoosemetatag.items;
      if (!isEmpty(items)) {
        SetMetakey(landingchoosemetatag.items[0].name);
        SetMetadesc(landingchoosemetatag.items[1].name);
        SetMetatitle(landingchoosemetatag.items[2].name);
        SetMetalang(landingchoosemetatag.items[3].name);
        SetMetatags(landingchoosemetatag.items[4].name);
      }
    }
  }, [landingchoosemetatag]);

  return (
    <div>
      <Header
        metatitle={metatitle}
        metadesc={metadesc}
        metakey={metakey}
        metalang={metalang}
        metatags={metatags}
      />
      {props.sections.map((sec) => (
        <>
          {(() => {
            switch (sec.copy_section_id) {
              case 17:
                return <ChooseBanner itemid={sec.id} />;
              case 18:
                return <ChooseMap itemid={sec.id} />;
              case 19:
                return <ChooseSlider itemid={sec.id} />;
              case 20:
                return <ChooseReviewSlider itemid={sec.id} />;
              case 21:
                return <InhouseSection itemid={sec.id} />;
              case 24:
                return <StorySection itemid={sec.id} />;
              case 22:
                return <MshStory itemid={sec.id} />;
              case 23:
                return <LiveStory itemid={sec.id} />;
              default:
                return "";
            }
          })()}
        </>
      ))}
      <Footer />
    </div>
  );
}

export { ChooseLanding };
