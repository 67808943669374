import React, { Fragment, useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { getLandingHomeMetatag } from "../../actions";
import Item from "../../item";
import isEmpty from "../../isEmpty";
import { Slider } from "./Sections/Slider";
import  Header  from "../Comman/Header";
import { Footer } from "../Comman/Footer";
import { Modal, Button } from "react-bootstrap";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import "../../index.css";

import { Review } from "./Sections/Review";
import { PartnerSection } from "./Sections/PartnerSection";
import { TravelCategorys } from "./Sections/TravelCategorys";
import { FilterSection } from "./Sections/FilterSection";
import { AgentSection } from "./Sections/AgentSection";
import { ContactSection } from "./Sections/ContactSection";
import { JoinSection } from "./Sections/JoinSection";
import { ViewPlanSection } from "./Sections/ViewPlanSection";

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 4,
    slidesToSlide: 1, // optional, default to 1.
  },
  tablet: {
    breakpoint: { max: 1024, min: 768 },
    items: 2,
    slidesToSlide: 1, // optional, default to 1.
  },
  mobile: {
    breakpoint: { max: 550, min: 1 },
    items: 1,
    slidesToSlide: 1, // optional, default to 1.
  },
};

const responsive2 = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 2,
    slidesToSlide: 1, // optional, default to 1.
  },
  tablet: {
    breakpoint: { max: 1024, min: 768 },
    items: 2,
    slidesToSlide: 1, // optional, default to 1.
  },
  mobile: {
    breakpoint: { max: 550, min: 1 },
    items: 1,
    slidesToSlide: 1, // optional, default to 1.
  },
};

function HomeLanding(props) {
  const [isOpen, SetIsOpen] = useState(false);
  const { landinghomeMetatags } = useSelector((state) => {
    return state.Home;
  });

  const dispatch = useDispatch();
  const openModal = () => SetIsOpen(true);
  const closeModal = () => SetIsOpen(false);
  const [metakey, SetMetakey] = useState("");
  const [metadesc, SetMetadesc] = useState("");
  const [metatitle, SetMetatitle] = useState("");
  const [metalang, SetMetalang] = useState("");
  const [metatags, SetMetatags] = useState("");

  useEffect(() => {
    for (var i = 0; i < props.sections.length; i++) {
      if (props.sections[i].copy_section_id === 211) {
        dispatch(getLandingHomeMetatag(props.sections[i].id));
      }
    }
  }, [dispatch]);

  useEffect(() => {
    if (!isEmpty(landinghomeMetatags)) {
      SetMetakey(landinghomeMetatags.items[0].name);
      SetMetadesc(landinghomeMetatags.items[1].name);
      SetMetatitle(landinghomeMetatags.items[2].name);
      SetMetalang(landinghomeMetatags.items[3].name);
      SetMetatags(landinghomeMetatags.items[4].name);
    }
  }, [landinghomeMetatags]);

  const meta = {
    title: "Some Meta Title",
  };

  return (
    <div>
      <Header
        metatitle={metatitle}
        metadesc={metadesc}
        metakey={metakey}
        metalang={metalang}
        metatags={metatags}
      />
      {props.sections.map((sec) => (
        <>
          {(() => {
            switch (sec.copy_section_id) {
              case 1:
                return (
                  <FilterSection pfgsections={props.sections} itemid={sec.id} />
                );
              case 2:
                return <TravelCategorys itemid={sec.id} />;
              case 3:
                return <ViewPlanSection itemid={sec.id} />;
              case 4:
                return <AgentSection itemid={sec.id} />;
              case 5:
                return <Slider itemid={sec.id} />;
              case 6:
                return <Review itemid={sec.id} />;
              case 7:
                return <PartnerSection itemid={sec.id} />;
              case 8:
                return <ContactSection itemid={sec.id} />;
              case 9:
                return <JoinSection itemid={sec.id} />;
              default:
                return "";
            }
          })()}
        </>
      ))}

      <Footer />

      <div
        className="modal custom_modal fade"
        id="signupModal"
        tabindex="-1"
        aria-labelledby="signupModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
            <div className="modal-body select_plan_body">
              <div className="agent_title buytravel_title">
                Sign up for the World Wanderer Club
              </div>
              <div className="enter_email_outer">
                <label>Enter your email</label>
                <input
                  className="select_custom selectplanselect"
                  placeholder="name@example.com"
                />
                <a
                  className="go_btn view_plan_btn aos-item aos-init aos-animate"
                  data-bs-dismiss="modal"
                  data-aos="fade-up"
                  data-bs-toggle="modal"
                  data-bs-target="#thankyouModal"
                >
                  Submit
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className="modal custom_modal fade"
        id="thankyouModal"
        tabindex="-1"
        aria-labelledby="thankyouModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
            <div className="modal-body select_plan_body">
              <div className="agent_title buytravel_title">
                Thank you for signing up!
              </div>
              <p className="thankyou_p">
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy
                text ever since the 1500s, when an unknown printer took a galley
                of type and scrambled it to make a type specimen book.
              </p>
            </div>
          </div>
        </div>
      </div>

      <Modal show={isOpen} onHide={closeModal} className="custom_modal">
        <button
          type="button"
          className="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
          onClick={closeModal}
        ></button>
        <div className="modal-body select_plan_body">
          <div className="agent_title buytravel_title">
            Sign up for the World Wanderer Club
          </div>
          <div className="enter_email_outer">
            <label>Enter your email</label>
            <input
              className="select_custom selectplanselect"
              placeholder="name@example.com"
            />
            <a
              className="go_btn view_plan_btn aos-item aos-init aos-animate"
              data-bs-dismiss="modal"
              data-aos="fade-up"
              data-bs-toggle="modal"
              data-bs-target="#thankyouModal"
            >
              Submit
            </a>
          </div>
        </div>
      </Modal>
    </div>
  );
}

export { HomeLanding };
