import { appConstants } from "../helpers/app-constants";

const intialState = {
  businessBanners: [],
  businessService: [],
  businessBottom: [],
  businessBottom2: [],
  businessBottom3: [],
  landingbusinessBottom: [],
  landingbusinessBottom2: [],
  landingbusinessBottom3: [],
  businessTab: [],
  businessGroups: [],
  businessGroups1: [],
  businessGroups2: [],
  landingbusinessGroups: [],
  landingbusinessGroups1: [],
  landingbusinessGroups2: [],
  servicemetatag: [],
  landingbusinessBanners: [],
  landingbusinessService: [],
  landingbusinessTab: [],
  landingservicemetatag: [],
  businesspopup: [],
};

// business reducere
export const Business = (state = intialState, action) => {
  switch (action.type) {
    case appConstants.GET_BUSINESS_BANNER:
      return {
        ...state,
        businessBanners: action.payload,
      };
    case appConstants.GET_LANDING_BUSINESS_BANNER:
      return {
        ...state,
        landingbusinessBanners: action.payload,
      };

    case appConstants.GET_LANDING_BUSINESS_SERVICE:
      return {
        ...state,
        landingbusinessService: action.payload,
      };
    case appConstants.GET_BUSINESS_SERVICE:
      return {
        ...state,
        businessService: action.payload,
      };
    case appConstants.GET_BUSINESS_BOTTOM:
      return {
        ...state,
        businessBottom: action.payload,
      };
    case appConstants.GET_BUSINESS_BOTTOM2:
      return {
        ...state,
        businessBottom2: action.payload,
      };
    case appConstants.GET_BUSINESS_BOTTOM3:
      return {
        ...state,
        businessBottom3: action.payload,
      };
    case appConstants.GET_LANDING_BUSINESS_BOTTOM:
      return {
        ...state,
        landingbusinessBottom: action.payload,
      };
    case appConstants.GET_LANDING_BUSINESS_BOTTOM2:
      return {
        ...state,
        landingbusinessBottom2: action.payload,
      };
    case appConstants.GET_LANDING_BUSINESS_BOTTOM3:
      return {
        ...state,
        landingbusinessBottom3: action.payload,
      };

    case appConstants.GET_LANDING_BUSINESS_TAB:
      return {
        ...state,
        landingbusinessTab: action.payload,
      };
    case appConstants.GET_BUSINESS_POPUP:
      return {
        ...state,
        businesspopup: action.payload,
      };
    case appConstants.GET_BUSINESS_TAB:
      return {
        ...state,
        businessTab: action.payload,
      };
    case appConstants.GET_BUSINESS_GROUPS:
      return {
        ...state,
        businessGroups: action.payload,
      };
    case appConstants.GET_BUSINESS_GROUPS1:
      return {
        ...state,
        businessGroups1: action.payload,
      };
    case appConstants.GET_BUSINESS_GROUPS2:
      return {
        ...state,
        businessGroups2: action.payload,
      };
      case appConstants.GET_BUSINESS_GROUPS_ALL:
      return {
        ...state,
        businessGroupsAll: action.payload,
      };
    case appConstants.GET_LANDING_BUSINESS_GROUPS:
      return {
        ...state,
        landingbusinessGroups: action.payload,
      };
    case appConstants.GET_LANDING_BUSINESS_GROUPS1:
      return {
        ...state,
        landingbusinessGroups1: action.payload,
      };
    case appConstants.GET_LANDING_BUSINESS_GROUPS2:
      return {
        ...state,
        landingbusinessGroups2: action.payload,
      };
    case appConstants.GET_SERVICE_METATAG:
      return {
        ...state,
        servicemetatag: action.payload,
      };
    case appConstants.GET_LANDING_SERVICE_METATAG:
      return {
        ...state,
        landingservicemetatag: action.payload,
      };

    default:
      return state;
  }
};
