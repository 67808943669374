import React, { Fragment, useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
// import { connect,useSelector, } from 'react-redux';
import { Link } from "react-router-dom";
import MetaTags from "react-meta-tags";

import {
  getMenus,
  getSelectedLanguage,
  setSelectedLanguage,
} from "../../actions";
import Item from "../../item";
import { appConstants } from "../../helpers/app-constants";
import isEmpty from "../../isEmpty";
import { Select } from "react-select";
import { getBusinessPopup } from './../../actions/bussiness.action';
import { Modal } from "react-bootstrap";
import { getContactForm, saveContactForm } from "../../actions";
import ReactHtmlParser from "react-html-parser";

import { bounce, flipInX, flipInY, flipOutX, flip } from 'react-animations';
import Radium, { StyleRoot } from 'radium';

// include styles

function ServicePopup(props) {
  const {

    businessTab,
    businesspopup,
  } = useSelector((state) => {
    return state.Business;
  });


  const styles = {
    bounce: {
      animation: '1s',
      animationName: Radium.keyframes(flipInY, 'flip')
    }
  }

  const dispatch = useDispatch();


  const [isOpen, SetIsOpen] = useState(false);

  const closeModal = () => {
    props.closeModal();
    SetIsOpen(false);

  };

  const openModal = () => {
    closeModal2();
    SetIsOpen(props.isOpen);

  };


  const [isOpen2, SetIsOpen2] = useState(false);

  const closeModal2 = () => {
    SetIsOpen2(false);

  };

  const openModal2 = () => {
    // props.openModal2();
    SetIsOpen2(true);

  };

  const { contactForm } = useSelector((state) => {
    return state.Contact;
  });

  const { saveContact } = useSelector((state) => {
    return state.Contact;
  });


  const [formData, SetFormData] = useState({
    name: "",
    email: "",
    service: [],
    submitted: false,
    type_id: 4
  });


  const [names, SetNames] = useState([]);

  const [emails, SetEmails] = useState([]);
  const [service, SetService] = useState([]);


  const [name, SetName] = useState('');

  const [email, SetEmail] = useState('');
  const [message, SetMessage] = useState([]);
  const [sign, SetSign] = useState([]);
  const [button, SetButton] = useState([]);
  const [msg, SetMsg] = useState('');
  const [errors, SetErrors] = useState({});
  const [check, SetCheck] = useState([]);
  const [planId, SetPlanId] = useState([]);

  function handleValidation(e) {
    let errors = {};
    let formIsValid = true;

    if (!name) {
      formIsValid = false;
      errors["name"] = namevalid;

    }

    if (planId.length <= 0) {
      formIsValid = false;
      errors["check"] = servicevalid;
    }
    if (!email) {
      formIsValid = false;
      errors["email"] = mailvalid;
    } else {
      let regEmail = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (!regEmail.test(email)) {
        formIsValid = false;
        errors["email"] = mailvalid;
      }
    }

    SetErrors(errors);
    return formIsValid;
  }


  const handleChange = (event) => {
    if (event.target.name == 'name') {
      SetName(event.currentTarget.value);
    };
    if (event.target.name == 'email') {
      SetEmail(event.currentTarget.value);
    }

    if (event.target.name == 'service') {

      const checked = event.target.checked;
      const val = event.target.getAttribute("eid");
      if (checked) {

        var plans = [...planId];
        plans = [...planId, parseInt(event.target.getAttribute("eid"))];

        SetPlanId(plans);

      } else {
        var plans = [...planId];

        plans.splice(planId.indexOf(parseInt(event.target.getAttribute("eid"))), 1);
        SetPlanId(plans);
      }
      SetCheck(plans);
    }

    if (event.target.name == 'service') {
      SetFormData({ ...formData, [event.target.name]: plans });
    } else {
      SetFormData({ ...formData, [event.target.name]: event.target.value });

    }

  }

  function handleSubmit() {
    if (handleValidation()) {
      dispatch(saveContactForm(formData));

    }
  };

  useEffect(() => {

    if (saveContact.success) {

      console.log('saveContact.success');
      console.log(saveContact)
      closeModal();

      openModal2();
      // props.openModal2()
    }
  }, [saveContact]);


  const [pophead, SetPophead] = useState("");
  const [popsubhead, SetPopsubhead] = useState("");
  const [poptitle, SetPoptitle] = useState("");
  const [popname, SetPopname] = useState("");
  const [popemail, SetPopemail] = useState("");
  const [popsub, SetPopsub] = useState("");
  const [thanks, SetThanks] = useState("");
  const [thanksdesc, SetThanksdesc] = useState("");
  const [namevalid, SetNamevalid] = useState("");
  const [mailvalid, SetMailvalid] = useState("");
  const [servicevalid, SetServicevalid] = useState("");

  useEffect(() => {


    //  props.closeModal2();
    dispatch(getBusinessPopup(props.section_id));


    if (props.isOpen) {
      openModal();

    }

  }, [dispatch]);


  useEffect(() => {

    if (!isEmpty(businessTab)) {
      const items = businessTab.items;
      const ser = businessTab.slides;

      if (!isEmpty(ser)) {
        SetService(ser);
      }
    }

    if (!isEmpty(businesspopup)) {
      const itemss = businesspopup.items;
      if (!isEmpty(itemss)) {
        SetPophead(itemss[0]?.name);
        SetPopsubhead(itemss[1]?.name);
        SetPoptitle(itemss[2]?.name);
        SetPopname(itemss[3]?.name);
        SetPopemail(itemss[4]?.name);
        SetPopsub(itemss[5]?.name);
        SetThanks(itemss[6]?.name);
        SetThanksdesc(itemss[7]?.name);
        SetNamevalid(itemss[8]?.name);
        SetMailvalid(itemss[9]?.name);
        SetServicevalid(itemss[10]?.name);
      } else {
        SetPophead();
        SetPopsubhead();
        SetPoptitle();
        SetPopname();
        SetPopemail();
        SetPopsub();
        SetThanks();
        SetThanksdesc();
        SetNamevalid();
        SetMailvalid();
        SetServicevalid();
      }

    }
  }, [businesspopup, businessTab]);


  return (

    <div>
      <StyleRoot >
        <Modal
          show={isOpen}
          onHide={closeModal}
          className="custom_modal"
          tabindex="-1"
          aria-labelledby="selectplanModalLabel"
          aria-hidden="true"
        >

          <div style={styles.bounce}>

            <button type="button" class="btn-close" onClick={() => closeModal()}></button>
            <div class="modal-body select_plan_body select_plan_body1 request_info_body" >
              <div class="agent_title buytravel_title">{pophead}</div>
              <p class="p_text">{ReactHtmlParser(popsubhead)}</p>
            </div>
            <div class="modal-body select_plan_body select_plan_body1 select_plan_body2">
              <div class="row">
                <div class="col-md-12">
                  <form method="post" >
                    <div class="check_apply">
                      <p class="p_text">{poptitle}</p>

                      <div class="request_check_outer">
                        <div class="request_check row">
                          {service.map((item, index) => (
                            <label class="signup_check col-md-6" key={index}>
                              {item.name_en}
                              <input name="service" eid={item.id} type="checkbox" value={item.id} checked={planId.includes(item.id) ? true : false}
                                onChange={handleChange} />
                              <span class="checkmark"></span>
                            </label>

                          ))}
                          <br />
                          <span class="" style={{ color: "red" }}>
                            {errors["check"]}
                          </span>
                        </div>
                      </div>
                      <br />
                      <div class="contact_input_inner">
                        <div class="contact_info_first ">
                          <label class="custom_label">{popname}</label>
                          <div className="email_input_div2">
                            <input
                              class="contact_input_field"
                              type="text"
                              placeholder=""
                              name="name"
                              onChange={handleChange}
                              value={name}
                            />
                            <br />
                            <span class="error_contact2" style={{ color: "red" }}>
                              {errors["name"]}
                            </span>
                          </div>
                        </div>

                        <div class="contact_info_first">
                          <label class="custom_label">{popemail}</label>
                          <div className="email_input_div2">
                            <input
                              class="contact_input_field"
                              type="text"
                              placeholder=""
                              name="email"
                              onChange={handleChange}
                              value={email}
                            />
                            <br />
                            <span class="error_contact2" style={{ color: "red" }}>
                              {errors["email"]}
                            </span>
                          </div>
                        </div>


                      </div>
                      <div class="view_plan_inner submit_outr">
                        <a
                          class="go_btn view_plan_btn"
                          data-aos="fade-up"

                          onClick={handleSubmit}
                        >
                          {popsub}
                        </a>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </Modal>

      </StyleRoot>

      <Modal
        className="modal custom_modal fade "
        key={props.key}
        show={isOpen2}
        onHide={closeModal2}
      >
        <button type="button" class="btn-close" onClick={() => closeModal2()}></button>

        <div className="modal-body select_plan_body">
          <div className="agent_title buytravel_title">{thanks}</div>
          <p className="thankyou_p">{ReactHtmlParser(thanksdesc)}</p>
        </div>
      </Modal>
    </div>
  );
}


export { ServicePopup };
