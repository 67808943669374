import axios from "axios";
import { returnErrors } from "../actions/error.action";
import { appConstants, config } from "../helpers/app-constants";

import { loaderDispatch } from "../helpers/app-constants";

// get header footer list
export const getHomeSlider = () => (dispatch) => {
  loaderDispatch(dispatch, true);
  axios
    .get(appConstants.BACKEND_URL + "getHomeSilder", config)
    .then((res) => {
      // console.log(res);
      loaderDispatch(dispatch, false);
      dispatch({ type: appConstants.GET_HOME_SLIDER, payload: res.data.data });
    })
    .catch((err) => {
      // console.log(err);
      loaderDispatch(dispatch, false);
      dispatch(returnErrors(err.response, err.response));
    });
};

export const getLandingSlider = (id) => (dispatch) => {
  loaderDispatch(dispatch, true);
  axios
    .get(appConstants.BACKEND_URL + "getLandingSilder/" + id, config)
    .then((res) => {
      // console.log(res);
      loaderDispatch(dispatch, false);
      dispatch({
        type: appConstants.GET_LANDING_SLIDER,
        payload: res.data.data,
      });
    })
    .catch((err) => {
      // console.log(err);
      loaderDispatch(dispatch, false);
      dispatch(returnErrors(err.response, err.response));
    });
};

export const getLandingValidation = (id) => (dispatch) => {
  loaderDispatch(dispatch, true);
  axios
    .get(appConstants.BACKEND_URL + "getHomeSectionItem/" + id, config)
    .then((res) => {
      // console.log(res);
      loaderDispatch(dispatch, false);
      dispatch({
        type: appConstants.GET_LANDING_VALIDATION,
        payload: res.data.data,
      });
    })
    .catch((err) => {
      loaderDispatch(dispatch, false);
      dispatch(returnErrors(err.response, err.response));
    });
};

export const getLandingStatictext = (id) => (dispatch) => {
  loaderDispatch(dispatch, true);
  axios
    .get(appConstants.BACKEND_URL + "getHomeSectionItem/" + id, config)
    .then((res) => {
      // console.log(res);
      loaderDispatch(dispatch, false);
      dispatch({
        type: appConstants.GET_LANDING_STATIC,
        payload: res.data.data,
      });
    })
    .catch((err) => {
      loaderDispatch(dispatch, false);
      dispatch(returnErrors(err.response, err.response));
    });
};

export const getHomeReviews = () => (dispatch) => {
  loaderDispatch(dispatch, true);
  axios
    .get(appConstants.BACKEND_URL + "getHomeReviews", config)
    .then((res) => {
      // console.log(res);
      loaderDispatch(dispatch, false);
      dispatch({ type: appConstants.GET_HOME_REVIEWS, payload: res.data.data });
    })
    .catch((err) => {
      loaderDispatch(dispatch, false);
      dispatch(returnErrors(err.response, err.response));
    });
};
export const getLandingReviews = (id) => (dispatch) => {
  loaderDispatch(dispatch, true);
  axios
    .get(appConstants.BACKEND_URL + "getLandingReviews/" + id, config)
    .then((res) => {
      // console.log(res);
      loaderDispatch(dispatch, false);
      dispatch({
        type: appConstants.GET_LANDING_REVIEWS,
        payload: res.data.data,
      });
    })
    .catch((err) => {
      loaderDispatch(dispatch, false);
      dispatch(returnErrors(err.response, err.response));
    });
};

export const getHomeCategory2 = () => (dispatch) => {
  loaderDispatch(dispatch, true);
  axios
    .get(appConstants.BACKEND_URL + "getHomeTravelCategory", config)
    .then((res) => {
      console.log("ajay");
      console.log(res);
      loaderDispatch(dispatch, false);
      dispatch({
        type: appConstants.GET_HOME_TRAVELCATGORIES,
        payload: res.data.data,
      });
    })
    .catch((err) => {
      loaderDispatch(dispatch, false);
      dispatch(returnErrors(err.response, err.response));
    });
};

export const getHomePartners = () => (dispatch) => {
  loaderDispatch(dispatch, true);
  axios
    .get(appConstants.BACKEND_URL + "getHomePartners", config)
    .then((res) => {
      // console.log(res);
      loaderDispatch(dispatch, false);
      dispatch({
        type: appConstants.GET_HOME_PARTNERS,
        payload: res.data.data,
      });
    })
    .catch((err) => {
      loaderDispatch(dispatch, false);
      dispatch(returnErrors(err.response, err.response));
    });
};
export const getLandingPartners = (id) => (dispatch) => {
  loaderDispatch(dispatch, true);
  axios
    .get(appConstants.BACKEND_URL + "getLandingPartners/" + id, config)
    .then((res) => {
      // console.log(res);
      loaderDispatch(dispatch, false);
      dispatch({
        type: appConstants.GET_LANDING_PARTNERS,
        payload: res.data.data,
      });
    })
    .catch((err) => {
      loaderDispatch(dispatch, false);
      dispatch(returnErrors(err.response, err.response));
    });
};

export const getHomeFilter = (id = "") => (dispatch) => {
  loaderDispatch(dispatch, true);
  axios
    .get(appConstants.BACKEND_URL + "getHomeSectionItem/" + id, config)
    .then((res) => {
     
      loaderDispatch(dispatch, false);
      dispatch({ type: appConstants.GET_HOME_FILTER, payload: res.data.data });
    })
    .catch((err) => {
      loaderDispatch(dispatch, false);
      dispatch(returnErrors(err.response, err.response));
    });
};
export const getLandingHomeFilter = (id = "") => (dispatch) => {
  loaderDispatch(dispatch, true);
  axios
    .get(appConstants.BACKEND_URL + "getHomeSectionItem/" + id, config)
    .then((res) => {
      // console.log(res);
      loaderDispatch(dispatch, false);
      dispatch({
        type: appConstants.GET_LANDING_HOME_FILTER,
        payload: res.data.data,
      });
    })
    .catch((err) => {
      loaderDispatch(dispatch, false);
      dispatch(returnErrors(err.response, err.response));
    });
};

export const getStatictext = (id = "") => (dispatch) => {
  loaderDispatch(dispatch, true);
  axios
    .get(appConstants.BACKEND_URL + "getHomeSectionItem/" + id, config)
    .then((res) => {
      // console.log('static test',id);
      loaderDispatch(dispatch, false);
      dispatch({ type: appConstants.GET_STATIC_TEXT, payload: res.data.data });
    })
    .catch((err) => {
      loaderDispatch(dispatch, false);
      dispatch(returnErrors(err.response, err.response));
    });
};

export const getStaticValidation = (id = "") => (dispatch) => {
  loaderDispatch(dispatch, true);
  axios
    .get(appConstants.BACKEND_URL + "getHomeSectionItem/" + id, config)
    .then((res) => {
      // console.log(res);
      loaderDispatch(dispatch, false);
      dispatch({
        type: appConstants.GET_STATIC_VALIDATION,
        payload: res.data.data,
      });
    })
    .catch((err) => {
      loaderDispatch(dispatch, false);
      dispatch(returnErrors(err.response, err.response));
    });
};

export const getInsuranceStaticText = (id = "") => (dispatch) => {
  loaderDispatch(dispatch, true);
  axios
    .get(appConstants.BACKEND_URL + "getHomeSectionItem/209", config)
    .then((res) => {
      // console.log(res);
      loaderDispatch(dispatch, false);
      dispatch({
        type: appConstants.GET_INS_STATIC_TEXT,
        payload: res.data.data,
      });
    })
    .catch((err) => {
      loaderDispatch(dispatch, false);
      dispatch(returnErrors(err.response, err.response));
    });
};
export const getHomeMetatag = () => (dispatch) => {
  loaderDispatch(dispatch, true);
  axios
    .get(appConstants.BACKEND_URL + "getHomeSectionItem/211", config)
    .then((res) => {
      // console.log(res);
      loaderDispatch(dispatch, false);
      dispatch({
        type: appConstants.GET_HOME_METATAGS,
        payload: res.data.data,
      });
    })
    .catch((err) => {
      loaderDispatch(dispatch, false);
      dispatch(returnErrors(err.response, err.response));
    });
};
export const getLandingHomeMetatag = (id) => (dispatch) => {
  loaderDispatch(dispatch, true);
  axios
    .get(appConstants.BACKEND_URL + "getHomeSectionItem/" + id, config)
    .then((res) => {
      // console.log(res);
      loaderDispatch(dispatch, false);
      dispatch({
        type: appConstants.GET_LANDING_HOME_METATAGS,
        payload: res.data.data,
      });
    })
    .catch((err) => {
      loaderDispatch(dispatch, false);
      dispatch(returnErrors(err.response, err.response));
    });
};

export const saveSubscribeForm = (formdata) => (dispatch) => {
  loaderDispatch(dispatch, true, "saveSubscribeForm");
  const config = {
    headers: {
      "Content-type": "application/json",
    },
  };
  const body = JSON.stringify(formdata);
  axios
    .post(appConstants.BACKEND_URL + "saveSubscribeForm", body, config)
    .then((res) => {
      dispatch({
        type: appConstants.SAVE_SUBSCRIBE_FORM,
        payload: res.data.data,
      });
      loaderDispatch(dispatch, false, "saveSubscribeForm");
    })
    .catch((err) => {
      loaderDispatch(dispatch, false, "saveSubscribeForm");
      dispatch(returnErrors(err.response, err.response));
    });
};

export const getFilterMetatag = () => (dispatch) => {
  loaderDispatch(dispatch, true);
  axios
    .get(appConstants.BACKEND_URL + "getHomeSectionItem/216", config)
    .then((res) => {
      // console.log(res);
      loaderDispatch(dispatch, false);
      dispatch({
        type: appConstants.GET_FILTER_METATAG,
        payload: res.data.data,
      });
    })
    .catch((err) => {
      loaderDispatch(dispatch, false);
      dispatch(returnErrors(err.response, err.response));
    });
};

export const getHomeAgent = () => (dispatch) => {
  loaderDispatch(dispatch, true);
  axios
    .get(appConstants.BACKEND_URL + "getHomeSectionItem/4", config)
    .then((res) => {
      // console.log(res);
      loaderDispatch(dispatch, false);
      dispatch({ type: appConstants.GET_HOME_AGENT, payload: res.data.data });
    })
    .catch((err) => {
      loaderDispatch(dispatch, false);
      dispatch(returnErrors(err.response, err.response));
    });
};

export const getHomeViewPlan = () => (dispatch) => {
  loaderDispatch(dispatch, true);
  axios
    .get(appConstants.BACKEND_URL + "getHomeSectionItem/3", config)
    .then((res) => {
      // console.log(res);
      loaderDispatch(dispatch, false);
      dispatch({
        type: appConstants.GET_HOME_VIEWPLAN,
        payload: res.data.data,
      });
    })
    .catch((err) => {
      console.log(err);
      loaderDispatch(dispatch, false);
      dispatch(returnErrors(err.response, err.response));
    });
};

export const getHomeLanding = (id) => (dispatch) => {
  loaderDispatch(dispatch, true);
  axios
    .get(appConstants.BACKEND_URL + "getHomeSectionItem/" + id, config)
    .then((res) => {
      loaderDispatch(dispatch, false);
      dispatch({
        type: appConstants.GET_LANDING_VIEWPLAN,
        payload: res.data.data,
      });
    })
    .catch((err) => {
      console.log(err);
      loaderDispatch(dispatch, false);
      dispatch(returnErrors(err.response, err.response));
    });
};
export const getAgentLanding = (id) => (dispatch) => {
  loaderDispatch(dispatch, true);
  axios
    .get(appConstants.BACKEND_URL + "getHomeSectionItem/" + id, config)
    .then((res) => {
      loaderDispatch(dispatch, false);
      dispatch({
        type: appConstants.GET_LANDING_AGENGT,
        payload: res.data.data,
      });
    })
    .catch((err) => {
      console.log(err);
      loaderDispatch(dispatch, false);
      dispatch(returnErrors(err.response, err.response));
    });
};

export const getHomeJoin = () => (dispatch) => {
  loaderDispatch(dispatch, true);
  axios
    .get(appConstants.BACKEND_URL + "getHomeSectionItem/9", config)
    .then((res) => {
      loaderDispatch(dispatch, false);
      dispatch({ type: appConstants.GET_HOME_JOIN, payload: res.data.data });
    })
    .catch((err) => {
      loaderDispatch(dispatch, false);
      dispatch(returnErrors(err.response, err.response));
    });
};
export const getLandingJoin = (id) => (dispatch) => {
  loaderDispatch(dispatch, true);
  axios
    .get(appConstants.BACKEND_URL + "getHomeSectionItem/" + id, config)
    .then((res) => {
      loaderDispatch(dispatch, false);
      dispatch({ type: appConstants.GET_LANDING_JOIN, payload: res.data.data });
    })
    .catch((err) => {
      loaderDispatch(dispatch, false);
      dispatch(returnErrors(err.response, err.response));
    });
};

export const getHomeContact = () => (dispatch) => {
  loaderDispatch(dispatch, true);
  axios
    .get(appConstants.BACKEND_URL + "getHomeSectionItem/8", config)
    .then((res) => {
      // console.log(res);
      loaderDispatch(dispatch, false);
      dispatch({ type: appConstants.GET_HOME_CONTACT, payload: res.data.data });
    })
    .catch((err) => {
      loaderDispatch(dispatch, false);
      dispatch(returnErrors(err.response, err.response));
    });
};
export const getLandingContact = (id) => (dispatch) => {
  loaderDispatch(dispatch, true);
  axios
    .get(appConstants.BACKEND_URL + "getHomeSectionItem/" + id, config)
    .then((res) => {
      // console.log(res);
      loaderDispatch(dispatch, false);
      dispatch({
        type: appConstants.GET_LANDING_CONTACT,
        payload: res.data.data,
      });
    })
    .catch((err) => {
      loaderDispatch(dispatch, false);
      dispatch(returnErrors(err.response, err.response));
    });
};

export const getSocialMedia = () => (dispatch) => {
  loaderDispatch(dispatch, true);
  axios
    .get(appConstants.BACKEND_URL + "getHomeSectionItem/289", config)
    .then((res) => {
      //  console.log('res',res);
      loaderDispatch(dispatch, false);
      dispatch({ type: appConstants.GET_SOCIAL_MEDIA, payload: res.data.data });
    })
    .catch((err) => {
      loaderDispatch(dispatch, false);
      dispatch(returnErrors(err.response, err.response));
    });
};

export const getTravelTypes = () => (dispatch) => {
  loaderDispatch(dispatch, true);
  axios
    .get(appConstants.BACKEND_URL + "getHomeSectionItem/276", config)
    .then((res) => {
      // console.log(res);
      loaderDispatch(dispatch, false);
      dispatch({ type: appConstants.GET_TRAVEL_TYPE, payload: res.data.data });
    })
    .catch((err) => {
      loaderDispatch(dispatch, false);
      dispatch(returnErrors(err.response, err.response));
    });
};

export const getProducts = (catId = "") => (dispatch) => {
  loaderDispatch(dispatch, true);
  axios
    .get(appConstants.BACKEND_URL + "getProducts?cat_id=" + catId, config)
    .then((res) => {
      // console.log(res);
      loaderDispatch(dispatch, false);
      dispatch({ type: appConstants.GET_PRODUCTS, payload: res.data.data });
    })
    .catch((err) => {
      loaderDispatch(dispatch, false);
      dispatch(returnErrors(err.response, err.response));
    });
};

export const getPageId = (slug) => (dispatch) => {
  loaderDispatch(dispatch, true);
  axios
    .get(appConstants.BACKEND_URL + "getslug/" + slug, config)
    .then((res) => {
      loaderDispatch(dispatch, false);
      dispatch({
        type: appConstants.GET_PAGE_BY_SLUG,
        payload: res.data.data,
      });
    })
    .catch((err) => {
      loaderDispatch(dispatch, false);
      dispatch(returnErrors(err.response, err.response));
    });
};

export const getProductById = (id) => (dispatch) => {
  loaderDispatch(dispatch, true);
  axios
    .get(appConstants.BACKEND_URL + "getproduct/" + id, config)
    .then((res) => {
      // console.log(res);
      loaderDispatch(dispatch, false);
      dispatch({
        type: appConstants.GET_PRODUCT_BY_ID,
        payload: res.data.data,
      });
    })
    .catch((err) => {
      loaderDispatch(dispatch, false);
      dispatch(returnErrors(err.response, err.response));
    });
};

export const filter = (formdata) => (dispatch) => {
  loaderDispatch(dispatch, true);

  dispatch({ type: appConstants.FILTER_PRODUCT, payload: formdata });
};

export const getProductFilter = (formdata) => (dispatch) => {
  loaderDispatch(dispatch, true, "filterProduct");
  // Request body
  const body = JSON.stringify(formdata);
  axios
    .post(appConstants.BACKEND_URL + "filterProduct", body, config)
    .then((res) => {
      // console.log(res);

      dispatch({
        type: appConstants.GET_FILTER_PRODUCT,
        payload: res.data.data,
      });
      loaderDispatch(dispatch, false, "filterProduct");
    })
    .catch((err) => {
      loaderDispatch(dispatch, false, "filterProduct");
      dispatch(returnErrors(err.response, err.response));
    });
};

export const getShopFilterPage = () => (dispatch) => {
  loaderDispatch(dispatch, true, "filterProduct");
  axios
    .get(appConstants.BACKEND_URL + "getShopFilterPage", config)
    .then((res) => {
      // console.log(res);
      loaderDispatch(dispatch, false, "filterProduct");
      dispatch({ type: appConstants.GET_FILTER_PAGE, payload: res.data.data });
    })
    .catch((err) => {
      loaderDispatch(dispatch, false, "filterProduct");
      dispatch(returnErrors(err.response, err.response));
    });
};

export const getCountrylist = () => (dispatch) => {
  loaderDispatch(dispatch, true);

  axios
    .get(appConstants.BACKEND_URL + "getCountrylist", config)
    .then((res) => {
      // console.log(res);
      loaderDispatch(dispatch, false);
      dispatch({ type: appConstants.GET_COUNTRY_LIST, payload: res.data.data });
    })
    .catch((err) => {
      loaderDispatch(dispatch, false);
      dispatch(returnErrors(err.response, err.response));
    });
};

export const sendQuoteMail = (formdata) => (dispatch) => {
  loaderDispatch(dispatch, true, "sendQuoteMail");
  const config = {
    headers: {
      "Content-type": "application/json",
    },
  };
  // Request body
  const body = JSON.stringify(formdata);
  axios
    .post(appConstants.BACKEND_URL + "sendQuoteMail", body, config)
    .then((res) => {
      // console.log(res);

      dispatch({
        type: appConstants.SEND_QUOTE_MAIL,
        payload: res.data.data,
      });
      loaderDispatch(dispatch, false, "sendQuoteMail");
    })
    .catch((err) => {
      loaderDispatch(dispatch, false, "sendQuoteMail");
      dispatch(returnErrors(err.response, err.response));
    });
};
