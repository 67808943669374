import React, { Fragment, useState, useEffect } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { Link } from 'react-router-dom';
import { getLandingJoin } from '../../../actions';
import Item from '../../../item';
import { appConstants } from '../../../helpers/app-constants';
import isEmpty from "../../../isEmpty";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import "../../../index.css";
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';

function JoinSection(props) {

  const { landingjoinSection } = useSelector((state) => {
    return state.Home;
  });

  const dispatch = useDispatch();

  const [section, SetSection] = useState([]);
  const [heading, SetHeading] = useState("");
  const [heading2, SetHeading2] = useState("");
  const [btn, SetBtn] = useState({});

  const [image, SetImage] = useState("");
  const [slides, SetSlides] = useState([]);

  useEffect(() => {
    dispatch(getLandingJoin(props.itemid));

  }, [dispatch]);

  useEffect(() => {
    if (!isEmpty(landingjoinSection)) {
      const items = landingjoinSection.items;
      if (!isEmpty(items)) {
        SetHeading(items[0].name);
        SetHeading2(items[1].name);
        SetBtn(items[2]);
        SetImage(items[3].image);
      }

    }
  }, [landingjoinSection]);

  return (
    <section className="section_space buytravel_outer testimonial_outer club_outer ">
      <div className="club_left">
        <div className="agent_title buytravel_title aos-item" data-aos="fade-up">{heading}</div>
        <div className="buyer_title aos-item" data-aos="fade-up">{ReactHtmlParser(heading2)}</div>
        <a className="go_btn view_plan_btn aos-item" data-aos="fade-up" data-bs-toggle="modal" data-bs-target="#signupModal">{btn.name_en}</a>
      </div>
      <div className="club_right aos-item " data-aos="zoom-in">
        <img src={image} alt="club" />
      </div>
    </section>
  )
}

export { JoinSection };

