import React, { Fragment, useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import isEmpty from "../../isEmpty";
import ReactHtmlParser from "react-html-parser";
import { getChooseMetatag } from "../../actions";
import Header from "../Comman/Header";
import { Footer } from "../Comman/Footer";
import { ChooseBanner } from "./ChooseBanner";
import { ChooseMap } from "./ChooseMap";
import { ChooseSlider } from "./ChooseSlider";
import { ChooseReviewSlider } from "./ChooseReviewSlider";
import { InhouseSection } from "./InhouseSection";
import { StorySection } from "./StorySection";
import { MshStory } from "./MshStory";
import { Review } from "../Home/Review";
import "react-multi-carousel/lib/styles.css";
import Carousel from "react-multi-carousel";
import { LiveStory } from "./LiveStory";

function WhyChoose(props) {
  const { choosemetatag } = useSelector((state) => {
    return state.Choose;
  });

  const { reviews } = useSelector((state) => {
    return state.Home;
  });

  const dispatch = useDispatch();
  const [metakey, SetMetakey] = useState("");
  const [metadesc, SetMetadesc] = useState("");
  const [metatitle, SetMetatitle] = useState("");
  const [metalang, SetMetalang] = useState("");
  const [metatags, SetMetatags] = useState("");

  const [responsive2, SetResponsive2] = useState({
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
      slidesToSlide: 2, // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1024, min: 768 },
      items: 2,
      slidesToSlide: 2, // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 768, min: 1 },
      items: 1,
      slidesToSlide: 2, // optional, default to 1.
    },
  });
  const [heading, SetHeading] = useState("");
  const [slides, SetSlides] = useState([]);
  const [sliderSpeed, SetSliderSpeed] = useState(0);
  const [rating, SetRating] = useState(0);
  const [reviewCount, SetReviewCount] = useState(0);

  useEffect(() => {
    dispatch(getChooseMetatag());
  }, [dispatch]);

  useEffect(() => {
    if (!isEmpty(reviews)) {
      const items = reviews.items;
      const slides = reviews.reviews;
      if (!isEmpty(items)) {
        SetHeading(items[0].name_en);
        SetSliderSpeed(items[1].name_en);

      }

      if (!isEmpty(slides)) {
        SetSlides(slides);
      }
    }
  }, [reviews]);

  useEffect(() => {
    if (!isEmpty(choosemetatag)) {
      const items = choosemetatag.items;
      if (!isEmpty(items)) {
        SetMetakey(choosemetatag.items[0].name);
        SetMetadesc(choosemetatag.items[1].name);
        SetMetatitle(choosemetatag.items[2].name);
        SetMetalang(choosemetatag.items[3].name);
        SetMetatags(choosemetatag.items[4].name);
      }
    }
  }, [choosemetatag]);
  
 

  return (
    <div>
      <Header
        metatitle={metatitle}
        metadesc={metadesc}
        metakey={metakey}
        metalang={metalang}
        metatags={metatags}
      />

      <ChooseBanner />
      <ChooseMap />
      <ChooseSlider />
      <ChooseReviewSlider />
      <InhouseSection />
      <StorySection />
      <section className="section_space buytravel_outer testimonial_outer review_verified_outer11">
      <div className="container custom_container">
        <div className="row">
          <div className="col-md-12">
            <div
              className="agent_title buytravel_title aos-item"
              data-aos="fade-up"
            >
              {heading}
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
             <div className="review_div_new">
              <div className="review_div_new_right crousel_review_right">
              <Carousel
                responsive={responsive2}
                additionalTransfrom={0}
                arrows
                autoPlay={true}
                autoPlaySpeed={parseInt(sliderSpeed)}
                centerMode={false}
                draggable
                focusOnSelect={false}
                infinite
                keyBoardControl
                renderButtonGroupOutside={false}
                renderDotsOutside={false}
                // showDots
                sliderClass=""
                slidesToSlide={1}
                swipeable
                containerclassName="carousel-container"
                removeArrowOnDeviceType={["tablet", "mobile"]}
                deviceType={props.deviceType}
                dotListclassName="rec-dot"
                itemclassName="carousel-item-padding-40-px"
              >
                {slides.map((item, index) => (
                  <div className="buyer_inner aos-item" key={index} data-aos="fade-right">
                    <div id="full-stars-example-two1">
                      <div className="rating-group">
                        <label
                          aria-label="1 star"
                          className="rating__label active"
                          for="rating3-1"
                        >
                          <i className="rating__icon rating__icon--star fa fa-star"></i>
                        </label>

                        <label
                          aria-label="2 stars"
                          className={
                            item.rating > 1
                              ? " rating__label active"
                              : "rating__label"
                          }
                          for="rating3-2"
                        >
                          <i className="rating__icon rating__icon--star fa fa-star"></i>
                        </label>

                        <label
                          aria-label="3 stars"
                          className={
                            item.rating > 2
                              ? " rating__label active"
                              : "rating__label"
                          }
                          for="rating3-3"
                        >
                          <i className="rating__icon rating__icon--star fa fa-star"></i>
                        </label>

                        <label
                          aria-label="4 stars"
                          className={
                            item.rating > 3
                              ? " rating__label active"
                              : "rating__label"
                          }
                          for="rating3-4"
                        >
                          <i className="rating__icon rating__icon--star fa fa-star"></i>
                        </label>

                        <label
                          aria-label="5 stars"
                          className={
                            item.rating > 4
                              ? " rating__label active"
                              : "rating__label"
                          }
                          for="rating3-5"
                        >
                          <i className="rating__icon rating__icon--star fa fa-star"></i>
                        </label>
                      </div>
                    </div>
                    <div className="buyer_title">{ReactHtmlParser(item.name_en)}</div>
                    <div className="client_title">— {item.author_name} </div>
                  </div>
                ))}
              </Carousel>
              </div>
             </div>
          </div>
        </div>
      </div>
    </section>
      <Review />
      {/* <section className="section_space buytravel_outer testimonial_outer review_verified_outer">
      <div className="container custom_container">
        <div className="row">
          <div className="col-md-12">
             <div className="review_div_new review_verified_widget">
             <div className="review_div_new_left">
                <img src="assets/images/ribbonstars4.png" />
                <div className="rating_title">7.8/10</div>
                <span class="review-display-title ng-binding">Excellent</span>
                <div className="based_title">Based on <b>45</b> Reviews</div>
                <img src="assets/images/logo_170.png" alt="logo_170" />
                <a href="#" className="review_control">Reviews subject to control <i class="fas fa-question-circle"></i></a>
              </div>
             </div>
          </div>
        </div>
      </div>
    </section> */}
      <MshStory />
      {/* <Review /> */}
      <LiveStory />

      <Footer />
    </div>
  );
}

export { WhyChoose };
