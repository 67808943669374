import React, { Fragment, useState, useEffect } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { Link } from 'react-router-dom';
import { getLandingPartners } from '../../../actions';
import Item from '../../../item';
import { appConstants } from '../../../helpers/app-constants';
import isEmpty from "../../../isEmpty";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import "../../../index.css";

function PartnerSection(props) {

  const { landpartners } = useSelector((state) => {
    return state.Home;
  });

  const dispatch = useDispatch();

  const [section, SetSection] = useState([]);
  const [heading, SetHeading] = useState("");
  const [slides, SetSlides] = useState([]);
  const [visible, SetVisible] = useState(7);

  const loadMore = () => {
    var newste = visible + 7;
    SetVisible(newste);

  }

  const loadLess = () => {
    SetVisible(7);
  }

  useEffect(() => {
    dispatch(getLandingPartners(props.itemid));

  }, [dispatch]);

  useEffect(() => {
    if (!isEmpty(landpartners)) {
      const items = landpartners.items;
      const slides = landpartners.logos;
      if (!isEmpty(items)) {
        SetHeading(items[0].name);
      }

      if (!isEmpty(slides)) {
        SetSlides(slides);
      }

    }
  }, [landpartners]);

  return (
    <section className="section_space buytravel_outer">
      <div className="container custom_container">
        <div className="row">
          <div className="col-md-12">
            <div className="agent_title buytravel_title aos-item" data-aos="fade-up">{heading}</div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12 mb-5">


            <ul className="brand_ul">
              {slides.slice(0, visible).map((item, index) => (
                <li className="aos-item " key={index} data-aos="zoom-right">
                  <img src={item.image} alt="brand" />
                </li>
              ))}

            </ul>

          </div>
          {visible < slides.length &&
            <button onClick={loadMore} className="go_btn view_plan_btn">Load more</button>
          }
          {visible > 7 &&
            <button onClick={loadLess} className="go_btn view_plan_btn">Load Less</button>
          }
        </div>
      </div>
    </section>
  )
}

export { PartnerSection };

