import React, { Fragment, useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getBusinessService } from "../../actions";
import isEmpty from "../../isEmpty";
import "react-multi-carousel/lib/styles.css";
import "../../index.css";
import ReactHtmlParser from "react-html-parser";

function BusinessService(props) {
  const { businessService } = useSelector((state) => {
    return state.Business;
  });

  const dispatch = useDispatch();
  const [heading, SetHeading] = useState("");
  const [subHead, SetSubHead] = useState("");

  useEffect(() => {
    dispatch(getBusinessService());
  }, [dispatch]);

  useEffect(() => {
    if (!isEmpty(businessService)) {
      const items = businessService.items;
      if (!isEmpty(items)) {
        SetHeading(items[0].name);
        SetSubHead(items[1].name);
      }
    }
  }, [businessService]);

  return (
    <section class="section_space contact_title_outer" style={{paddingBottom:'0px'}}>
      <div class="container custom_container">
        <div class="row">
          <div class="col-md-8 offset-md-2 col-sm-12">
            <div
              class="agent_title buytravel_title brief_title aos-item aos-init aos-animate"
              data-aos="fade-up"
            >
              {heading}
            </div>
            <p class="p_small">{ReactHtmlParser(subHead)} </p>
          </div>
        </div>
      </div>
    </section>
  );
}

export { BusinessService };
