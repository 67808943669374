import { appConstants } from "../helpers/app-constants";

const intialState = {
  bannerSection: [],
  contactTitle: [],
  contactEnquiry: [],
  contactForm: [],
  claimSection: [],
  agentSection: [],
  saveContact: [],
  contactmetatag: [],
  landingbannerSection: [],
  landingcontactTitle: [],
  landingcontactEnquiry: [],
  landingcontactForm: [],
  landingclaimSection: [],
  landingcontagentSection: [],
  landingcontactmetatag: [],
};

// contact reducer
export const Contact = (state = intialState, action) => {
  switch (action.type) {
    case appConstants.GET_CONTACT_BANNER:
      return {
        ...state,
        bannerSection: action.payload,
      };
    case appConstants.GET_CONTACT_TITLE:
      return {
        ...state,
        contactTitle: action.payload,
      };
    case appConstants.GET_LANDING_CONT_AGENT_SECTION:
      return {
        ...state,
        landingcontagentSection: action.payload,
      };

    case appConstants.GET_LANDING_CONTACT_TITLE:
      return {
        ...state,
        landingcontactTitle: action.payload,
      };
    case appConstants.GET_LANDING_CONTACT_BANNER:
      return {
        ...state,
        landingbannerSection: action.payload,
      };
    case appConstants.GET_LANDING_CONTACT_ENQUIRY:
      return {
        ...state,
        landingcontactEnquiry: action.payload,
      };
    case appConstants.GET_CONTACT_ENQUIRY:
      return {
        ...state,
        contactEnquiry: action.payload,
      };

    case appConstants.GET_LANDING_CONTACT_FORM:
      return {
        ...state,
        landingcontactForm: action.payload,
      };
    case appConstants.GET_CONTACT_FORM:
      return {
        ...state,
        contactForm: action.payload,
      };

    case appConstants.GET_LANDING_CLAIM_SECTION:
      return {
        ...state,
        landingclaimSection: action.payload,
      };
    case appConstants.GET_CLAIM_SECTION:
      return {
        ...state,
        claimSection: action.payload,
      };
    case appConstants.GET_AGENT_SECTION:
      return {
        ...state,
        agentSection: action.payload,
      };
    case appConstants.SAVE_CONTACT_FORM:
      return {
        ...state,
        saveContact: action.payload,
      };
    case appConstants.GET_CONTACT_METATAG:
      return {
        ...state,
        contactmetatag: action.payload,
      };
    case appConstants.GET_LANDING_CONTACT_METATAG:
      return {
        ...state,
        landingcontactmetatag: action.payload,
      };

    default:
      return state;
  }
};
