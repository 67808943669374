import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getFaqDynamic, getFaqMetatag } from "../../actions";
import Header from "../Comman/Header";
import { Footer } from "../Comman/Footer";
import isEmpty from "../../isEmpty";
import $ from "jquery";
import { FaqSection } from "./FaqSection";
import { ExpertSection } from "./ExpertSection";
import ReactHtmlParser from "react-html-parser";

function Faq(props) {
  const { faqDynamic, faqmetatag } = useSelector((state) => {
    return state.Faq;
  });

  const dispatch = useDispatch();

  const [faqListing, SetFaqListing] = useState([]);
  const [metakey, SetMetakey] = useState("");
  const [metadesc, SetMetadesc] = useState("");
  const [metatitle, SetMetatitle] = useState("");
  const [metalang, SetMetalang] = useState("");
  const [metatags, SetMetatags] = useState("");

  useEffect(() => {
    dispatch(getFaqDynamic());
    dispatch(getFaqMetatag());
  }, [dispatch]);

  useEffect(() => {
    if (!isEmpty(faqDynamic)) {
      const items = faqDynamic;
      if (!isEmpty(items)) {
        SetFaqListing(items);
      }
    }
    if (!isEmpty(faqmetatag)) {
      const items = faqmetatag.items;
      if (!isEmpty(items)) {
        SetMetakey(faqmetatag.items[0].name);
        SetMetadesc(faqmetatag.items[1].name);
        SetMetatitle(faqmetatag.items[2].name);
        SetMetalang(faqmetatag.items[3].name);
        SetMetatags(faqmetatag.items[4].name);
      }
    }
  }, [faqDynamic, faqmetatag]);

  const handlef = (e) => {
    console.log(e.target.getAttribute("class"))
    if (e.target.getAttribute("class") === "fal fa-plus") {
      console.log('a')
      $(this).attr("class", "fal fa-minus");
    } else {
      $(this).attr("class", "fal fa-plus");
    }
  };

  const handleToggleIcon = (i) => {
    if ($('#' + i).attr("class") === "fal fa-plus") {
      $('#' + i).attr("class", "fal fa-minus");
    } else {
      $('#' + i).attr("class", "fal fa-plus");
    }
  }

  const handler = (e) => {
    $("ul.faq_ul li").removeClass("active");
    $(e.target).parent("li").addClass("active");
    $(".demo,.faq_title").addClass("d-none");
    $(
      ".headfaq-" +
      e.target.getAttribute("showit") +
      ",.subfaq-" +
      e.target.getAttribute("showit")
    ).removeClass("d-none");
  };

  return (
    <div>
      <Header
        metatitle={metatitle}
        metadesc={metadesc}
        metakey={metakey}
        metalang={metalang}
        metatags={metatags}
      />

      <FaqSection />

      <section class="section_space faq_outer">
        <div class="container-fluid">
          <div class="row">
            <div class="col-md-3">
              <div class="faq_left">
                <div
                  class="panel-group mobile_category"
                  id="accordion_category"
                  role="tablist"
                  aria-multiselectable="true"
                >
                  <div class="panel panel-default">
                    <div class="panel-heading" role="tab" id="heading2">
                      <h4 class="panel-title">
                        <a
                          class="accordion-toggle"
                          role="button"
                          data-bs-toggle="collapse"
                          data-bs-parent="#accordion_category"
                          href="#collapse2"
                          aria-expanded="true"
                          aria-controls="collapse2"
                        >
                          <div
                            class="agent_title buytravel_title question_title aos-item aos-init aos-animate"
                            data-aos="fade-up"
                          >
                            SELECT A CATEGORY
                          </div>
                        </a>
                      </h4>
                    </div>
                    <div
                      id="collapse2"
                      class="panel-collapse collapse in"
                      role="tabpanel"
                      aria-labelledby="heading2"
                    >
                      <div class="panel-body">
                        <ul class="faq_ul">
                          {faqListing &&
                            faqListing.map(function (fq, index) {
                              return (
                                <li
                                  className={index == 0 ? "active" : ""}
                                  key={index}
                                >
                                  <a onClick={handler} showit={index}>
                                    {fq.name_en}
                                  </a>
                                </li>
                              );
                            })}
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="desktop_category">
                  <ul class="faq_ul">
                    {faqListing &&
                      faqListing.map(function (fq, index) {
                        return (
                          <li
                            className={index == 0 ? "active" : ""}
                            key={index}
                          >
                            <a onClick={handler} showit={index}>
                              {fq.name_en}
                            </a>
                          </li>
                        );
                      })}
                  </ul>
                </div>
              </div>
              <ExpertSection />
            </div>
            <div class="col-md-9">
              <div class="faq_right">
                {faqListing &&
                  faqListing.map(function (fq, index) {
                    var subcat = fq.faqlistcont.map(function (ct, i) {
                      return (
                        <div
                          class={
                            index == 0
                              ? `demo subfaq-${index}`
                              : `demo d-none subfaq-${index}`
                          }
                        >
                          <div class="accordion" id="accordionExample">
                            <div class="card">
                              <div class="card-header" id="headingOne">
                                <h2 class="mb-0">
                                  <button
                                    type="button"
                                    onClick={() => handleToggleIcon('ar' + i)}
                                    class="btn btn-link"
                                    data-bs-toggle="collapse"
                                    data-bs-target={
                                      "#collapse" + i + "-" + fq.id
                                    }

                                  >
                                    <span>{ct.name_en}</span>

                                    <i
                                      class="fal fa-plus"
                                      id={'ar' + i}
                                      onClick={handlef}
                                    ></i>
                                  </button>
                                </h2>
                              </div>
                              <div
                                id={"collapse" + i + "-" + fq.id}
                                class="collapse"
                                aria-labelledby="headingOne"
                                data-bs-parent="#accordionExample"
                              >
                                <div class="card-body">
                                  <p>{ReactHtmlParser(ct.content_en)}</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    });
                    return (
                      <>
                        <div
                          class={
                            index == 0
                              ? `faq_title headfaq-${index}`
                              : `faq_title catg_2 d-none headfaq-${index}`
                          }
                          key={index}
                        >
                          <img src={fq.image} alt="" />
                          {fq.name_en}
                        </div>
                        {subcat}
                      </>
                    );
                  })}
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
}

export { Faq };
