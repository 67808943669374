import React, { Fragment, useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getMshStory } from "../../actions";
import isEmpty from "../../isEmpty";
import "react-multi-carousel/lib/styles.css";
import "../../index.css";
import ReactHtmlParser from "react-html-parser";
function MshStory(props) {
  const { mshStory } = useSelector((state) => {
    return state.Choose;
  });

  const dispatch = useDispatch();

  const [heading, SetHeading] = useState("");
  const [backImage, SetBackImage] = useState("");
  const [parag, SetParag] = useState("");

  useEffect(() => {
    dispatch(getMshStory());
  }, [dispatch]);

  useEffect(() => {
    if (!isEmpty(mshStory)) {
      const items = mshStory.items;
      console.log(items);
      if (!isEmpty(items)) {
        SetHeading(items[0].name_en);
        SetBackImage(items[1].image);
        SetParag(items[2].name_en);
      }
    }
  }, [mshStory]);

  return (
    <section class="section_space buytravel_outer">
      <div class="container custom_container">
        <div class="row">
          <div class="col-md-12">
            <div
              class="agent_title buytravel_title aos-item"
              data-aos="fade-up"
            >
              {heading}
            </div>
            <img class="tree_img" src={backImage} alt="tree" />
            <div class="buyer_title">{ReactHtmlParser(parag)}</div>
          </div>
        </div>
      </div>
    </section>
  );
}

export { MshStory };
