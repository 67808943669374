import React, { Fragment, useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
// import { connect,useSelector, } from 'react-redux';
import { Link } from "react-router-dom";
import { getHomeReviews } from "../../actions";
import Item from "../../item";
import { appConstants } from "../../helpers/app-constants";
import isEmpty from "../../isEmpty";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import "../../index.css";
import ReactHtmlParser from "react-html-parser";
import Moment from 'moment';
import { format } from 'date-fns';
// import Ribbonstars4 from "assets/images/ribbonstars4.png";



function Review(props) {
  const { reviews } = useSelector((state) => {
    return state.Home;
  });

  const dispatch = useDispatch();

  const [section, SetSection] = useState([]);
  const [responsive, SetResponsive] = useState({
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4,
      slidesToSlide: 3, // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1024, min: 768 },
      items: 2,
      slidesToSlide: 3, // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 550, min: 1 },
      items: 1,
      slidesToSlide: 3, // optional, default to 1.
    },
  });

  const [responsive2, SetResponsive2] = useState({
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
      slidesToSlide: 2, // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1024, min: 768 },
      items: 2,
      slidesToSlide: 2, // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 768, min: 1 },
      items: 1,
      slidesToSlide: 2, // optional, default to 1.
    },
  });
  const [heading, SetHeading] = useState("");
  const [slides, SetSlides] = useState([]);
  const [sliderSpeed, SetSliderSpeed] = useState(0);
  const [rating, SetRating] = useState(0);
  const [reviewCount, SetReviewCount] = useState(0);


  useEffect(() => {
    // dispatch(getLanuageList());
    dispatch(getHomeReviews());
  }, [dispatch]);

  useEffect(() => {
    if (!isEmpty(reviews)) {
      const items = reviews.items;
      const slides = reviews.reviews;
      const rating = reviews.rating;
      const reviewCount = reviews.reviews.length;
      SetRating(rating);
      SetReviewCount(reviewCount);
      if (!isEmpty(items)) {
        SetHeading(items[0].name_en);
        SetSliderSpeed(items[1].name_en);

      }

      if (!isEmpty(slides)) {
        SetSlides(slides);
      }
    }
  }, [reviews]);

  return (
    <section className="section_space buytravel_outer testimonial_outer review_verified_outer">
      <div className="container custom_container">
        <div className="row">
          <div className="col-md-12">
            <div
              className="agent_title buytravel_title aos-item"
              data-aos="fade-up"
            >
              Reviews
            </div>
          </div>
        </div>
        <div className="row">
          <iframe src= "https://cl.avis-verifies.com/us/cache/9/5/3/95358ffb-9172-9064-79bb-c15192841720/widget4/95358ffb-9172-9064-79bb-c15192841720horizontal_index.html#/avis"></iframe>
        </div>
      </div>
    </section>
  );
}

export { Review };
