import { appConstants } from "../helpers/app-constants";

const intialState = {
  chooseBanners: [],
  chooseMap: [],
  chooseSlider: [],
  chooseReview: [],
  inhouseSection: [],
  storySection: [],
  mshStory: [],
  liveStory: [],
  choosemetatag: [],
  landingchooseBanners: [],
  landingchooseMap: [],
  landingchooseSlider: [],
  landingchooseReview: [],
  landingstorySection: [],
  landinginhouseSection: [],
  landingmshStory: [],
  landingliveStory: [],
  landingchoosemetatag: [],
};

// choose reducer
export const Choose = (state = intialState, action) => {
  switch (action.type) {
    case appConstants.GET_CHOOSE_BANNER_SECTION:
      return {
        ...state,
        chooseBanners: action.payload,
      };
    case appConstants.GET_LANDING_INHOUSE_SECTION:
      return {
        ...state,
        landinginhouseSection: action.payload,
      };
    case appConstants.GET_LANDING_MSH_STORY:
      return {
        ...state,
        landingmshStory: action.payload,
      };

    case appConstants.GET_LANDING_STORY_SECTION:
      return {
        ...state,
        landingstorySection: action.payload,
      };
    case appConstants.GET_INHOUSE_SECTION:
      return {
        ...state,
        inhouseSection: action.payload,
      };

    case appConstants.GET_CHOOSE_MAP:
      return {
        ...state,
        chooseMap: action.payload,
      };
    case appConstants.GET_CHOOSE_SLIDER:
      return {
        ...state,
        chooseSlider: action.payload,
      };
    case appConstants.GET_LANDING_CHOOSE_SLIDER:
      return {
        ...state,
        landingchooseSlider: action.payload,
      };

    case appConstants.GET_LANDING_CHOOSE_REVIEWS:
      return {
        ...state,
        landingchooseReview: action.payload,
      };
    case appConstants.GET_CHOOSE_REVIEWS:
      return {
        ...state,
        chooseReview: action.payload,
      };
    case appConstants.GET_STORY_SECTION:
      return {
        ...state,
        storySection: action.payload,
      };
    case appConstants.GET_MSH_STORY:
      return {
        ...state,
        mshStory: action.payload,
      };

    case appConstants.GET_LANDING_LIVE_STORY:
      return {
        ...state,
        landingliveStory: action.payload,
      };
    case appConstants.GET_LIVE_STORY:
      return {
        ...state,
        liveStory: action.payload,
      };
    case appConstants.GET_CHOOSE_METATAG:
      return {
        ...state,
        choosemetatag: action.payload,
      };
    case appConstants.GET_LANDING_CHOOSE_METATAG:
      return {
        ...state,
        landingchoosemetatag: action.payload,
      };

    case appConstants.GET_LANDING_CHOOSE_BANNER_SECTION:
      return {
        ...state,
        landingchooseBanners: action.payload,
      };
    case appConstants.GET_LANDING_CHOOSE_MAP:
      return {
        ...state,
        landingchooseMap: action.payload,
      };

    default:
      return state;
  }
};
