import { combineReducers } from "redux";

import { authentication } from "./auth.reducer";
import { register } from "./register.reducer";
import { alert } from "./alert.reducer";
import { MenuReducer } from "./menu.reducer";
import { errorReducer } from "./error.reducer";
import { Home } from "./home.reducer";
import { Contact } from "./contact.reducer";
import { Legal } from "./legal.reducer";
import { Choose } from "./choose.reducer";
import { Business } from "./business.reducer";
import { Faq } from "./faq.reducer";
// import { Landing } from "./landing.reducer";

const rootReducer = combineReducers({
  authentication,
  alert,
  register,
  errorReducer,
  MenuReducer,
  Home,
  Contact,
  Legal,
  Choose,
  Business,
  Faq,
  // Landing,
});

export default rootReducer;
