import React, { Fragment, useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getBusinessTab,getBusinessGroupsAll } from "../../actions";
import isEmpty from "../../isEmpty";
import "react-multi-carousel/lib/styles.css";
import "../../index.css";

function BusinessTab(props) {


  const {
    businessGroupsAll
  } = useSelector((state) => {
    return state.Business;
  });


  const { businessTab } = useSelector((state) => { 
    return state.Business;
  });

  const dispatch = useDispatch();

  const [sliderHeading, SetSliderHeading] = useState("");
  const [slides, SetSlides] = useState([]);

  useEffect(() => {
    // dispatch(getLanuageList());
    dispatch(getBusinessTab());
    dispatch(getBusinessGroupsAll());
  }, [dispatch]);

  useEffect(() => {
    if (!isEmpty(businessTab) &&
    !isEmpty(businessGroupsAll)) {
      const items = businessTab.items;
      const slides = businessTab.slides;

      if (!isEmpty(items)) {
        SetSliderHeading(items[0].name_en);
      }

      if (!isEmpty(slides)) {
        SetSlides(slides);
      }
    }
  }, [businessTab,businessGroupsAll]);

  return (
    <section class="section_space buytravel_outer testimonial_outer general_inquires professional_uls">
      <div class="container custom_container">
        <div class="row">
          <div class="col-md-12">
            <ul class="professional_ul">
              {/* {slides.map((item, index) => (
                <li>
                  <a href={`#businessGroup${index}`}>{item.name_en}</a>
                </li>
              ))} */}

            {businessGroupsAll?.map((item, index) => (
                <li>
                  <a href={`#businessGroup${index}`}>{item.sectionitems[0]?.name_en}</a>
                </li>
              ))}

            </ul>
          </div>
        </div>
      </div>
    </section>
  );
}

export { BusinessTab };
