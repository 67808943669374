import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getHomeSlider } from "../../actions";
import isEmpty from "../../isEmpty";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import "../../index.css";
import ReactHtmlParser from "react-html-parser";

function Slider(props) {
  const { homeslider } = useSelector((state) => {
    return state.Home;
  });

  const dispatch = useDispatch();

  const [homeSlider2, SetHomeSlider2] = useState([]);
  const [responsive, SetResponsive] = useState({
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4,
      slidesToSlide: 1, // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1024, min: 768 },
      items: 2,
      slidesToSlide: 1, // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 550, min: 1 },
      items: 1,
      slidesToSlide: 1, // optional, default to 1.
    },
  });

  const [responsive2, SetResponsive2] = useState({
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 2,
      slidesToSlide: 1, // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1024, min: 768 },
      items: 2,
      slidesToSlide: 1, // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 768, min: 0 },
      items: 1,
      slidesToSlide: 1, // optional, default to 1.
    },
  });
  const [sliderHeading, SetSliderHeading] = useState("");
  const [sliderSpeed, SetSliderSpeed] = useState(0);
  const [slides, SetSlides] = useState([]);

  useEffect(() => {
    dispatch(getHomeSlider());
  }, [dispatch]);

  useEffect(() => {
    if (!isEmpty(homeslider)) {
      const items = homeslider.items;
      const slides = homeslider.slides;
      if (!isEmpty(items)) {
        SetSliderHeading(items[0].name_en);
        SetSliderSpeed(items[1].name_en);
      }

      if (!isEmpty(slides)) {
        SetSlides(slides);
      }
    }
  }, [homeslider]);

  return (
    <section className="section_space buytravel_outer">
      <div className="container custom_container">
        <div className="row">
          <div className="col-md-12">
            <div
              className="agent_title buytravel_title aos-item"
              data-aos="fade-up"
            >
              {sliderHeading}
            </div>
          </div>
        </div>
        <div className="row">

          <div className="col-md-12">

            <Carousel
              responsive={responsive}
              additionalTransfrom={0}
              arrows
              autoPlay={true}
              autoPlaySpeed={parseInt(sliderSpeed)}
              centerMode={false}
              draggable
              focusOnSelect={false}
              infinite
              keyBoardControl
              minimumTouchDrag={80}
              renderButtonGroupOutside={false}
              renderDotsOutside={false}
              showDots
              sliderClass=""
              slidesToSlide={1}
              swipeable
              containerclassName="carousel-container"
              removeArrowOnDeviceType={["tablet", "mobile"]}
              deviceType={props.deviceType}
              dotListclassName="rec-dot"
              itemclassName="carousel-item-padding-40-px"
            >
              {slides.map((item, index) => (
                <div className="buyer_inner aos-item" key={index} data-aos="fade-right">
                  <img src={item.image} alt="b1" />
                  <div className="buyer_title">
                    {ReactHtmlParser(item.name_en)}
                  </div>
                </div>
              ))}
            </Carousel>
          </div>
        </div>
      </div>
    </section>
  );
}

export { Slider };
