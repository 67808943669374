
import React from 'react'
import Header from "../Comman/Header";
import { useSelector, useDispatch } from "react-redux";
import { Footer } from '../Comman/Footer';
const PageNotFound = () => {
    return (
        <div id="wrapper">
            <Header />
            <div className="text-center mt-4" id="info" style={{ height: "250px" }}>
                <h3>Sorry, This page could not be found</h3>
            </div>
            <Footer />
        </div >

    )
}

export default PageNotFound