import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getHomeCategory2, getStatictext } from "../../actions";
import isEmpty from "../../isEmpty";
import "react-multi-carousel/lib/styles.css";
import "../../index.css";
import { appConstants } from './../../helpers/app-constants';

function TravelCategorys(props) {
  const { travelCategory } = useSelector((state) => {
    return state.Home;
  });

  const dispatch = useDispatch();

  const [visible, SetVisible] = useState(6);

  const [loadmoreplace, SetLoadmoreplace] = useState("");
  const [loadlessplace, SetLoadlessplace] = useState("");

  const [slides, SetSlides] = useState([]);

  const loadMore = () => {
    var newste = visible + 6;
    SetVisible(newste);
  };

  const loadLess = () => {
    SetVisible(6);
  };
  const { statictext } = useSelector((state) => {
    return state.Home;
  });

  useEffect(() => {
    dispatch(getHomeCategory2());
    dispatch(getStatictext());
  }, [dispatch]);

  useEffect(() => {
    if (!isEmpty(travelCategory)) {
      const slides = travelCategory.categories;
      if (!isEmpty(slides)) {
        SetSlides(slides);
      }
    }
    if (!isEmpty(statictext)) {
      SetLoadmoreplace(statictext.items[8].name);
      SetLoadlessplace(statictext.items[9].name);
    }
  }, [travelCategory, statictext]);

  return (
    <section className="section_space service_outer">
      <div className="container custom_container">
        <div className="row">
          {
            slides.map((item, index) => (
              <div className="col-md-2 mb-4" key={index}>
                <div className="service_inner aos-item" style={{ cursor: "pointer" }} onClick={(e) => {
                  e.preventDefault();
                  let url = appConstants.FRONTEND_URL + '/shop-insurance-filter?category_id=' + item.id;
                  if (item.id === 9) {
                    url = appConstants.FRONTEND_URL + '/shop-insurance-service';
                  } 
                  window.location.href = url;
                }} data-aos="zoom-in">
                  <div className="service_img">
                    <img src={item.back_image} alt="sbg1" />
                  </div>
                  <div className="service_content">
                    <img src={item.image} alt="s1" />
                    <div className="service_title">{item.name_en}</div>
                  </div>
                  <div className="service_overlay"></div>
                </div>
              </div>
            ))
          }

        </div>
      </div>
    </section>
  );
}

export { TravelCategorys };
