import React, { Fragment, useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getLandingLegalBanner } from "../../../actions";
import Item from "../../../item";
import { appConstants } from "../../../helpers/app-constants";
import isEmpty from "../../../isEmpty";
import "react-multi-carousel/lib/styles.css";
import "../../../index.css";
import ReactHtmlParser, {
  processNodes,
  convertNodeToElement,
  htmlparser2,
} from "react-html-parser";

function LegalBanner(props) {
  const { landinglegalBanner } = useSelector((state) => {
    return state.Legal;
  });

  const dispatch = useDispatch();

  const [heading, SetHeading] = useState("");
  const [backImage, SetBackImage] = useState("");

  useEffect(() => {
    dispatch(getLandingLegalBanner(props.itemid));
  }, [dispatch]);

  useEffect(() => {
    if (!isEmpty(landinglegalBanner)) {
      const items = landinglegalBanner.items;
      console.log(items);
      if (!isEmpty(items)) {
        SetHeading(items[0].name_en);
        SetBackImage(items[1].image);
      }
    }
  }, [landinglegalBanner]);

  const bannerstyle = { backgroundImage: "url(" + backImage + ")" };
  return (
    <section
      class="section_space banner_outer why_choose faq_banner"
      style={bannerstyle}
    >
      <div class="overlay"></div>
      <div class="container">
        <div class="row align-items-center">
          <div class="col-md-12">
            <div class="banner_inner">
              <div
                class="travel_title why_choose_title aos-item"
                data-aos="fade-up"
              >
                {heading}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export { LegalBanner };
