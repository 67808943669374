import React, { Fragment, useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
// import { connect,useSelector, } from 'react-redux';
import { Link } from "react-router-dom";
import { getLandingStorySection } from "../../../actions";
import Item from "../../../item";
import { appConstants } from "../../../helpers/app-constants";
import isEmpty from "../../../isEmpty";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import "../../../index.css";
import ReactHtmlParser, {
  processNodes,
  convertNodeToElement,
  htmlparser2,
} from "react-html-parser";

function StorySection(props) {
  const { landingstorySection } = useSelector((state) => {
    return state.Choose;
  });

  const dispatch = useDispatch();

  const [section, SetSection] = useState([]);

  const breakPoints = [
    { width: 1, itemsToShow: 1 },
    { width: 550, itemsToShow: 1, itemsToScroll: 2 },
    { width: 768, itemsToShow: 1 },
    { width: 1200, itemsToShow: 1 },
  ];

  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
      slidesToSlide: 1, // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1024, min: 768 },
      items: 1,
      slidesToSlide: 1, // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 768, min: 1 },
      items: 1,
      slidesToSlide: 1, // optional, default to 1.
    },
  };
  const [heading, SetHeading] = useState("");
  const [slides, SetSlides] = useState([]);
  const [storyImg, SetStoryImg] = useState("");
  const [sliderSpeed, SetSliderSpeed] = useState(0);


  useEffect(() => {
    // dispatch(getLanuageList());
    dispatch(getLandingStorySection(props.itemid));
  }, [dispatch]);

  useEffect(() => {
    if (!isEmpty(landingstorySection)) {
      const items = landingstorySection.items;
      const slides = landingstorySection.reviews;

      if (!isEmpty(items)) {
        SetHeading(items[0].name_en);
        SetStoryImg(items[0].image);
        SetSliderSpeed(items[1].name_en);

      }

      if (!isEmpty(slides)) {
        SetSlides(slides);
      }
    }
  }, [landingstorySection]);

  return (
    <section class="section_space buytravel_outer testimonial_outer club_outer">
      <div class="inhouse_left aos-item" data-aos="zoom-in-right">
        <img src={storyImg} alt="in_house" />
      </div>
      <div class="inhouse_right">
        <Carousel
          responsive={responsive}
          additionalTransfrom={0}
          arrows
          autoPlay={true}
          autoPlaySpeed={parseInt(sliderSpeed)}
          centerMode={false}
          draggable
          focusOnSelect={false}
          infinite
          keyBoardControl
          minimumTouchDrag={80}
          renderButtonGroupOutside={false}
          renderDotsOutside={false}
          showDots
          sliderClass=""
          slidesToSlide={1}
          swipeable
          containerClass="carousel-container"
          // removeArrowOnDeviceType={["tablet", "mobile", "desktop"]}
          deviceType={props.deviceType}
          dotListClass="rec-dot"
          itemClass="carousel-item-padding-40-px"
        >
          {slides.map((item, index) => (
            <Item key={index}>
              <div class="buyer_inner Superlative_exp">
                <div class="buyer_title clientstory_title">
                  {item.review_by}
                </div>
                <div class="buyer_title">{ReactHtmlParser(item.name)}</div>
              </div>
            </Item>
          ))}
        </Carousel>
      </div>
    </section>
  );
}

export { StorySection };
