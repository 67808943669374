import React, { Fragment, useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { getStatictext, getSocialMedia } from "../../actions";

import Item from "../../item";
import { appConstants } from "../../helpers/app-constants";
import isEmpty from "../../isEmpty";

function Footer(props) {
  const { menuList } = useSelector((state) => {
    return state.MenuReducer;
  });

  const { statictext, socialmedia } = useSelector((state) => {
    // console.log('redux',state)
    return state.Home;
  });
// console.log('statictext', statictext)
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getSocialMedia());
    dispatch(getStatictext(208));
  }, [dispatch]);

  const [menuTopLeft, setMenuTopLeft] = useState([]);
  const [menuTopRight, setMenuTopRight] = useState([]);
  const [menuTopRightFirst, setMenuTopRightFirst] = useState([]);
  const [menuLogo, setMenuLogo] = useState([]);
  const [fb, SetFb] = useState("");
  const [ld, SetLd] = useState("");
  const [tw, SetTw] = useState("");
  const [insta, SetInsta] = useState("");

  const [menuFooter, setMenuFooter] = useState([]);
  const [menuFooter2, setMenuFooter2] = useState([]);
  const [logo, setLogo] = useState([]);
  const [backtotop, SetBacktotop] = useState("Back to Top");

  useEffect(() => {
    if (!isEmpty(menuList)) {
      // console.log(menuList);

      let rightmenu = menuList.menu_top_right;

      setMenuTopLeft(menuList.menu_top_left);
      setMenuTopRight(rightmenu.splice(1));
      setMenuTopRightFirst(menuList.menu_top_right_first);
      setMenuLogo(menuList.menu_logo);
      setMenuFooter(menuList.menu_footer);
      setMenuFooter2(menuList.menu_footer2);
      setLogo(menuList.logo);
    }
    if (!isEmpty(statictext)) {
      SetBacktotop(statictext.items[10].name);
    }
    if (!isEmpty(socialmedia)) {
      SetFb(socialmedia.items[0].name);
      SetLd(socialmedia.items[1].name);
      SetTw(socialmedia.items[2].name);
      SetInsta(socialmedia.items[3].name);
    }
  }, [menuList, statictext, socialmedia]);

  let footemenu2 = menuFooter2.map((item, index) => {
    var f = item.is_external == 1 ? true : false;
    var child = false;
    if (!isEmpty(item.childs)) {
      child = true;
    }

    return child ? (
      <div>
        <li key={index} className="mobile_hide">
          {" "}
          <a className="" href="#">
            {item.title_en}
          </a>
        </li>
        <ul className="footer_ul second_ul">
          {item.childs.map((itemChild, index2) => {
            var f2 = itemChild.is_external == 1 ? true : false;
            return (
              <li key={index2} className="mobile_hide">
                {f2 ? (
                  <a className="" href={itemChild.link_url} target="_blank">
                    {itemChild.title_en}
                  </a>
                ) : (
                  <a className="" href={appConstants.FRONTEND_URL+itemChild.link_url}>
                    {itemChild.title_en}
                  </a>
                )}
              </li>
            );
          })}
        </ul>
      </div>
    ) : (
      <li className="">
        {f ? (
          <a className="" href={item.link_url} target="_blank">
            {item.title_en}
          </a>
        ) : (
          <a className="" href={appConstants.FRONTEND_URL+item.link_url}>
            {item.title_en}
          </a>
        )}
      </li>
    );
  });

  let footemenu = menuFooter.map((item, index) => {
    var f = item.is_external == 1 ? true : false;
    var child = false;
    if (!isEmpty(item.childs)) {
      child = true;
    }

    return child ? (
      <ul className="footer_ul second_ul">
        <li key={index} className="mobile_hide">
          {" "}
          <a className="" href="#">
            {item.title_en}
          </a>
        </li>
        <ul className="footer_ul second_ul  third_ul">
          {item.childs.map((itemChild, index2) => {
            var f2 = itemChild.is_external == 1 ? true : false;
            return (
              <li key={index2} className="mobile_hide">
                {f2 ? (
                  <a className="" href={itemChild.link_url} target="_blank">
                    {itemChild.title_en}
                  </a>
                ) : (
                  <a className="" href={appConstants.FRONTEND_URL+itemChild.link_url} >
                    {itemChild.title_en}
                  </a>
                )}
              </li>
            );
          })}
        </ul>
      </ul>
    ) : (
      <li className="mobile_hide">
        {f ? (
          <a className="" href={item.link_url} target="_blank">
            {item.title_en}
          </a>
        ) : (
          <a className=""  href={appConstants.FRONTEND_URL+item.link_url}>
            {item.title_en}
          </a>
        )}
      </li>
    );
  });

  // console.warn(this.props);
  return (
    <div>
      <footer className="section_space footer_custom">
        <div className="container custom_container">
          <div className="row">
            <div className="col-md-12">
              <div className="footer_uppr">
                <div className="footer_upper_left">
                  <a href="/" className="f_logo">
                    <img
                      src={logo.image ? logo.image : +"assets/images/logo.png"}
                      alt="logo"
                    />
                  </a>
                </div>
                <div className="footer_upper_right">
                  <a className="back_top" href="#">
                    {backtotop}
                    <i className="fal fa-chevron-up"></i>
                  </a>
                  <ul className="social_ul">
                    <li>
                      <a href={ld} target="_blank">
                        <i className="fab fa-linkedin"></i>
                      </a>
                    </li>
                    <li>
                      <a href={fb} target="_blank">
                        <i className="fab fa-facebook"></i>
                      </a>
                    </li>
                    <li>
                      <a href={tw} target="_blank">
                        <i className="fab fa-twitter"></i>
                      </a>
                    </li>
                    <li>
                      <a href={insta} target="_blank">
                        <i className="fab fa-instagram"></i>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-4">
              <div className="footer_first">
                <ul className="footer_ul">
                  {/* <li className="mobile_hide"><Link to="/shop-insurance-service">Shop insurance</Link></li>
                <ul className="footer_ul second_ul">
                  <li className="mobile_hide"><a href="#">Individuals</a></li>
                  <ul className="footer_ul second_ul third_ul">
                    <li className="mobile_hide"><a href="#">Canadian travellers</a></li>
                    <li className="mobile_hide"><a href="#">Visitors to Canada</a></li>
                    <li className="mobile_hide"><a href="#">International students</a></li>
                    <li className="mobile_hide"><a href="#">Expatriates & business travel</a></li>
                    <li className="mobile_hide"><a href="#">Global travellers</a></li>
                  </ul>
                </ul>
                <li className="mobile_hide"><a href="#">Group & business services</a></li> */}
                  {footemenu}
                </ul>
              </div>
            </div>
            <div className="col-md-8">
              <div className="footer_first">
                <ul className="footer_ul">
                  {footemenu2}
                  {/* <li className="mobile_hide">  <Link to="/why-choose-us">Why MSH</Link></li>
                <li className="mobile_hide"><a href="#">Support</a></li>
                <ul className="footer_ul second_ul">
                  <li className="mobile_hide"><Link to={{ pathname: "https://blog.ingleinternational.com" }} target="_blank">Travel information articles</Link></li>
                  <li className="mobile_hide">
                  <a href="/faq">FAQ</a>

                    </li>
                  <li className="mobile_hide">
                  <Link to="/contact-us">Contact us</Link>
                  </li>
                </ul>
                <li>
                <Link to="/legal">Privacy policy</Link>
                </li>
                  
                
                <li><Link to="/legal">Regulatory information</Link></li>
                <li><Link to="/legal">Terms of service</Link></li> */}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
}

export { Footer };
