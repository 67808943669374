import React, { Component } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { Switch, Route } from "react-router-loading";
import loadingImage from "./loading.gif"

import { Login } from "./components/auth/Login";
import { Register } from "./components/auth/Register";
import { Home } from "./components/Home/Home";
import { Faq } from "./components/Faq/Faq";
import { Redirect } from "react-router";

import { history } from "./helpers";
import { PrivateRoute } from "./components/PrivateRoute";
import { connect } from "react-redux";
import { alertActions } from "./actions";

import { Contact } from "./components/ContactUs/Contact";
import { Legal } from "./components/Legal/Legal";
import { WhyChoose } from "./components/WhyChoose/WhyChoose";
import { ShopInsuranceService } from "./components/ShopInsuranceService/ShopInsuranceService";
import { ShopinsuranceFilter } from "./components/ShopInsuranceFilter/ShopInsuranceFilter";
import { LandingPage } from "./components/pages/LandingPage";
import PageNotFound from "./components/pages/404-page";

import AOS from "aos";
import "aos/dist/aos.css";
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

import { appConstants } from './helpers/app-constants';

class App extends Component {
  constructor(props) {
    super(props);

    history.listen((location, action) => {
      // clear alert on location change
    });

  }

  componentDidMount() {
    AOS.init({
      duration: 3000,
      offset: -35,
    });
    AOS.refresh();

    // window.scrollTo(0, 0);
  }

  MyLoadingScreen = () => {
    return (<img src={loadingImage} className="mx-auto d-block align-items-center" />)
  }

  render() {
    return (
      <div>
        <Router history={history}>
          <Switch maxLoadingTime={700} loadingScreen={this.MyLoadingScreen}  >
            <Route exact path="/" component={Home} loading />
            <Route exact path="/faq" component={Faq} loading />
            <Route exact path="/contact-us" component={Contact} loading />
            <Route exact path="/legal" component={Legal} loading />
            <Route exact path="/why-choose-us" component={WhyChoose} loading />
            <Route
              exact
              path="/shop-insurance-service"
              component={ShopInsuranceService}
              loading
            />
            <Route
              exact
              path="/shop-insurance-filter"
              component={ShopinsuranceFilter}
              loading
            />
            <Route path="/login" component={Login} loading />
            <Route path="/register" component={Register} loading />
            <Route exact path="/landing/:slug" component={LandingPage} loading />
            <Route path="*" component={PageNotFound} loading />
          </Switch>
        </Router>
      </div>
    );
  }
}

export { App };
export default App;
