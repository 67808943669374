import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getChooseMap } from "../../actions";
import isEmpty from "../../isEmpty";
import "react-multi-carousel/lib/styles.css";
import "../../index.css";

function ChooseMap(props) {
  const { chooseMap } = useSelector((state) => {
    return state.Choose;
  });

  const dispatch = useDispatch();

  const [heading, SetHeading] = useState("");
  const [backImage, SetBackImage] = useState("");

  useEffect(() => {
    dispatch(getChooseMap());
  }, [dispatch]);

  useEffect(() => {
    if (!isEmpty(chooseMap)) {
      const items = chooseMap.items;
      if (!isEmpty(items)) {
        console.log("item is",items[0].name_en);
        SetHeading(items[0].name_en);
        SetBackImage(items[1].image);
      }
    }
  }, [chooseMap]);

  return (
    <section class="section_space">
      <div class="container custom_container">
        <div class="row">
          <div class="col-md-12">
            <div
              class="agent_title buytravel_title aos-item"
              data-aos="fade-up" style={{'whiteSpace':'pre'}}
            >
              <p>{heading}</p>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <div class="map_outer">
             
              <img class="map_img" src={backImage} alt="map" />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export { ChooseMap };
