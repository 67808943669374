import { appConstants } from '../helpers/app-constants';

const intialState = {
    menuList:[],
    SelectedLang:[],
    
    loader: true,
  };
  
  // home reducer
  export const MenuReducer = (state = intialState, action) => {

    switch (action.type) {
      
      case "LOADER":

        return {
          ...state,
          loader: action.payload,
        };
      case appConstants.GET_MENU_LISTING:

        return {
          ...state,
          menuList: action.payload,
        };
        case appConstants.GET_LANGUAGE:
        console.log(action.payload);
        return {
          ...state,
          SelectedLang: action.payload,
        };
     
      default:
        return state;
    }
  };
  