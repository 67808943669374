import React, { Fragment, useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getLandingFaqDynamic, getLandingFaqMetatag } from "../../actions";
import  Header  from "../Comman/Header";
import { Footer } from "./../Comman/Footer";
import isEmpty from "../../isEmpty";
import $ from "jquery";
import { FaqSection } from "./Sections/FaqSection";
import { FaqCategory } from "./Sections/FaqCategory";
import { ExpertSection } from "./Sections/ExpertSection";
import ReactHtmlParser, {
  processNodes,
  convertNodeToElement,
  htmlparser2,
} from "react-html-parser";

const breakPoints = [
  { width: 1, itemsToShow: 1 },
  { width: 550, itemsToShow: 2, itemsToScroll: 2 },
  { width: 768, itemsToShow: 4 },
  { width: 1200, itemsToShow: 4 },
];

const breakPoints2 = [
  { width: 1, itemsToShow: 1 },
  { width: 550, itemsToShow: 2, itemsToScroll: 2 },
  { width: 768, itemsToShow: 2 },
  { width: 1200, itemsToShow: 2 },
];

function FaqLanding(props) {
  const { faqLandingDynamic, landingfaqmetatag } = useSelector((state) => {
    return state.Faq;
  });

  const dispatch = useDispatch();

  const [section, SetSection] = useState([]);
  const [heading, SetHeading] = useState("");
  const [backImage, SetBackImage] = useState("");

  const [metakey, SetMetakey] = useState("");
  const [metadesc, SetMetadesc] = useState("");
  const [metatitle, SetMetatitle] = useState("");
  const [metalang, SetMetalang] = useState("");
  const [metatags, SetMetatags] = useState("");

  useEffect(() => {
    for (var i = 0; i < props.sections.length; i++) {
     
      if (props.sections[i].copy_section_id === 212) {
        dispatch(getLandingFaqMetatag(props.sections[i].id));
      }
    }
  }, [dispatch]);

  useEffect(() => {
   
    if (!isEmpty(landingfaqmetatag)) {
      const items = landingfaqmetatag.items;
      if (!isEmpty(items)) {
        SetMetakey(landingfaqmetatag.items[0].name);
        SetMetadesc(landingfaqmetatag.items[1].name);
        SetMetatitle(landingfaqmetatag.items[2].name);
        SetMetalang(landingfaqmetatag.items[3].name);
        SetMetatags(landingfaqmetatag.items[4].name);
      }
    }
  }, [landingfaqmetatag]);

  const handlef = (e) => {
    if (e.target.getAttribute("class") === "fal fa-plus") {
      $(e.target).attr("class", "fal fa-minus");
    } else {
      $(e.target).attr("class", "fal fa-plus");
    }
  };

  const handler = (e) => {
    $("ul.faq_ul li").removeClass("active");
    $(e.target).parent("li").addClass("active");
    $(".demo,.faq_title").addClass("d-none");
    $(
      ".headfaq-" +
        e.target.getAttribute("showit") +
        ",.subfaq-" +
        e.target.getAttribute("showit")
    ).removeClass("d-none");
  };

  const doSomethingMob = (ii) => {};
  return (
    <div>
      <Header
        metatitle={metatitle}
        metadesc={metadesc}
        metakey={metakey}
        metalang={metalang}
        metatags={metatags}
      />
      
      {props.sections.map((sec,index) => (
        <>
          {(() => {
            switch (sec.copy_section_id) {
              case 25:
                return (
                  <FaqSection  itemid={sec.id} />
                );
              case 27:
                return (
                  <FaqCategory  sections={props.sections}  />
                );
               
              default:
                return "";
            }
          })()}
        </>
      ))}
     
      <Footer />
    </div>
  );
}

export { FaqLanding };
