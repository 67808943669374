import { appConstants } from "../helpers/app-constants";

const intialState = {
  homeslider: [],
  reviews: [],
  partners: [],
  travelCategory: [],
  joinSection: [],
  filterSection: [],
  viewPlanSection: [],
  contactSection: [],
  agentSection: [],
  travelType: [],
  productList: [],
  product: [],
  filterProducts: [],
  loader: {
    ftype: "",
    state: true,
  },
  preFilter: [],
  shopFilterPage: [],
  countryList: [],
  statictext: [],
  insstatictext: [],
  staticValidation: [],
  homeMetatags: [],
  filtermetatag: [],
  landinghomeMetatags: [],
  slugTypeId: [],
  pageId: [],
  viewLandingView: [],
  viewLandingAgent: [],
  landSlider: [],
  landreviews: [],
  landpartners: [],
  landingcontactSection: [],
  landingjoinSection: [],
  landingfilterSection: [],
  sendmail: [],
  savesubscribe: [],
  socialmedia: [],
  statictexts: [],
  staticValidations: [],
};

// home reducer
export const Home = (state = intialState, action) => {
  switch (action.type) {
    case "LOADER":
      return {
        ...state,
        loader: { ftype: action.ftype, state: action.payload },
      };

    case appConstants.GET_LANDING_VALIDATION:
      return {
        ...state,
        staticValidations: action.payload,
      };
    case appConstants.SAVE_SUBSCRIBE_FORM:
      return {
        ...state,
        savesubscribe: action.payload,
      };
    case appConstants.GET_SOCIAL_MEDIA:
      return {
        ...state,
        socialmedia: action.payload,
      };

    case appConstants.SEND_QUOTE_MAIL:
      return {
        ...state,
        sendmail: action.payload,
      };
    case appConstants.GET_HOME_SLIDER:
      return {
        ...state,
        homeslider: action.payload,
      };
    case appConstants.GET_LANDING_JOIN:
      return {
        ...state,
        landingjoinSection: action.payload,
      };
    case appConstants.GET_LANDING_CONTACT:
      return {
        ...state,
        landingcontactSection: action.payload,
      };
    case appConstants.GET_LANDING_SLIDER:
      return {
        ...state,
        landSlider: action.payload,
      };
    case appConstants.GET_HOME_REVIEWS:
      return {
        ...state,
        reviews: action.payload,
      };
    case appConstants.GET_LANDING_PARTNERS:
      return {
        ...state,
        landpartners: action.payload,
      };
    case appConstants.GET_LANDING_REVIEWS:
      return {
        ...state,
        landreviews: action.payload,
      };
    case appConstants.GET_HOME_PARTNERS:
      return {
        ...state,
        partners: action.payload,
      };

    case appConstants.GET_HOME_TRAVELCATGORIES:
      return {
        ...state,
        travelCategory: action.payload,
      };
    case appConstants.GET_HOME_FILTER:
      return {
        ...state,
        filterSection: action.payload,
      };
    case appConstants.GET_LANDING_HOME_FILTER:
      return {
        ...state,
        landingfilterSection: action.payload,
      };
    case appConstants.GET_HOME_JOIN:
      return {
        ...state,
        joinSection: action.payload,
      };
    case appConstants.GET_HOME_CONTACT:
      return {
        ...state,
        contactSection: action.payload,
      };
    case appConstants.GET_HOME_VIEWPLAN:
      return {
        ...state,
        viewPlanSection: action.payload,
      };
    case appConstants.GET_LANDING_VIEWPLAN:
      return {
        ...state,
        viewLandingView: action.payload,
      };
    case appConstants.GET_LANDING_AGENGT:
      return {
        ...state,
        viewLandingAgent: action.payload,
      };
    case appConstants.GET_HOME_AGENT:
      return {
        ...state,
        agentSection: action.payload,
      };
    case appConstants.GET_PAGE_BY_SLUG:
      return {
        ...state,
        pageId: action.payload,
      };
    case appConstants.GET_LANDING_STATIC:
      return {
        ...state,
        statictexts: action.payload,
      };

    case appConstants.GET_TRAVEL_TYPE:
      return {
        ...state,
        travelType: action.payload,
      };
    case appConstants.GET_PRODUCTS:
      return {
        ...state,
        productList: action.payload,
      };
    case appConstants.GET_PRODUCT_BY_ID:
      return {
        ...state,
        product: action.payload,
      };
    case appConstants.FILTER_PRODUCT:
      return {
        ...state,
        preFilter: action.payload,
      };
    case appConstants.GET_FILTER_PRODUCT:
      return {
        ...state,
        filterProducts: action.payload,
      };
    case appConstants.GET_FILTER_PAGE:
      return {
        ...state,
        shopFilterPage: action.payload,
      };
    case appConstants.GET_COUNTRY_LIST:
      return {
        ...state,
        countryList: action.payload,
      };
    case appConstants.GET_STATIC_TEXT:
      return {
        ...state,
        statictext: action.payload,
      };
    case appConstants.GET_INS_STATIC_TEXT:
      return {
        ...state,
        insstatictext: action.payload,
      };
    case appConstants.GET_STATIC_VALIDATION:
      return {
        ...state,
        staticValidation: action.payload,
      };
    case appConstants.GET_HOME_METATAGS:
      return {
        ...state,
        homeMetatags: action.payload,
      };
    case appConstants.GET_LANDING_HOME_METATAGS:
      return {
        ...state,
        landinghomeMetatags: action.payload,
      };

    case appConstants.GET_FILTER_METATAG:
      return {
        ...state,
        filtermetatag: action.payload,
      };

    default:
      return state;
  }
};
