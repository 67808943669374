import React, { Fragment, useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getLandingContactEnquiry } from "../../../actions";
import Item from "../../../item";
import { appConstants } from "../../../helpers/app-constants";
import isEmpty from "../../../isEmpty";
import "react-multi-carousel/lib/styles.css";
import "../../../index.css";
import ReactHtmlParser, {
  processNodes,
  convertNodeToElement,
  htmlparser2,
} from "react-html-parser";

function ContactEnquiry(props) {
  const { landingcontactEnquiry } = useSelector((state) => {
    return state.Contact;
  });

  const dispatch = useDispatch();

  const [heading, SetHeading] = useState("");
  const [TollFree, SetTollFree] = useState("");
  const [TollFreeNo, SetTollFreeNo] = useState("");
  const [Worldwide, SetWorldwide] = useState("");
  const [WorldwideNo, SetWorldwideNo] = useState("");

  useEffect(() => {
    // dispatch(getLanuageList());
    dispatch(getLandingContactEnquiry(props.itemid));
  }, [dispatch]);

  useEffect(() => {
    if (!isEmpty(landingcontactEnquiry)) {
      const items = landingcontactEnquiry.items;
      if (!isEmpty(items)) {
        SetHeading(items[0].name_en);
        SetTollFree(items[1].name_en);
        SetTollFreeNo(items[2].name_en);
        SetWorldwide(items[3].name_en);
        SetWorldwideNo(items[4].name_en);
      }
    }
  }, [landingcontactEnquiry]);

  return (
    <>
      <section class="section_space buytravel_outer testimonial_outer general_inquires">
        <div class="container custom_container">
          <div class="row">
            <div class="col-md-12">
              <div class="general_inquiry_title">{heading}</div>
            </div>
          </div>
        </div>
      </section>

      <section class="section_space contact_title_outer contact_info_outer">
        <div class="container custom_container">
          <div class="row">
            <div class="col-md-12">
              <div class="contact_info_inner">
                <div class="phone_img">
                  <img src="assets/images/phone.png" alt="" />
                </div>

                <div class="contact-info-right">
                  <label class="custom_label">{TollFree}</label>
                  <div class="phone_number"> {TollFreeNo}</div>
                </div>
                <div class="contact-info-right">
                  <label class="custom_label"> {Worldwide}</label>
                  <div class="phone_number">{WorldwideNo}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export { ContactEnquiry };
