import React, { useState, useEffect, Suspense } from "react";
import { useSelector, useDispatch } from "react-redux";
import ReactHtmlParser from "react-html-parser";
import { getHomeMetatag, saveSubscribeForm, getHomeJoin } from "../../actions";
import isEmpty from "../../isEmpty";
import { Slider } from "./Slider";
// import { Header } from "../Comman/Header";
import { useHistory } from "react-router-dom";
import { Footer } from "../Comman/Footer";
import { Modal } from "react-bootstrap";
import "react-multi-carousel/lib/styles.css";
import Carousel from "react-multi-carousel";
import "../../index.css";
import { Review } from "./Review";
import { PartnerSection } from "./PartnerSection";
import { TravelCategorys } from "./TravelCategorys";
import { FilterSection } from "./FilterSection";
import { AgentSection } from "./AgentSection";
import { ContactSection } from "./ContactSection";
import { JoinSection } from "./JoinSection";
import { ViewPlanSection } from "./ViewPlanSection";
import { getHomeReviews } from "../../actions";
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

import { bounce, flipInX, flipInY, flipOutX, flipOutY, flip, lightSpeedIn, rubberBand } from 'react-animations';
import Radium, { StyleRoot } from 'radium';
import Header from '../Comman/Header';

function Home(props) {
  const { reviews } = useSelector((state) => {
    return state.Home;
  });

  // const dispatch = useDispatch();

  const [section, SetSection] = useState([]);
  const [responsive, SetResponsive] = useState({
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4,
      slidesToSlide: 3, // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1024, min: 768 },
      items: 2,
      slidesToSlide: 3, // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 550, min: 1 },
      items: 1,
      slidesToSlide: 3, // optional, default to 1.
    },
  });

  const [responsive2, SetResponsive2] = useState({
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
      slidesToSlide: 2, // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1024, min: 768 },
      items: 2,
      slidesToSlide: 2, // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 768, min: 1 },
      items: 1,
      slidesToSlide: 2, // optional, default to 1.
    },
  });
  const [heading, SetHeading] = useState("");
  const [slides, SetSlides] = useState([]);
  const [sliderSpeed, SetSliderSpeed] = useState(0);
  const [rating, SetRating] = useState(0);
  const [reviewCount, SetReviewCount] = useState(0);


  // useEffect(() => {
  //   // dispatch(getLanuageList());
  //   dispatch(getHomeReviews());
  // }, [dispatch]);

  useEffect(() => {
    if (!isEmpty(reviews)) {
      const items = reviews.items;
      const slides = reviews.reviews;
      if (!isEmpty(items)) {
        SetHeading(items[0].name_en);
        SetSliderSpeed(items[1].name_en);

      }

      if (!isEmpty(slides)) {
        SetSlides(slides);
      }
    }
  }, [reviews]);
  const [isOpen, SetIsOpen] = useState(false);
  let history = useHistory();
  const { homeMetatags, savesubscribe, joinSection } = useSelector((state) => {
    return state.Home;
  });

  const styles = {
    bounce: {
      animation: '1s',
      animationName: Radium.keyframes(flipInY, 'flip')
    }
  }

  const [formData, SetFormData] = useState({
    email: "",
    submitted: false,
  });
  const [errors, SetErrors] = useState({});
  const dispatch = useDispatch();
  const [email, SetEmail] = useState("");
  const closeModal = () => SetIsOpen(false);
  const [thanks, SetThanks] = useState(false);

  const [metakey, SetMetakey] = useState("");
  const [metadesc, SetMetadesc] = useState("");
  const [metatitle, SetMetatitle] = useState("");
  const [metalang, SetMetalang] = useState("");
  const [metatags, SetMetatags] = useState("");
  const [joinhead, SetJoinhead] = useState("");
  const [joinlabel, SetJoinlabel] = useState("");
  const [joinsubmit, SetJoinsubmit] = useState("");
  const [thankshead, SetThankshead] = useState("");
  const [thanksdesc, SetThanksdesc] = useState("");
  const [mailvalid, SetMailvalid] = useState("");

  const [showLoader, setShowLoader] = useState(true);

  useEffect(() => {
    closeLoaderIn5Seconds();
  }, []);

  useEffect(() => {
    dispatch(getHomeJoin());
    dispatch(getHomeMetatag());
  }, [dispatch]);

  useEffect(() => {
    if (!isEmpty(homeMetatags)) {
      SetMetakey(homeMetatags.items[0].name_en);
      SetMetadesc(homeMetatags.items[1].name_en);
      SetMetatitle(homeMetatags.items[2].name_en);
      SetMetalang(homeMetatags.items[3].name_en);
      SetMetatags(homeMetatags.items[4].name_en);
    }

    if (savesubscribe.success) {
      SetEmail('')
      window.$("#signupModal").modal("hide");

      window.$("#thankyouModal").modal("show");

      history.push("/");
    }
    if (!isEmpty(joinSection)) {
      const items = joinSection.items;
      SetJoinhead(items[4].name_en);
      SetJoinlabel(items[5].name_en);
      SetJoinsubmit(items[6].name_en);
      SetThankshead(items[7].name_en);
      SetThanksdesc(items[8].name_en);
      SetMailvalid(items[9].name_en);
    }
  }, [homeMetatags, savesubscribe, joinSection]);

  function handleValidation(e) {
    let errors = {};
    let formIsValid = true;

    if (!email) {
      formIsValid = false;
      errors["email"] = mailvalid;
    } else {
      let regEmail =
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (!regEmail.test(email)) {
        formIsValid = false;
        errors["email"] = mailvalid;
      }
    }
    SetErrors(errors);
    SetThanks(formIsValid);
    return formIsValid;
  }
  const handleChange = (event) => {
    if (event.target.name == "email") {
      SetEmail(event.currentTarget.value);
    }
    SetFormData({
      ...formData,
      [event.target.name]: event.currentTarget.value,
    });
  };

  function handleSubmit(e) {
    if (handleValidation()) {
      dispatch(saveSubscribeForm(formData));
    }
  }

  const closeLoaderIn5Seconds = () => {
    setTimeout(() => {
      setShowLoader(false)
    }, 5000)
  };

  return (
    <div>

      <Header
        metatitle={metatitle}
        metadesc={metadesc}
        metakey={metakey}
        metalang={metalang}
        metatags={metatags}
      />

      <FilterSection />
      <TravelCategorys />
      <ViewPlanSection />
      <AgentSection />
      <Slider />
      <section className="section_space buytravel_outer testimonial_outer review_verified_outer11">
        <div className="container custom_container">
            <div className="row">
            <div className="col-md-12">
                <div
                className="agent_title buytravel_title aos-item"
                data-aos="fade-up"
                >
                {heading}
                </div>
            </div>
            </div>
            <div className="row">
            <div className="col-md-12">
                <div className="review_div_new">
                <div className="review_div_new_right crousel_review_right">
                <Carousel
                    responsive={responsive2}
                    additionalTransfrom={0}
                    arrows
                    autoPlay={true}
                    autoPlaySpeed={parseInt(sliderSpeed)}
                    centerMode={false}
                    draggable
                    focusOnSelect={false}
                    infinite
                    keyBoardControl
                    renderButtonGroupOutside={false}
                    renderDotsOutside={false}
                    // showDots
                    sliderClass=""
                    slidesToSlide={1}
                    swipeable
                    containerclassName="carousel-container"
                    removeArrowOnDeviceType={["tablet", "mobile"]}
                    deviceType={props.deviceType}
                    dotListclassName="rec-dot"
                    itemclassName="carousel-item-padding-40-px"
                >
                    {slides.map((item, index) => (
                    <div className="buyer_inner aos-item" key={index} data-aos="fade-right">
                        <div id="full-stars-example-two1">
                        <div className="rating-group">
                            <label
                            aria-label="1 star"
                            className="rating__label active"
                            for="rating3-1"
                            >
                            <i className="rating__icon rating__icon--star fa fa-star"></i>
                            </label>

                            <label
                            aria-label="2 stars"
                            className={
                                item.rating > 1
                                ? " rating__label active"
                                : "rating__label"
                            }
                            for="rating3-2"
                            >
                            <i className="rating__icon rating__icon--star fa fa-star"></i>
                            </label>

                            <label
                            aria-label="3 stars"
                            className={
                                item.rating > 2
                                ? " rating__label active"
                                : "rating__label"
                            }
                            for="rating3-3"
                            >
                            <i className="rating__icon rating__icon--star fa fa-star"></i>
                            </label>

                            <label
                            aria-label="4 stars"
                            className={
                                item.rating > 3
                                ? " rating__label active"
                                : "rating__label"
                            }
                            for="rating3-4"
                            >
                            <i className="rating__icon rating__icon--star fa fa-star"></i>
                            </label>

                            <label
                            aria-label="5 stars"
                            className={
                                item.rating > 4
                                ? " rating__label active"
                                : "rating__label"
                            }
                            for="rating3-5"
                            >
                            <i className="rating__icon rating__icon--star fa fa-star"></i>
                            </label>
                        </div>
                        </div>
                        <div className="buyer_title">{ReactHtmlParser(item.name_en)}</div>
                        <div className="client_title">— {item.author_name} </div>
                    </div>
                    ))}
                </Carousel>
                </div>
                </div>
            </div>
            </div>
        </div>
      </section>
      <Review />
      <PartnerSection />
      <ContactSection />
      <Footer />

      <StyleRoot >

        <div
          className="modal custom_modal fade "
          id="signupModal"
          tabindex="-1"
          aria-labelledby="signupModalLabel"
          aria-hidden="true"
          style={styles.bounce}

        >
          <div className="modal-dialog">
            <div className="modal-content">
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
              <div className="modal-body select_plan_body">
                <div className="agent_title buytravel_title">{joinhead}</div>
                <div className="enter_email_outer enter_email_flexstart">
                  <label>{joinlabel}</label>
                  <div className="email_input_div">
                    <input
                      className="select_custom selectplanselect"
                      placeholder="name@example.com"
                      name="email"
                      onChange={handleChange}
                      value={email}
                      type="email"
                    />
                    <span class="error_contact" style={{ color: "red" }}>
                      {errors["email"]}
                    </span>
                  </div>
                  <a
                    className="go_btn view_plan_btn aos-item aos-init aos-animate"
                    onClick={handleSubmit}
                  >
                    {joinsubmit}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </StyleRoot>

      {/* end singup modal */}

      {/* end thanks modal */}

      <StyleRoot >
        <div
          className="modal custom_modal "
          id="thankyouModal"
          tabindex="-1"
          aria-labelledby="thankyouModalLabel"
          aria-hidden="true"
          style={styles.bounce}
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
              <div className="modal-body select_plan_body">
                <div className="agent_title buytravel_title">{thankshead}</div>
                <p className="thankyou_p">{ReactHtmlParser(thanksdesc)}</p>
              </div>
            </div>
          </div>
        </div>
      </StyleRoot>

      <StyleRoot>

        <Modal show={isOpen} onHide={closeModal} className="custom_modal ">
          <div style={styles.bounce}>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={closeModal}
            ></button>
            <div className="modal-body select_plan_body">
              <div className="agent_title buytravel_title">{joinhead}</div>
              <div className="enter_email_outer">
                <label>{joinlabel}</label>
                <input
                  className="select_custom selectplanselect"
                  placeholder="name@example.com"
                />

                <a
                  className="go_btn view_plan_btn aos-item aos-init aos-animate"
                  data-bs-dismiss="modal"
                  data-aos="fade-up"
                  data-bs-toggle="modal"
                  data-bs-target="#thankyouModal"
                >
                  {joinsubmit}
                </a>
              </div>
            </div>
          </div>
        </Modal>
      </StyleRoot>

    </div>
  );
}

export { Home };
