import React, { Fragment, useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
// import { connect,useSelector, } from 'react-redux';
import { Link } from "react-router-dom";
import {
  getLandingBusinessBottom,
  getLandingBusinessBottom2,
  getLandingBusinessBottom3,
} from "../../../actions";
import Item from "../../../item";
import { appConstants } from "../../../helpers/app-constants";
import isEmpty from "../../../isEmpty";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import "../../../index.css";
import ReactHtmlParser, {
  processNodes,
  convertNodeToElement,
  htmlparser2,
} from "react-html-parser";

function BusinessBottom(props) {
  const {
    landingbusinessBottom,
    landingbusinessBottom2,
    landingbusinessBottom3,
  } = useSelector((state) => {
    return state.Business;
  });

  const dispatch = useDispatch();

  const [section, SetSection] = useState([]);
  const [heading1, SetHeading1] = useState("");
  const [heading2, SetHeading2] = useState("");
  const [heading3, SetHeading3] = useState("");

  const [btn1, SetBtn1] = useState("");
  const [url1, SetUrl1] = useState("");
  const [btn3, SetBtn3] = useState("");
  const [url3, SetUrl3] = useState("");

  const [subHead1, SetSubHead1] = useState("");
  const [subHead2, SetSubHead2] = useState("");
  const [subHead3, SetSubHead3] = useState("");
  const [subHead4, SetSubHead4] = useState("");

  const [btn2, SetBtn2] = useState("");
  const [url2, SetUrl2] = useState("");

  useEffect(() => {
    for (var i = 0; i < props.pfgsections.length; i++) {
      if (props.pfgsections[i].copy_section_id === 12) {
        dispatch(getLandingBusinessBottom(props.pfgsections[i].id));
      }
      if (props.pfgsections[i].copy_section_id === 48) {
        dispatch(getLandingBusinessBottom2(props.pfgsections[i].id));
      }
      if (props.pfgsections[i].copy_section_id === 49) {
        dispatch(getLandingBusinessBottom3(props.pfgsections[i].id));
      }
    }
  }, [dispatch]);

  useEffect(() => {
    if (
      !isEmpty(landingbusinessBottom) &&
      !isEmpty(landingbusinessBottom2) &&
      !isEmpty(landingbusinessBottom3)
    ) {
      const items = landingbusinessBottom.items;
      const items2 = landingbusinessBottom2.items;
      const items3 = landingbusinessBottom3.items;

      if (!isEmpty(items)) {
        SetHeading1(items[0].name);
        SetSubHead1(items[1].name);
        SetBtn1(items[2].name);
        SetUrl1(items[2].url);
        SetHeading2(items2[0].name);
        SetSubHead2(items2[1].name);
        SetBtn2(items2[2].name);
        SetUrl2(items2[2].url);
        SetHeading3(items3[0].name);
        SetSubHead3(items3[1].name);
        SetBtn3(items3[3].name);
        SetUrl3(items3[3].url);
        SetSubHead4(items3[2].name);
      }
    }
  }, [landingbusinessBottom, landingbusinessBottom2, landingbusinessBottom3]);

  return (
    <section class="section_space buytravel_outer testimonial_outer club_outer service_call_outer">
      <div class="inhouse_right ready_insurance ready_insuranceright">
        <div
          class="agent_title buytravel_title question_title aos-item aos-init aos-animate"
          data-aos="fade-up"
        >
          {heading1}
        </div>
        <div class="insurance_title fixed_height_insurance aos-item aos-init" data-aos="fade-up">
          {ReactHtmlParser(subHead1)}
        </div>
        <div class="view_plan_inner aos-item aos-init" data-aos="fade-up">
          <a href={url1} class="go_btn view_plan_btn">
            <i class="fal fa-long-arrow-right"></i>
            {btn1}
          </a>
        </div>
      </div>
      <div
        class="inhouse_right ready_insurance aos-item aos-init aos-animate"
        data-aos="zoom-in"
      >
        <div
          class="agent_title buytravel_title question_title aos-item aos-init aos-animate"
          data-aos="fade-up"
        >
          {heading2}
        </div>
        <div class="insurance_title fixed_height_insurance aos-item aos-init" data-aos="fade-up">
          {ReactHtmlParser(subHead2)}
        </div>
        <div class="view_plan_inner aos-item aos-init" data-aos="fade-up">
          <a href={url2} class="go_btn view_plan_btn">
            <i class="fal fa-long-arrow-right"></i>
            {btn2}
          </a>
        </div>
      </div>
      <div class="inhouse_right ready_insuranceright">
        <div
          class="agent_title buytravel_title question_title aos-item aos-init aos-animate"
          data-aos="fade-up"
        >
          {heading3}
        </div>
        <div class="insurance_title fixed_height_insurance aos-item aos-init" data-aos="fade-up">
          {ReactHtmlParser(subHead3)}
          <a href={`tel:+${subHead4}`}><div
          class="call_title aos-item aos-init aos-animate"
          data-aos="fade-up"
          >
          {subHead4}
          </div></a>
        </div>
        
        <div class="view_plan_inner aos-item aos-init" data-aos="fade-up">
          <a href={url3} class="go_btn view_plan_btn chat_btn">
            <i class="fal fa-long-arrow-right"></i>
            {btn3}
          </a>
        </div>
      </div>
    </section>
  );
}

export { BusinessBottom };
