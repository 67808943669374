import React, { Fragment, useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { getLandingLegalMetatag } from "../../actions";

import Carousel from "react-elastic-carousel";
import Item from "../../item";
import  Header  from "../Comman/Header";
import { Footer } from "./../Comman/Footer";
import { LegalBanner } from "./Sections/LegalBanner";
import { TermsPolicy } from "./Sections/TermsPolicy";
import isEmpty from "../../isEmpty";

function LegalLanding(props) {
  const { landinglegalmetatag } = useSelector((state) => {
    return state.Legal;
  });

  const dispatch = useDispatch();
  const [metakey, SetMetakey] = useState("");
  const [metadesc, SetMetadesc] = useState("");
  const [metatitle, SetMetatitle] = useState("");
  const [metalang, SetMetalang] = useState("");
  const [metatags, SetMetatags] = useState("");
  const [sectionArr, SetSectionArr] = useState([]);
  useEffect(() => {
    for (var i = 0; i < props.sections.length; i++) {
      if (props.sections[i].copy_section_id === 214) {
        dispatch(getLandingLegalMetatag(props.sections[i].id));
      }
    }
  }, [dispatch]);

  useEffect(() => {
    if (!isEmpty(landinglegalmetatag)) {
      const items = landinglegalmetatag.items;
      if (!isEmpty(items)) {
        SetMetakey(landinglegalmetatag.items[0].name);
        SetMetadesc(landinglegalmetatag.items[1].name);
        SetMetatitle(landinglegalmetatag.items[2].name);
        SetMetalang(landinglegalmetatag.items[3].name);
        SetMetatags(landinglegalmetatag.items[4].name);
      }
    }
  }, [landinglegalmetatag]);
  return (
    <div>
      <Header
        metatitle={metatitle}
        metadesc={metadesc}
        metakey={metakey}
        metalang={metalang}
        metatags={metatags}
      />
      {props.sections.map((sec) => (
        <>
          {(() => {
            switch (sec.copy_section_id) {
              case 34:
                return (
                  <>
                    <LegalBanner itemid={sec.id} />
                  </>
                );
              case 37:
                return (
                  <>
                    <TermsPolicy pfgsections={props.sections} />
                  </>
                );
              default:
                return "";
            }
          })()}
        </>
      ))}
      <Footer />
    </div>
  );
}

export { LegalLanding };
