import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getChooseReviews } from "../../actions";
import Item from "../../item";
import isEmpty from "../../isEmpty";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import "../../index.css";
import ReactHtmlParser from "react-html-parser";

function ChooseReviewSlider(props) {
  const { chooseReview } = useSelector((state) => {
    return state.Choose;
  });

  const dispatch = useDispatch();

  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
      slidesToSlide: 1, // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1024, min: 768 },
      items: 1,
      slidesToSlide: 1, // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 768, min: 1 },
      items: 1,
      slidesToSlide: 1, // optional, default to 1.
    },
  };
  const [heading, SetHeading] = useState("");
  const [slides, SetSlides] = useState([]);
  const [sliderSpeed, SetSliderSpeed] = useState(0);


  useEffect(() => {
    dispatch(getChooseReviews());
  }, [dispatch]);

  useEffect(() => {
    if (!isEmpty(chooseReview)) {
      const items = chooseReview.items;
      const slides = chooseReview.reviews;

      if (!isEmpty(items)) {
        SetHeading(items[0].name_en);
        SetSliderSpeed(items[1].name_en);

      }

      if (!isEmpty(slides)) {
        SetSlides(slides);
      }
    }
  }, [chooseReview]);

  return (
    <section class="section_space buytravel_outer testimonial_outer">
      <div class="container custom_container">
        <div class="row">
          <div class="col-md-12">
            <div
              class="agent_title buytravel_title aos-item"
              data-aos="fade-up"
            >
              {heading}
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <Carousel
              responsive={responsive}
              additionalTransfrom={0}
              arrows
              autoPlay={true}
              autoPlaySpeed={parseInt(sliderSpeed)}
              centerMode={false}
              draggable
              focusOnSelect={false}
              infinite
              keyBoardControl
              minimumTouchDrag={80}
              renderButtonGroupOutside={false}
              renderDotsOutside={false}
              showDots
              sliderClass=""
              slidesToSlide={1}
              swipeable
              containerClass="carousel-container"
              removeArrowOnDeviceType={["tablet", "mobile"]}
              deviceType={props.deviceType}
              dotListClass="rec-dot"
              itemClass="carousel-item-padding-40-px"
            >
              {slides.map((item, index) => (
                <Item key={index}>
                  <div className="buyer_slideOuter">
                  <div class="buyer_inner Superlative_exp">
                    <div class="buyer_title">
                      {ReactHtmlParser(item.name_en)}
                    </div>
                    <div class="client_title">— {item.review_by} </div>
                  </div>
                  </div>
                </Item>
              ))}
            </Carousel>
          </div>
        </div>
      </div>
    </section>
  );
}

export { ChooseReviewSlider };
