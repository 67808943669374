import React, { Fragment, useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
// import { connect,useSelector, } from 'react-redux';
import { Link } from "react-router-dom";
import {
  getLandingBusinessGroups,
  getLandingBusinessGroups1,
  getLandingBusinessGroups2,
} from "../../../actions";
import Item from "../../../item";
import { appConstants } from "../../../helpers/app-constants";
import isEmpty from "../../../isEmpty";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import "../../../index.css";
import ReactHtmlParser, {
  processNodes,
  convertNodeToElement,
  htmlparser2,
} from "react-html-parser";
import { ServicePopup } from "../../Comman/ServicePopup";

function BusinessGroups(props) {
  const {
    landingbusinessGroups,
    landingbusinessGroups1,
    landingbusinessGroups2,
  } = useSelector((state) => {
    return state.Business;
  });

  const dispatch = useDispatch();

  const [homeSlider2, SetHomeSlider2] = useState([]);
  const [responsive, SetResponsive] = useState({
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4,
      slidesToSlide: 1, // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1024, min: 768 },
      items: 2,
      slidesToSlide: 1, // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 550, min: 1 },
      items: 1,
      slidesToSlide: 1, // optional, default to 1.
    },
  });

  const [head1, SetHead1] = useState([]);
  const [head2, SetHead2] = useState([]);
  const [head3, SetHead3] = useState([]);

  const [subHead1, SetSubHead1] = useState([]);
  const [subHead2, SetSubHead2] = useState([]);
  const [subHead3, SetSubHead3] = useState([]);

  const [imgg1, SetImgg1] = useState([]);
  const [imgg2, SetImgg2] = useState([]);
  const [imgg3, SetImgg3] = useState([]);

  const [btn1, SetBtn1] = useState([]);
  const [btn2, SetBtn2] = useState([]);
  const [btn3, SetBtn3] = useState([]);

  const [url1, SetUrl1] = useState([]);
  const [url2, SetUrl2] = useState([]);
  const [url3, SetUrl3] = useState([]);

  const [responsive2, SetResponsive2] = useState({
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 2,
      slidesToSlide: 1, // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1024, min: 768 },
      items: 2,
      slidesToSlide: 1, // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 550, min: 1 },
      items: 1,
      slidesToSlide: 1, // optional, default to 1.
    },
  });
  const [sliderHeading, SetSliderHeading] = useState("");
  const [slides, SetSlides] = useState([]);
  const [slides1, SetSlides1] = useState([]);
  const [slides2, SetSlides2] = useState([]);

  const [popid1, setPopid1] = useState([]);
  const [popid2, setPopid2] = useState([]);
  const [popid3, setPopid3] = useState([]);

  const [isOpen, SetIsOpen] = useState(false);
  // const openModal = () => SetIsOpen(true);
  // const closeModal = () => SetIsOpen(false);

  const [refresh, doRefresh] = useState(0);


  const popupProcess = (id) => {

    SetCurrentSection(id);
    // alert(id);
    doRefresh(prev => prev + 1);
    // alert(isOpen2);
    openModal();
  }


  const closeModal = () => {
    SetIsOpen(false);
  };

  const openModal = () => {
    SetIsOpen(true);
  };


  const [isOpen2, SetIsOpen2] = useState(false);
  const openModal2 = () => SetIsOpen2(true);
  const closeModal2 = () => SetIsOpen2(false);

  useEffect(() => {
    // dispatch(getLanuageList());
    for (var i = 0; i < props.pfgsections.length; i++) {
      if (props.pfgsections[i].copy_section_id === 14) {
        dispatch(getLandingBusinessGroups(props.pfgsections[i].id));
      }
      if (props.pfgsections[i].copy_section_id === 15) {
        dispatch(getLandingBusinessGroups1(props.pfgsections[i].id));
      }
      if (props.pfgsections[i].copy_section_id === 16) {
        dispatch(getLandingBusinessGroups2(props.pfgsections[i].id));
      }
    }
  }, [dispatch]);

  useEffect(() => {
    if (
      !isEmpty(landingbusinessGroups) &&
      !isEmpty(landingbusinessGroups1) &&
      !isEmpty(landingbusinessGroups2)
    ) {
      const items = landingbusinessGroups.items;
      const slides = landingbusinessGroups.reviews;

      const items1 = landingbusinessGroups1.items;
      const slides1 = landingbusinessGroups1.reviews;

      const items2 = landingbusinessGroups2.items;
      const slides2 = landingbusinessGroups2.reviews;

      if (!isEmpty(items)) {
        SetSliderHeading(items[0].name_en);
        SetHead1(items[0].name);
        SetSubHead1(items[1].name);
        SetImgg1(items[2].image);
        SetBtn1(items[3].name);
        SetUrl1(items[3].url);
        setPopid1(items[0].pop_id);

        SetHead2(items1[0].name);
        SetSubHead2(items1[1].name);
        SetImgg2(items1[2].image);
        SetBtn2(items1[3].name);
        SetUrl2(items1[3].url);
        setPopid2(items1[0].pop_id);

        SetHead3(items2[0].name);
        SetSubHead3(items2[1].name);
        SetImgg3(items2[2].image);
        SetBtn3(items2[3].name);
        SetUrl3(items2[3].url);
        setPopid3(items2[0].pop_id);

      }

      if (!isEmpty(slides) && !isEmpty(slides2) && !isEmpty(slides1)) {
        SetSlides(slides);
        SetSlides2(slides2);
        SetSlides1(slides1);
      }
    }
  }, [landingbusinessGroups, landingbusinessGroups1, landingbusinessGroups2]);

  const [currentSection, SetCurrentSection] = useState(1);

  return (
    <>
      <section class="section_space business_group_outer">
        <div class="container-fluid">
          <div class="row">
            <div class="col-md-5">
              <div class="business_left">
                <img src={imgg1} alt="business1" />
              </div>
            </div>
            <div class="col-md-7">
              <div class="business_right">
                <div
                  class="agent_title buytravel_title brief_title aos-item aos-init aos-animate"
                  data-aos="fade-up"
                >
                  {head1}
                </div>
                {/* <p class="p_small">Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat. Duis autem vel eum iriure dolor in hendrerit in vulputate velit esse molestie consequat, vel illum dolore eu feugiat nulla facilisis at vero eros et accumsan et iusto odio dignissim qui blandit praesent luptatum zzril delenit augue duis dolore te feugait nulla facilisi.</p> */}
                <p class="p_small">{ReactHtmlParser(subHead1)}</p>
                <ul class="service_ul">
                  {slides.map((item, index) => (
                    <li>
                      <img src={item.image} alt="icon1" />
                      <span></span>
                      {ReactHtmlParser(item.name_en)}{" "}
                    </li>
                  ))}
                </ul>
                <div class="view_plan_inner">
                  <a
                    class="go_btn view_plan_btn chat_btn"
                    onClick={() => popupProcess(popid1)}
                  >
                    <i class="fal fa-long-arrow-right"></i>
                    {btn1}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section class="section_space business_group_outer flex_row">
        <div class="container-fluid">
          <div class="row">
            <div class="col-md-5">
              <div class="business_left">
                <img src={imgg2} alt="business2" />
              </div>
            </div>
            <div class="col-md-7">
              <div class="business_right">
                <div
                  class="agent_title buytravel_title brief_title aos-item aos-init aos-animate"
                  data-aos="fade-up"
                >
                  {head2}
                </div>
                {/* <p class="p_small">Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat. Duis autem vel eum iriure dolor in hendrerit in vulputate velit esse molestie consequat, vel illum dolore eu feugiat nulla facilisis at vero eros et accumsan et iusto odio dignissim qui blandit praesent luptatum zzril delenit augue duis dolore te feugait nulla facilisi.</p> */}
                <p class="p_small">{ReactHtmlParser(subHead2)}</p>
                <ul class="service_ul">
                  {slides1.map((item, index) => (
                    <li>
                      <img src={item.image} alt="icon6" />
                      <span></span>
                      {ReactHtmlParser(item.name_en)}
                    </li>
                  ))}
                </ul>

                <div class="view_plan_inner">
                  <a class="go_btn view_plan_btn chat_btn" onClick={() => popupProcess(popid2)}
                  >
                    <i class="fal fa-long-arrow-right"></i>
                    {btn2}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section class="section_space business_group_outer">
        <div class="container-fluid">
          <div class="row">
            <div class="col-md-5">
              <div class="business_left">
                <img src={imgg3} alt="business3" />
              </div>
            </div>
            <div class="col-md-7">
              <div class="business_right">
                <div
                  class="agent_title buytravel_title brief_title aos-item aos-init aos-animate"
                  data-aos="fade-up"
                >
                  {head3}
                </div>
                {/* <p class="p_small">Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat. Duis autem vel eum iriure dolor in hendrerit in vulputate velit esse molestie consequat, vel illum dolore eu feugiat nulla facilisis at vero eros et accumsan et iusto odio dignissim qui blandit praesent luptatum zzril delenit augue duis dolore te feugait nulla facilisi.</p> */}
                <p class="p_small">{ReactHtmlParser(subHead3)}</p>
                <ul class="service_ul">
                  {slides2.map((item, index) => (
                    <li>
                      <img src={item.image} alt="icon6" />
                      <span></span>
                      {ReactHtmlParser(item.name_en)}
                    </li>
                  ))}
                </ul>
                <div class="view_plan_inner">
                  <a class="go_btn view_plan_btn chat_btn" onClick={() => popupProcess(popid3)}>
                    <i class="fal fa-long-arrow-right"></i>
                    {btn3}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <ServicePopup refresh={refresh} key={refresh} section_id={currentSection} isOpen={isOpen} isOpen2={isOpen2} closeModal={closeModal} closeModal2={closeModal2} openModal2={openModal2} />

    </>
  );
}

export { BusinessGroups };
