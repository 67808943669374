import axios from "axios";
import { returnErrors } from "../actions/error.action";
import { appConstants, config } from "../helpers/app-constants";
import { loaderDispatch } from "../helpers/app-constants";

// get header footer list
export const getMenus = () => (dispatch) => {
  loaderDispatch(dispatch, true);
  axios
    .get(appConstants.BACKEND_URL + 'getmenus', config)
    .then((res) => {
      // console.log(res);
      loaderDispatch(dispatch, false);
      dispatch({ type: appConstants.GET_MENU_LISTING, payload: res.data.data });
    })
    .catch((err) => {

      console.log(err);
      loaderDispatch(dispatch, false);
      dispatch(returnErrors(err.response, err.response))
    }
    );
}

export const getSelectedLanguage = () => (dispatch) => {
  ///loaderDispatch(dispatch, true);
  if (localStorage.getItem('selectedLang') !== null) {
    var lang23 = localStorage.getItem('selectedLang');
    dispatch({ type: appConstants.GET_LANGUAGE, payload: JSON.parse(lang23) });
  } else {
    dispatch({ type: appConstants.GET_LANGUAGE, payload: { 'id': 1, 'name': 'English' } });
  }

};


export const setSelectedLanguage = (langs) => (dispatch) => {
  loaderDispatch(dispatch, true);
  // alert(lang2);
  var lang2 = JSON.stringify(langs);
  localStorage.setItem('selectedLang', lang2);
  if (localStorage.getItem('selectedLang') !== null) {
    var lang23 = localStorage.getItem('selectedLang');
    dispatch({ type: appConstants.GET_LANGUAGE, payload: JSON.parse(lang23) });
  } else {
    dispatch({ type: appConstants.GET_LANGUAGE, payload: { 'id': 1, 'name': 'English' } });
  }
  window.location.reload(false);

};


