import React, { Fragment, useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { getLandingContactMetatag } from "../../actions";
import Carousel from "react-elastic-carousel";
import Item from "../../item";
import  Header  from "../Comman/Header";
import isEmpty from "../../isEmpty";
import { Footer } from "../Comman/Footer";
import { BannerSection } from "./Sections/BannerSection";
import { ContactTitle } from "./Sections/ContactTitle";
import { ContactEnquiry } from "./Sections/ContactEnquiry";
import { ContactForm } from "./Sections/ContactForm";
import { ClaimSection } from "./Sections/ClaimSection";
import { ContactAgentSection } from "./Sections/ContactAgentSection";
function ContactLanding(props) {
  const dispatch = useDispatch();

  const { landingcontactmetatag } = useSelector((state) => {
    return state.Contact;
  });
  const [metakey, SetMetakey] = useState("");
  const [metadesc, SetMetadesc] = useState("");
  const [metatitle, SetMetatitle] = useState("");
  const [metalang, SetMetalang] = useState("");
  const [metatags, SetMetatags] = useState("");
  useEffect(() => {
    for (var i = 0; i < props.sections.length; i++) {
      if (props.sections[i].copy_section_id === 213) {
        dispatch(getLandingContactMetatag(props.sections[i].id));
      }
    }
  }, [dispatch]);

  useEffect(() => {
    if (!isEmpty(landingcontactmetatag)) {
      const items = landingcontactmetatag.items;
      if (!isEmpty(items)) {
        SetMetakey(landingcontactmetatag.items[0].name);
        SetMetadesc(landingcontactmetatag.items[2].name);
        SetMetatitle(landingcontactmetatag.items[1].name);
        SetMetalang(landingcontactmetatag.items[3].name);
        SetMetatags(landingcontactmetatag.items[4].name);
      }
    }
  }, [landingcontactmetatag]);

  return (
    <div>
      <Header
        metatitle={metatitle}
        metadesc={metadesc}
        metakey={metakey}
        metalang={metalang}
        metatags={metatags}
      />
      {props.sections.map((sec) => (
        <>
          {(() => {
            switch (sec.copy_section_id) {
              case 28:
                return <BannerSection itemid={sec.id} />;
              case 29:
                return <ContactTitle itemid={sec.id} />;
              case 30:
                return <ContactEnquiry itemid={sec.id} />;
              case 33:
                return <ContactForm itemid={sec.id} />;
              case 31:
                return <ClaimSection itemid={sec.id} />;
              case 32:
                return <ContactAgentSection itemid={sec.id} />;
              default:
                return "";
            }
          })()}
        </>
      ))}

      <Footer />
    </div>
  );
}

export { ContactLanding };
