import React, { Fragment, useEffect, Suspense, useSearchParams } from "react";
import useState from "react-usestateref";
import { useSelector, useDispatch } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import {
  getProductFilter,
  getShopFilterPage,
  getProductById,
  getInsuranceStaticText,
  getFilterMetatag,
  sendQuoteMail,
  getStatictext,
  
} from "../../actions";
import Item from "../../item";
import { appConstants } from "../../helpers/app-constants";
import isEmpty from "../../isEmpty";
import Carousel from "react-elastic-carousel";
import Header from "../Comman/Header";
import { Footer } from "../Comman/Footer";
import { Modal, Button, Form } from "react-bootstrap";
import { useUpdateEffect } from "react-use";
import $ from "jquery";
import ReactHtmlParser, {
  processNodes,
  convertNodeToElement,
  htmlparser2,
} from "react-html-parser";

import { bounce, flipInX, flipInY, flipOutX, flip } from 'react-animations';
import Radium, { StyleRoot } from 'radium';

function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

function ShopinsuranceFilter(props) {
  const dispatch = useDispatch();

  const {
    insstatictext,
    preFilter,
    shopFilterPage,
    filterProducts,
    loader,
    product,
    filtermetatag,
    sendmail
  } = useSelector((state) => {
    return state.Home;
  });


  const { statictext, socialmedia } = useSelector((state) => {
    // console.log('redux',state)
    return state.Home;
  });


  const [productData, setProductData] = useState([]);
  const [AllproductData, setAllProductData] = useState([]);
  const [singleproductData, setSingleProductData] = useState([]);
  const [FilterData, setFilterData] = useState("");
  const [bannerSection, setBannerSection] = useState([]);
  const [emailvalue, SetEmailvalue] = useState("");
  const [emailId, SetEmailId] = useState("");
  const [travelFilterSection, setTravelFilterSection] = useState([]);
  const [insuranceFilterSection, setInsuranceFilterSection] = useState([]);
  const [termFilterSection, setTermFilterSection] = useState([]);
  const [questionSection, setQuestionSection] = useState([]);
  const [category, setCategory] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [comparePlan, setComparePlan] = useState("none");
  const [compareCount, setCompareCount] = useState(0);
  const [planId, setPlanId, cplan] = useState([]);
  const [productDucments, setProductDucments] = useState([]);
  const [errors, SetErrors] = useState({});
  const [productFeatures, setProductFeatures] = useState([]);
  const [productCategoryFeatures, setProductCategoryFeatures] = useState([]);
  const [paginate, setPaginate] = useState('1')
  const [planplace, SetPlanplace] = useState("");
  const [cmprplace, SetCmprplace] = useState("");
  const [slctdplanplace, SetSlctdplanplace] = useState("");
  const [cmpareplanplace, SetCmpareplanplace] = useState("");
  const [nosearchplace, SetNosearchplace] = useState("");
  const [plansumryplace, SetPlansumryplace] = useState("");
  const [benefitplace, SetBenefitplace] = useState("");
  const [detailsplace, SetDetailsplace] = useState("");
  const [emailLabel, SetEmailLabel] = useState("");
  const [emailValidation, SetEmailValidation] = useState("");
  const [submitText, SetSubmitText] = useState("");
  const [cmessage, SetCMessage] = useState("");

  const [slctnote, SetSlctnote] = useState("");
  const [metakey, SetMetakey] = useState("");
  const [metadesc, SetMetadesc] = useState("");
  const [metatitle, SetMetatitle] = useState("");
  const [metalang, SetMetalang] = useState("");
  const [metatags, SetMetatags] = useState("");

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);

  let query = useQuery();
  var catId = query.get('category_id');


  useEffect(() => {
    var pl = localStorage.getItem('plans');
    if (pl) {
      var plans = JSON.parse(pl);


      // console.log(plans);
      setPlanId(plans);
      setCompareCount(plans.length)
    }
  }, []);


  useEffect(() => {
    dispatch(getFilterMetatag());
    dispatch(getStatictext(208));
  }, [dispatch]);

  useEffect(() => {
    if (!isEmpty(filtermetatag)) {
      const items = filtermetatag.items;
      if (!isEmpty(items)) {
        SetMetakey(filtermetatag.items[0].name);
        SetMetadesc(filtermetatag.items[1].name);
        SetMetatitle(filtermetatag.items[2].name);
        SetMetalang(filtermetatag.items[3].name);
        SetMetatags(filtermetatag.items[4].name);
      }
    }
    if (sendmail.success) {
      var m = cmessage;
      var p = m.replace(/(<([^>]+)>)/ig, '');
      alert(p);
      handleClose();
    }
  }, [filtermetatag, sendmail,statictext]);

  const openModal = () => setIsOpen(true);
  const closeModal = () => setIsOpen(false);
  const [formsData, SetFormsData] = useState({
    email: "",
    itemid: "",
  });

  const [formData, setFormData, ref] = useState({
    travel: [],
    insurance: [],
    term: [],
  });


  const [checkedTravel, setCheckedTravel] = useState([]);
  const [checkedInsurnce, setCheckedInsurnce] = useState([]);
  const [checkedTerm, setCheckedTerm] = useState([]);

  useEffect(() => {



    dispatch(getShopFilterPage());
    dispatch(getInsuranceStaticText());
    dispatch(getStatictext(208));

    var ft = [];
    if (catId !== null && catId !== '') {
      ft = { category_id: catId };
    } else {
      ft = preFilter
    }

    dispatch(getProductFilter(ft));
  }, [dispatch]);

  useEffect(() => {
    if (!isEmpty(shopFilterPage)) {
      if (!isEmpty(shopFilterPage)) {
        setBannerSection(shopFilterPage[0].items);
        setTravelFilterSection(shopFilterPage[1].items);
        setCategory(shopFilterPage[1].categories);
        setInsuranceFilterSection(shopFilterPage[2].items);
        setTermFilterSection(shopFilterPage[3].items);
        setQuestionSection(shopFilterPage[4].items);
      }
    }
  }, [shopFilterPage,statictext]);

  useEffect(() => {
    if (!isEmpty(filterProducts)) {
      const items = filterProducts.product_data;
      const all = filterProducts.all_products;
      const fillt = filterProducts.filterData;
      if (!isEmpty(items)) {
        setProductData(items);
      } else {
        setProductData([]);
      }

      if (!isEmpty(all)) {
        setAllProductData(all);
      } else {
        setAllProductData([]);
      }


      if (!isEmpty(fillt)) {
        setFilterData(fillt.travel);
      }
    }
    if (!isEmpty(insstatictext)) {
      SetPlanplace(insstatictext.items[0].name);
      SetCmprplace(insstatictext.items[1].name);
      SetSlctdplanplace(insstatictext.items[2].name);
      SetCmpareplanplace(insstatictext.items[3].name);
      SetNosearchplace(insstatictext.items[4].name);
      SetPlansumryplace(insstatictext.items[5].name);
      SetBenefitplace(insstatictext.items[6].name);
      SetDetailsplace(insstatictext.items[7].name);
      SetSlctnote(insstatictext.items[8].name);
      SetEmailLabel(insstatictext.items[14].name);
      SetEmailValidation(insstatictext.items[15].name);
      SetSubmitText(insstatictext.items[16].name);
      SetCMessage(insstatictext.items[17].name);
    }
  }, [filterProducts, insstatictext,statictext]);

  useEffect(() => {
    if (!isEmpty(product)) {
      if (!isEmpty(product)) {
        setSingleProductData(product);
        setProductDucments(product.documents);
        setProductFeatures(product.features);
        setProductCategoryFeatures(product.category_feature);
      }
    }
  }, [product]);
  function handlesValidation(e) {
    let errors = {};
    let formIsValid = true;

    if (!emailvalue) {
      formIsValid = false;
      errors["emails"] = emailValidation;
    } else {
      let regEmail =
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (!regEmail.test(emailvalue)) {
        formIsValid = false;
        errors["emails"] = "Invalid Email Address";
      }
    }

    SetErrors(errors);
    return formIsValid;
  }

  const sendMail = (event) => {
    if (handlesValidation()) {
      dispatch(sendQuoteMail(formsData));
      handleClose();
    }
  };

  const styles = {
    bounce: {
      animation: '1s',
      animationName: Radium.keyframes(flipInY, 'flip')
    }
  }


  const handleToggleTravel = (c) => () => {
    // return the first index or -1
    const clickedCategory = checkedTravel.indexOf(c);
    const all = [...checkedTravel];

    if (clickedCategory === -1) {
      all.push(c);
    } else {
      all.splice(clickedCategory, 1);
    }
    setCheckedTravel(all);

    var dd = c - 1;

    setFilterData({ travel: all });
    setFormData({ ...formData, travel: all });

    dispatch(getProductFilter(ref.current));
  };

  const handleemailChange = (event) => {
    SetEmailvalue(event.target.value);
    SetFormsData({
      ...formsData,
      [event.target.name]: event.target.value,
    });
  };

  const handleToggleInsurance = (c) => (e) => {
    // return the first index or -1

    var check = e.target.checked;
    const clickedCategory = checkedInsurnce.indexOf(c);
    const all = [...checkedInsurnce];

    if (check == true) {
      all.push(c);
    } else {
      all.splice(clickedCategory, 1);
    }
    setCheckedInsurnce(all);
    setFormData({ ...formData, insurance: all });
    dispatch(getProductFilter(ref.current));
  };

  const handleToggleTerm = (c) => (e) => {
    // return the first index or -1

    var check = e.target.checked;
    var clickedCategory = checkedTerm.indexOf(c);

    const all = [...checkedTerm];

    // console.log(clickedCategory);

    if (check == true) {
      all.push(c);
    } else {
      // alert(check);
      all.splice(clickedCategory, 1);
    }
    // console.log(all);
    setCheckedTerm(all);
    setFormData({ ...formData, term: all });

    dispatch(getProductFilter(ref.current));
  };

  const showQuote = (d) => (e) => {
    SetEmailvalue('')
    SetEmailId(d);
    SetFormsData({
      ...formsData,
      itemid: d,
    });
    setShow(true);
  };

  useUpdateEffect(() => {
    if (compareCount > 0 && compareCount < 4) {
      setComparePlan("block");
    } else {
      setComparePlan("none");
    }
  });

  const removeItem = (r) => (e) => {
    setCompareCount(compareCount - 1);

    var plans = [...planId];
    plans.splice(planId.indexOf(parseInt(r)), 1);
    setPlanId(plans);

    $("#checked" + r).prop("checked", false);
    if (compareCount === 2) {
      $("#closePlanPop").click();
      $(".compare_plan_btn").addClass("d-none");
    }
  };

  const handleComparePlan = (p) => (e) => {
    const checked = e.target.checked;
    const val = e.target.getAttribute("eid");
    if (checked) {
      if (compareCount > 2) {
        alert("You can compare a maximum of Three products");
        $("#" + e.target.id).prop("checked", false);
      } else {
        setCompareCount(compareCount + 1);
        setPlanId([...planId, parseInt(e.target.getAttribute("eid"))]);
        if (compareCount > 0) {
          $(".compare_plan_btn").removeClass("d-none");
        } else {
          $(".compare_plan_btn").addClass("d-none");
        }
      }
    } else {
      setCompareCount(compareCount - 1);
      var plans = [...planId];
      var tt = compareCount - 1;
      if (tt == 1) {
        $(".compare_plan_btn").addClass("d-none");
      }
      plans.splice(planId.indexOf(parseInt(e.target.getAttribute("eid"))), 1);
      setPlanId(plans);
    }

    localStorage.setItem('plans', JSON.stringify(cplan.current));

  };

  const showPlans = (s) => () => { };

  const handleProduct = (d) => () => {
    let product_id = d;
    dispatch(getProductById(product_id));
    // $("#closePlanPop").click();

    setTimeout(() => openModal(), 1000);
  };

  const closeCompare = () => {
    closeModal();
    setCompareCount("");
    setPlanId([]);
  }
  return (
      
    <div>
      <Header
        metatitle={metatitle}
        metadesc={metadesc}
        metakey={metakey}
        metalang={metalang}
        metatags={metatags}
      />

      <section
        class="section_space banner_outer why_choose travel_banner"
        style={{
          backgroundImage: `url(${!isEmpty(bannerSection) && bannerSection[1].image
            }`,
        }}
      >
        <div class="overlay"></div>
        <div class="container">
          <div class="row align-items-center">
            <div class="col-md-12">
              <div class="banner_inner">
                <div
                  class="travel_title why_choose_title aos-item"
                  data-aos="fade-up"
                >
                  {!isEmpty(bannerSection) && bannerSection[0].name_en}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section class="section_space faq_outer">
        <div class="container-fluid">
          <div class="row">
            <div class="col-md-3">
              <div class="faq_left filter_left">
                <div
                  class="panel-group mobile_category tab_filter"
                  id="accordion_category"
                  role="tablist"
                  aria-multiselectable="true"
                >
                  <div class="panel panel-default">
                    <div class="panel-heading" role="tab" id="heading2">
                      <h4 class="panel-title">
                        <a
                          class="accordion-toggle"
                          role="button"
                          data-bs-toggle="collapse"
                          data-bs-parent="#accordion_category"
                          href="#collapse2"
                          aria-expanded="true"
                          aria-controls="collapse2"
                        >
                          <div
                            class="agent_title buytravel_title question_title aos-item aos-init aos-animate"
                            data-aos="fade-up"
                          >
                            FILTERS
                          </div>
                        </a>
                      </h4>
                    </div>
                    <div
                      id="collapse2"
                      class="panel-collapse collapse in"
                      role="tabpanel"
                      aria-labelledby="heading2"
                    >
                      <div class="panel-body">
                        <div class="filter_travel_title">
                          {!isEmpty(travelFilterSection) &&
                            travelFilterSection[0].name_en}
                        </div>
                        <div class="request_check">
                          {!isEmpty(category) &&
                            category.map((item, index) => (
                              <Fragment key={index}>
                                <label class="signup_check">
                                  {item.name_en}
                                  <input
                                    type="checkbox"
                                    onChange={handleToggleTravel(index + 1)}
                                  />
                                  <span class="checkmark"></span>
                                </label>
                              </Fragment>
                            ))}
                        </div>

                        <div class="filter_travel_title DURATION">
                          {!isEmpty(insuranceFilterSection) &&
                            insuranceFilterSection[0].name_en}
                        </div>
                        <div class="request_check">
                          {!isEmpty(insuranceFilterSection) &&
                            insuranceFilterSection.map((item, index) => (
                              <Fragment>
                                {item.type_id == "2" && (
                                  <label
                                    class="signup_check"
                                    onChange={handleToggleInsurance(item.id)}
                                  >
                                    {item.name_en}
                                    <input type="checkbox" />
                                    <span class="checkmark"></span>
                                  </label>
                                )}
                              </Fragment>
                            ))}
                        </div>

                        <div class="filter_travel_title DURATION">
                          {!isEmpty(termFilterSection) &&
                            termFilterSection[0].name_en}
                        </div>
                        <div class="request_check">
                          {!isEmpty(termFilterSection) &&
                            termFilterSection.map((item, index) => (
                              <Fragment>
                                {item.type_id == "14" && (
                                  <label class="signup_check">
                                    {item.name_en}
                                    <input
                                      type="checkbox"
                                      onChange={handleToggleTerm(item.id)}
                                    />
                                    <span class="checkmark"></span>
                                  </label>
                                )}
                              </Fragment>
                            ))}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="filter_desktop">
                  <div class="filter_travel_title">
                    {!isEmpty(travelFilterSection) &&
                      travelFilterSection[0].name_en}
                  </div>
                  <div class="request_check">
                    {!isEmpty(category) &&
                      category.map((item, index) => (
                        <Fragment key={index}>
                          <label class="signup_check">
                            {item.name_en}
                            <input
                              type="checkbox"
                              onChange={handleToggleTravel(index + 1)}
                            />
                            <span class="checkmark"></span>
                          </label>
                        </Fragment>
                      ))}
                  </div>

                  <div class="filter_travel_title DURATION">
                    {!isEmpty(insuranceFilterSection) &&
                      insuranceFilterSection[0].name_en}
                  </div>
                  <div class="request_check">
                    {!isEmpty(insuranceFilterSection) &&
                      insuranceFilterSection.map((item, index) => (
                        <Fragment>
                          {item.type_id == "2" && (
                            <label class="signup_check">
                              {item.name_en}
                              <input
                                type="checkbox"
                                onChange={handleToggleInsurance(item.id)}
                              />
                              <span class="checkmark"></span>
                            </label>
                          )}
                        </Fragment>
                      ))}
                  </div>

                  <div class="filter_travel_title DURATION">
                    {!isEmpty(termFilterSection) &&
                      termFilterSection[0].name_en}
                  </div>
                  <div class="request_check">
                    {!isEmpty(termFilterSection) &&
                      termFilterSection.map((item, index) => (
                        <Fragment>
                          {item.type_id == "14" && (
                            <label class="signup_check">
                              {item.name_en}
                              <input
                                type="checkbox"
                                onChange={handleToggleTerm(item.id)}
                                name="term"
                              />
                              <span class="checkmark"></span>
                            </label>
                          )}
                        </Fragment>
                      ))}
                  </div>
                </div>
              </div>
              <div class="faq_left talk_experts">
                <div
                  class="panel-group mobile_questions"
                  id="accordion_question"
                  role="tablist"
                  aria-multiselectable="true"
                >
                  <div class="panel panel-default">
                    <div class="panel-heading" role="tab" id="heading1">
                      <h4 class="panel-title">
                        <a
                          class="accordion-toggle"
                          role="button"
                          data-bs-toggle="collapse"
                          data-bs-parent="#accordion_question"
                          href="#collapse1"
                          aria-expanded="true"
                          aria-controls="collapse1"
                        >
                          <div class="agent_title buytravel_title question_title">
                            {" "}
                            {!isEmpty(questionSection) &&
                              questionSection[0].name_en}
                          </div>
                        </a>
                      </h4>
                    </div>
                    <div
                      id="collapse1"
                      class="panel-collapse collapse in"
                      role="tabpanel"
                      aria-labelledby="headingOne"
                    >
                      <div class="panel-body">
                        <div
                          class="insurance_title aos-item aos-init aos-animate"
                          data-aos="fade-up"
                        >
                          {!isEmpty(questionSection) &&
                            questionSection[1].name_en}
                        </div>
                        <div
                          class="call_title aos-item aos-init"
                          data-aos="fade-up"
                        >
                          {!isEmpty(questionSection) &&
                            ReactHtmlParser(questionSection[2].name_en)}
                        </div>
                        <div
                          class="view_plan_inner aos-item aos-init"
                          data-aos="fade-up"
                        >
                          <a class="go_btn view_plan_btn chat_btn">
                            <i class="fal fa-long-arrow-right"></i>
                            {!isEmpty(questionSection) &&
                              questionSection[3].name_en}
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="desktop_questions">
                  <div
                    class="agent_title buytravel_title question_title aos-item aos-init aos-animate"
                    data-aos="fade-up"
                  >
                    {!isEmpty(questionSection) && questionSection[0].name_en}
                  </div>
                  <div
                    class="insurance_title aos-item aos-init aos-animate"
                    data-aos="fade-up"
                  >
                    {!isEmpty(questionSection) && questionSection[1].name_en}
                  </div>
                  <div class="call_title aos-item aos-init" data-aos="fade-up">
                    {!isEmpty(questionSection) && ReactHtmlParser(questionSection[2].name_en)}
                  </div>
                  <div
                    class="view_plan_inner aos-item aos-init"
                    data-aos="fade-up"
                  >
                    <a href={!isEmpty(questionSection) && questionSection[3].url} class="go_btn view_plan_btn chat_btn">
                      <i class="fal fa-long-arrow-right"></i>
                      {!isEmpty(questionSection) && questionSection[3].name_en}
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-9">
              <div class="faq_right">
                <a class="go_btn view_plan_btn mb-5" href="/?filter=1">{ statictext.items?.length>0?statictext.items[12].name:''}</a>
                {isEmpty(productData) &&
                  loader.ftype == "filterProduct" &&
                  !loader.state && (
                    <div
                      class="filter_travel_title"
                      style={{ color: "#e84c0f" }}
                    >
                      <br />
                      {ReactHtmlParser(nosearchplace)}
                    </div>
                  )}
                {/* {loader.ftype}
 {loader.state} */}

                {loader.ftype == "filterProduct" && loader.state ? (
                  <img
                    className="pageLoaderFront mx-auto d-block"
                    src="assets/images/loader_front.gif"
                    alt="Loading..."
                    width="100px"
                    height="100px"
                  />
                ) : (
                  productData &&
                  productData.slice(0, paginate * 6).map((item, index) => (
                    <div class="msh_international" key={index}>
                      <div class="msh_international_left">
                        <div class="msh_inernational_left_inner">
                          <img src={item.company_logo} alt="logo_brand" />
                          <div className="title_MSH_right">
                            <div class="filter_travel_title">
                              {item.name_en}
                              <span>{item.type_name}</span>
                            </div>
                            <p class="p_small text-uppercase">
                              {item.category_name}, {item.insurance_name},{" "}
                              {item.term_name}
                            </p>
                          </div>
                        </div>
                        <div class="msh_inernational_right_inner">
                          <div class="filter_travel_title">
                            {item.name_en}
                            <span>{item.type_name}</span>
                          </div>
                          <p class="p_small text-uppercase">
                            {item.category_name}, {item.insurance_name},{" "}
                            {item.term_name}
                          </p>
                          <ul class="coverage_ul">
                            {!isEmpty(item.features) &&
                              item.features.slice(0, 3).map((item2, index) => (
                                <li key={index}>
                                  <span>•</span> {item2.feature_name}:{" "}
                                  {item2.value}
                                </li>
                              ))}
                          </ul>
                        </div>
                      </div>
                      <div class="msh_international_right">
                        {item.purchase_type != 1 ? (
                          <a
                            onClick={showQuote(item.id)}
                            className="buynow go_btn view_plan_btn"
                          >
                            <i class="fal fa-long-arrow-right"></i>
                            {item.btn_name}
                          </a>
                        ) : (
                          <a
                            class="buynow go_btn view_plan_btn"
                            href={item.url}
                            target="_blank"
                          >
                            <i class="fal fa-long-arrow-right"></i>
                            {item.btn_name}
                          </a>
                        )}

                        <a
                          class="policy_wording"
                          onClick={handleProduct(item.id)}
                        >
                          <i class="fal fa-long-arrow-right"></i>
                          {planplace}
                        </a>
                        <div class="compare_div">
                          <div class="request_check">
                            <label class="signup_check">
                              {cmprplace}
                              <input
                                type="checkbox"
                                eid={item.id}
                                id={"checked" + item.id}
                                onChange={handleComparePlan(item.name_en)}
                                checked={!isEmpty(planId) ? planId.includes(item.id) : false}
                              />
                              <span class="checkmark"></span>
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))
                )}
                {
                  productData.length > paginate * 6 &&
                  <a class="go_btn" onClick={() => { setPaginate(paginate + 1) }}> Show More</a>
                }

              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />

      <section class="compare_outer" style={{ display: comparePlan }}>
        <div class="container custom_container">
          <div class="row">
            <div class="col-md-12">
              <div class="compare_plan_div">
                <div class="compare_plan_left">
                  {compareCount == 1 ? (
                    <>
                      <p>
                        {" "}
                        {compareCount} {slctdplanplace}
                      </p>
                      <span className="text-white">{slctnote}</span>
                    </>
                  ) : (
                    <p> {compareCount} {slctdplanplace}</p>
                  )}
                </div>
                <div class="compare_plan_btn">
                  <a
                    class="policy_wording compare_plan"
                    href=""
                    data-bs-dismiss="modal"
                    onClick={showPlans()}
                    data-bs-toggle="modal"
                    data-bs-target="#compareModal"
                  >
                    <i class="fal fa-long-arrow-right"></i> {cmpareplanplace}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <StyleRoot >
        <div
          class="modal custom_modal custom_compare fade"
          id="compareModal"
          tabindex="-1"
          aria-labelledby="compareModalLabel"
          aria-hidden="true"
          style={styles.bounce}
        >
          <div class="modal-dialog">
            <div class="modal-content">
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <i class="fal fa-times" id="closePlanPop"></i>
              </button>
              <div class="compare_plan_upper">
                <p>{cmpareplanplace}</p>
                <p>
                  {compareCount} {slctdplanplace}
                </p>
              </div>
              <div class="modal-body select_plan_body select_plan_body1 plan_summary plan_table_custom">
                <div class="row">
                  <div class="col-md-12">
                    <div className="plan_buy_scroll_outer">
                      <div className="plan_buy_detail_outer">
                        {AllproductData.map((item, index) => (
                          <>
                            {planId &&
                              planId.length > 0 &&
                              planId.includes(item.id) && (
                                <div className="plan_buy_first ">
                                  <div className="plan_buy_upper">
                                    <div class="agent_title buytravel_title">
                                      {item.name_en}
                                    </div>
                                    <p class="p_text">
                                      {item.category_name}, {item.insurance_name},{" "}
                                      {item.term_name}{" "}
                                    </p>
                                    <div class="plan_tag_outer">
                                      <a
                                        class="go_btn view_plan_btn"
                                        href={
                                          item.purchase_type == 1 ? item.url : "#"
                                        }
                                        target="_blank"
                                      >
                                        <i class="fal fa-long-arrow-right"></i>
                                        {item.btn_name}
                                      </a>
                                      <a
                                        class="policy_wording"
                                        onClick={handleProduct(item.id)}
                                      >
                                        <i class="fal fa-long-arrow-down"></i>See
                                        Plan Details
                                      </a>
                                    </div>
                                    <a
                                      class="cross_icon"
                                      onClick={removeItem(item.id)}
                                      href="#"
                                    >
                                      <i class="fal fa-times"></i>
                                    </a>
                                  </div>
                                  <div className="plan_buy_lower">
                                    {!isEmpty(item.features) &&
                                      item.features.map((item2, index) => (
                                        <div className="plan_buy_div" key={index}>
                                          <p>
                                            <b>{item2.feature_name}</b>
                                          </p>
                                          <p>{item2.value}</p>
                                        </div>
                                      ))}
                                  </div>
                                </div>
                              )}
                          </>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </StyleRoot>


      <StyleRoot >

        <Modal
          show={isOpen}
          onHide={closeModal}
          className="custom_modal custom_selectplan fade"
        >
          {/* <div className="modal custom_modal custom_selectplan fade" id="selectplanModal" tabindex="-1" aria-labelledby="selectplanModalLabel" aria-hidden="true"> */}
          <div style={styles.bounce}>
            <button
              type="button"
              className="btn-close"
              onClick={closeModal}
            ></button>

            <div className="modal-body select_plan_body select_plan_body1">
              <div className="agent_title buytravel_title">
                {singleproductData.name_en}
              </div>
              <p className="p_text">
                {singleproductData.category_name},{" "}
                {singleproductData.insurance_name}, {singleproductData.term_name}
              </p>
              <div className="plan_tag_outer">
                {productDucments.map((item, index) => (
                  <a
                    className="policy_wording"
                    href={item.link_url}
                    target="_blank"
                    key={index}
                  >
                    <i className="fal fa-long-arrow-down"></i>
                    {item.name}
                  </a>
                ))}
              </div>
            </div>

            <div className="modal-body select_plan_body select_plan_body1 select_plan_body2">
              <div className="row">
                {productCategoryFeatures &&
                  productCategoryFeatures.map((item, index) => (
                    <div className="col-md-6">
                      <div className="hospital_div">
                        <img src={item.image} alt="image" />
                        <p>{item.name}</p>
                      </div>
                    </div>
                  ))}
              </div>
            </div>

            <div className="modal-body select_plan_body select_plan_body1 plan_summary">
              <div className="row">
                <div className="col-md-12">
                  <div className="plan_summary_title">{plansumryplace}</div>
                  <table className="table table_custom">
                    <thead>
                      <tr>
                        <th style={{ width: "40%" }} scope="col">
                          {benefitplace}
                        </th>
                        <th style={{ width: "60%" }} scope="col">
                          {detailsplace}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {productFeatures.map((item, index) => (
                        <tr key={index}>
                          <td style={{ width: "40%" }}>
                            <b>{item.feature_name}</b>
                          </td>
                          <td style={{ width: "60%" }}>{item.value}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>

            <div className="modal-body select_plan_body select_plan_body1 select_plan_body2">
              <div className="row">
                <div className="col-md-12">
                  <div className="view_plan_inner">
                    {singleproductData.purchase_type != 1 ? (
                      <>
                        <a
                          onClick={showQuote(singleproductData.id)}
                          className="go_btn view_plan_btn"
                        >
                          <i className="fal fa-long-arrow-right"></i>
                          {singleproductData.btn_name}
                        </a>
                      </>
                    ) : (
                      <a
                        className="go_btn view_plan_btn"
                        href={singleproductData.url}
                        target="_blank"
                      >
                        <i className="fal fa-long-arrow-right"></i>
                        {singleproductData.btn_name}
                      </a>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* </div> */}
        </Modal>
      </StyleRoot>

      <StyleRoot>
        <Modal
          show={show}
          className="fade custom_modal modal "
          onHide={handleClose}
        >
          <div style={styles.bounce}>
            <div className="modal-body select_plan_body select_plan_body1 select_plan_body2">
              <div className="row">
                <div className="col-md-12">

                  {/* <Modal.Title>{emailLabel}:</Modal.Title> */}
                  <button
                    type="button"
                    className="btn-close"
                    onClick={handleClose}
                  ></button>
                  <div className="modal-body select_plan_body select_plan_body1 select_plan_body2">

                    <input
                      type="email"
                      value={emailvalue}
                      name="email"
                      onChange={handleemailChange}
                      placeholder={emailLabel}
                      class="select_custom last_popup_input"
                    />
                    <span class="error_contact" style={{ color: "red" }}>
                      {errors["emails"]}
                    </span>
                    <div className="row">
                      <div className="col-md-12">
                        <div className="view_plan_inner">
                          <input
                            variant="primary"
                            class="btn go_btn view_plan_btn "
                            type="submit"
                            onClick={sendMail}
                            value={submitText}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal>
      </StyleRoot>
    </div>
  );
}

export { ShopinsuranceFilter };
