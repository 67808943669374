import React, { Fragment, useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
// import { connect,useSelector, } from 'react-redux';
import { getBusinessBanner } from "../../actions";
import isEmpty from "../../isEmpty";
import "react-multi-carousel/lib/styles.css";
import "../../index.css";

function BusinessBanner(props) {
  const { businessBanners } = useSelector((state) => {
    return state.Business;
  });

  const dispatch = useDispatch();

  const [heading, SetHeading] = useState("");
  const [backImage, SetBackImage] = useState("");

  useEffect(() => {
    dispatch(getBusinessBanner());
  }, [dispatch]);

  useEffect(() => {
    if (!isEmpty(businessBanners)) {
      const items = businessBanners.items;
      if (!isEmpty(items)) {
        SetHeading(items[0].name_en);
        SetBackImage(items[1].image);
      }
    }
  }, [businessBanners]);

  const bannerstyle = { backgroundImage: "url(" + backImage + ")" };
  return (
    <section
      class="section_space banner_outer why_choose shop_banner"
      style={bannerstyle}
    >
      <div class="overlay"></div>
      <div class="container">
        <div class="row align-items-center">
          <div class="col-md-12">
            <div class="banner_inner">
              <div
                class="travel_title why_choose_title aos-item"
                data-aos="fade-up"
              >
                {heading}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export { BusinessBanner };
