import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getHomePartners } from "../../actions";
import isEmpty from "../../isEmpty";
import "react-multi-carousel/lib/styles.css";
import "../../index.css";

function PartnerSection(props) {
  const { partners } = useSelector((state) => {
    return state.Home;
  });

  const dispatch = useDispatch();

  const [heading, SetHeading] = useState("");
  const [loadmore, SetLoadmore] = useState("");
  const [loadless, SetLoadless] = useState("");

  const [slides, SetSlides] = useState([]);
  const [visible, SetVisible] = useState(7);

  const loadMore = () => {
    var newste = visible + 7;
    SetVisible(newste);
  };

  const loadLess = () => {
    SetVisible(7);
  };

  useEffect(() => {
    dispatch(getHomePartners());
  }, [dispatch]);

  useEffect(() => {
    if (!isEmpty(partners)) {
      const items = partners.items;
      const slides = partners.logos;
      if (!isEmpty(items)) {
        SetHeading(items[0].name);
        SetLoadmore(items[1].name);
        SetLoadless(items[2].name);
      }

      if (!isEmpty(slides)) {
        SetSlides(slides);
      }
    }
  }, [partners]);

  return (
    <section className="section_space buytravel_outer">
      <div className="container custom_container">
        <div className="row">
          <div className="col-md-12">
            <div
              className="agent_title buytravel_title aos-item"
              data-aos="fade-up"
            >
              {heading}
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12 mobile_margin">
            <ul className="brand_ul">
              {slides.slice(0, visible).map((item, index) => (
                <li className="aos-item " key={index} data-aos="fade-right">
                  <img src={item.image} alt="brand" />
                </li>
              ))}
            </ul>
          </div>
        
        </div>
      </div>
    </section>

  );
}

export { PartnerSection };
