import React, { Fragment, useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
// import { connect,useSelector, } from 'react-redux';
import { Link } from "react-router-dom";
import { getLandingChooseMap } from "../../../actions";
import Item from "../../../item";
import { appConstants } from "../../../helpers/app-constants";
import isEmpty from "../../../isEmpty";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import "../../../index.css";

function ChooseMap(props) {
  const { landingchooseMap } = useSelector((state) => {
    return state.Choose;
  });

  const dispatch = useDispatch();

  const [section, SetSection] = useState([]);
  const [heading, SetHeading] = useState("");
  const [backImage, SetBackImage] = useState("");

  useEffect(() => {
    // dispatch(getLanuageList());
    dispatch(getLandingChooseMap(props.itemid));
  }, [dispatch]);

  useEffect(() => {
    if (!isEmpty(landingchooseMap)) {
      const items = landingchooseMap.items;
      if (!isEmpty(items)) {
        SetHeading(items[0].name_en);
        SetBackImage(items[1].image);
      }
    }
  }, [landingchooseMap]);

  return (
    <section class="section_space">
      <div class="container custom_container">
        <div class="row">
          <div class="col-md-12">
            <div
              class="agent_title buytravel_title aos-item"
              data-aos="fade-up"
            >
              {heading}
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <div class="map_outer">
              <img class="map_img" src={backImage} alt="map" />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export { ChooseMap };
