import React, { Fragment, useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getLandingContactTitle } from "../../../actions";
import Item from "../../../item";
import { appConstants } from "../../../helpers/app-constants";
import isEmpty from "../../../isEmpty";
import "react-multi-carousel/lib/styles.css";
import "../../../index.css";
import ReactHtmlParser, {
  processNodes,
  convertNodeToElement,
  htmlparser2,
} from "react-html-parser";

function ContactTitle(props) {
  const { landingcontactTitle } = useSelector((state) => {
    return state.Contact;
  });

  const dispatch = useDispatch();

  const [heading, SetHeading] = useState("");
  const [subHeading, SetSubHeading] = useState("");

  useEffect(() => {
    // dispatch(getLanuageList());
    dispatch(getLandingContactTitle(props.itemid));
  }, [dispatch]);

  useEffect(() => {
    if (!isEmpty(landingcontactTitle)) {
      const items = landingcontactTitle.items;
      if (!isEmpty(items)) {
        SetHeading(items[0].name_en);
        SetSubHeading(items[1].name_en);
      }
    }
  }, [landingcontactTitle]);

  return (
    <section class="section_space contact_title_outer">
      <div class="container custom_container">
        <div class="row">
          <div class="col-md-8 offset-md-2 col-sm-12">
            <div
              class="agent_title buytravel_title contact_title aos-item"
              data-aos="fade-up"
            >
              {heading}
            </div>
            <p class="p_text contact_subtitle">{ReactHtmlParser(subHeading)}</p>
          </div>
        </div>
      </div>
    </section>
  );
}

export { ContactTitle };
