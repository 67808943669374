import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getContactEnquiry } from "../../actions";
import isEmpty from "../../isEmpty";
import "react-multi-carousel/lib/styles.css";
import "../../index.css";

function ContactEnquiry(props) {
  const { contactEnquiry } = useSelector((state) => {
    return state.Contact;
  });

  const dispatch = useDispatch();

  const [heading, SetHeading] = useState("");
  const [TollFree, SetTollFree] = useState("");
  const [TollFreeNo, SetTollFreeNo] = useState("");
  const [Worldwide, SetWorldwide] = useState("");
  const [WorldwideNo, SetWorldwideNo] = useState("");
  const [emailLabel, SetEmailLabel] = useState("");
  const [email, SetEmail] = useState("");

  useEffect(() => {
    dispatch(getContactEnquiry());
  }, [dispatch]);

  useEffect(() => {
    if (!isEmpty(contactEnquiry)) {
      const items = contactEnquiry.items;
      if (!isEmpty(items)) {
        SetHeading(items[0].name_en);
        SetTollFree(items[1].name_en);
        SetTollFreeNo(items[2]);
        SetWorldwide(items[3].name_en);
        SetWorldwideNo(items[4]);
        SetEmailLabel(items[6].name_en);
        SetEmail(items[5]);
      }
    }
  }, [contactEnquiry]);

  return (
    <>
      <section class="section_space buytravel_outer testimonial_outer general_inquires">
        <div class="container custom_container">
          <div class="row">
            <div class="col-md-12">
              <div class="general_inquiry_title">{heading}</div>
            </div>
          </div>
        </div>
      </section>

      <section class="section_space contact_title_outer contact_info_outer">
        <div class="container custom_container">

        <div class="row">
            <div class="col-md-12">
              <div class="contact_info_inner">
                <div class="contact_info_first1">
                  <div class="phone_img">
                    <img src={TollFreeNo.image} alt="" width="53px" height="53px" />
                  </div>
                  <div class="contact-info-right">
                    <label class="custom_label">{TollFree}</label>
                    <a href={`tel:+${TollFreeNo.name}`}><div class="phone_number">{TollFreeNo.name}</div></a>
                  </div>
                </div>
                <div class="contact_info_first1">
                  <div class="phone_img">
                    <img src={WorldwideNo.image} alt="" width="53px" height="53px" />
                  </div>
                  <div class="contact-info-right">
                    <label class="custom_label">{Worldwide}</label>
                    <div class="phone_number">{WorldwideNo.name}</div>
                  </div>
                </div>
                <div class="contact_info_first1">
                  <div class="phone_img">
                    <img src={email.image} alt="" width="53px" height="53px" />
                  </div>
                  <div class="contact-info-right">
                    <label class="custom_label">{emailLabel}</label>
                    <a href={`mailto:${email.name}`}><div class="phone_number">{email.name}</div></a>
                  </div>
                </div>
              </div>
            </div>
          </div>          
        </div>
      </section>
    </>
  );
}

export { ContactEnquiry };
