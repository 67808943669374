import React, { Fragment, useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
// import { connect,useSelector, } from 'react-redux';
import { Link } from "react-router-dom";
import { getLandingMshStory } from "../../../actions";
import Item from "../../../item";
import { appConstants } from "../../../helpers/app-constants";
import isEmpty from "../../../isEmpty";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import "../../../index.css";
import ReactHtmlParser, {
  processNodes,
  convertNodeToElement,
  htmlparser2,
} from "react-html-parser";
function MshStory(props) {
  const { landingmshStory } = useSelector((state) => {
    return state.Choose;
  });

  const dispatch = useDispatch();

  const [section, SetSection] = useState([]);
  const [heading, SetHeading] = useState("");
  const [backImage, SetBackImage] = useState("");
  const [parag, SetParag] = useState("");

  useEffect(() => {
    // dispatch(getLanuageList());
    dispatch(getLandingMshStory(props.itemid));
  }, [dispatch]);

  useEffect(() => {
    if (!isEmpty(landingmshStory)) {
      const items = landingmshStory.items;
      console.log(items);
      if (!isEmpty(items)) {
        SetHeading(items[0].name_en);
        SetBackImage(items[1].image);
        SetParag(items[2].name_en);
      }
    }
  }, [landingmshStory]);

  return (
    <section class="section_space buytravel_outer">
      <div class="container custom_container">
        <div class="row">
          <div class="col-md-12">
            <div
              class="agent_title buytravel_title aos-item"
              data-aos="fade-up"
            >
              {heading}
            </div>
            <img class="tree_img" src={backImage} alt="tree" />
            <div class="buyer_title">{ReactHtmlParser(parag)}</div>
          </div>
        </div>
      </div>
    </section>
  );
}

export { MshStory };
