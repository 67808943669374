import React, { Fragment, useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getLandingFaqDynamic, getLandingFaqMetatag } from "../../../actions";
import Header from "../../Comman/Header";
import { Footer } from "../../Comman/Footer";
import isEmpty from "../../../isEmpty";
import $ from "jquery";
import { FaqSection } from "../Sections/FaqSection";
import { ExpertSection } from "../Sections/ExpertSection";
import ReactHtmlParser, {
  processNodes,
  convertNodeToElement,
  htmlparser2,
} from "react-html-parser";

const breakPoints = [
  { width: 1, itemsToShow: 1 },
  { width: 550, itemsToShow: 2, itemsToScroll: 2 },
  { width: 768, itemsToShow: 4 },
  { width: 1200, itemsToShow: 4 },
];

const breakPoints2 = [
  { width: 1, itemsToShow: 1 },
  { width: 550, itemsToShow: 2, itemsToScroll: 2 },
  { width: 768, itemsToShow: 2 },
  { width: 1200, itemsToShow: 2 },
];

function FaqCategory(props) {
  const { faqLandingDynamic } = useSelector((state) => {
    return state.Faq;
  });

  const dispatch = useDispatch();

  const [section, SetSection] = useState([]);
  const [heading, SetHeading] = useState("");
  const [backImage, SetBackImage] = useState("");
  const [faqListing, SetFaqListing] = useState([]);


  useEffect(() => {
    for (var i = 0; i < props.sections.length; i++) {
      if (props.sections[i].copy_section_id === 27) {
        dispatch(getLandingFaqDynamic(props.sections[i].id));
      }

    }
  }, [dispatch]);

  useEffect(() => {
    if (!isEmpty(faqLandingDynamic)) {
      const items = faqLandingDynamic;
      if (!isEmpty(items)) {
        SetFaqListing(items);
      }
    }

  }, [faqLandingDynamic]);

  const handlef = (e) => {
    if (e.target.getAttribute("class") === "fal fa-plus") {
      $(e.target).attr("class", "fal fa-minus");
    } else {
      $(e.target).attr("class", "fal fa-plus");
    }
  };

  const handler = (e) => {
    $("ul.faq_ul li").removeClass("active");
    $(e.target).parent("li").addClass("active");
    $(".demo,.faq_title").addClass("d-none");
    $(
      ".headfaq-" +
      e.target.getAttribute("showit") +
      ",.subfaq-" +
      e.target.getAttribute("showit")
    ).removeClass("d-none");
  };

  const doSomethingMob = (ii) => { };
  return (
    <div>
      <section class="section_space faq_outer">
        <div class="container-fluid">
          <div class="row">
            <div class="col-md-3">
              {props.sections.map((sec) => (
                <>
                  {(() => {
                    switch (sec.copy_section_id) {
                      case 27:
                        return <div className="faq_left"><div
                          class="panel-group mobile_category"
                          id="accordion_category"
                          role="tablist"
                          aria-multiselectable="true"
                        >
                          <div class="panel panel-default">
                            <div class="panel-heading" role="tab" id="heading2">
                              <h4 class="panel-title">
                                <a
                                  class="accordion-toggle"
                                  role="button"
                                  data-bs-toggle="collapse"
                                  data-bs-parent="#accordion_category"
                                  href="#collapse2"
                                  aria-expanded="true"
                                  aria-controls="collapse2"
                                >
                                  <div
                                    class="agent_title buytravel_title question_title aos-item aos-init aos-animate"
                                    data-aos="fade-up"
                                  >
                                    SELECT A CATEGORY
                                  </div>
                                </a>
                              </h4>
                            </div>
                            <div
                              id="collapse2"
                              class="panel-collapse collapse in"
                              role="tabpanel"
                              aria-labelledby="heading2"
                            >
                              <div class="panel-body">
                                <ul class="faq_ul">
                                  {faqListing &&
                                    faqListing.map(function (fq, index) {
                                      return (
                                        <li
                                          className={index == 0 ? "active" : ""}
                                          key={index}
                                        >
                                          <a onClick={handler} showit={index}>
                                            {fq.name_en}
                                          </a>
                                        </li>
                                      );
                                    })}
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                          <div class="desktop_category">
                            <ul class="faq_ul">
                              {faqListing &&
                                faqListing.map(function (fq, index) {
                                  return (
                                    <li
                                      className={index == 0 ? "active" : ""}
                                      key={index}
                                    >
                                      <a onClick={handler} showit={index}>
                                        {fq.name_en}
                                      </a>
                                    </li>
                                  );
                                })}
                            </ul>
                          </div>
                        </div>;
                      case 26:
                        return <ExpertSection itemid={sec.id} />;
                      default:
                        return "";
                    }
                  })()}
                </>
              ))}

            </div>
            <div class="col-md-9">
              <div class="faq_right">
                {faqListing &&
                  faqListing.map(function (fq, index) {
                    var subcat = fq.faqlistcont.map(function (ct, i) {
                      return (
                        <div
                          class={
                            index == 0
                              ? `demo subfaq-${index}`
                              : `demo d-none subfaq-${index}`
                          }
                        >
                          <div class="accordion" id="accordionExample">
                            <div class="card">
                              <div class="card-header" id="headingOne">
                                <h2 class="mb-0">
                                  <button
                                    type="button"
                                    class="btn btn-link"
                                    data-bs-toggle="collapse"
                                    data-bs-target={
                                      "#collapse" + i + "-" + fq.id
                                    }
                                  >
                                    <span>{ct.name_en}</span>

                                    <i
                                      class="fal fa-plus"
                                      onClick={handlef}
                                    ></i>
                                  </button>
                                </h2>
                              </div>
                              <div
                                id={"collapse" + i + "-" + fq.id}
                                class="collapse"
                                aria-labelledby="headingOne"
                                data-bs-parent="#accordionExample"
                              >
                                <div class="card-body">
                                  <p>{ReactHtmlParser(ct.content_en)}</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    });
                    return (
                      <>
                        <div
                          class={
                            index == 0
                              ? `faq_title headfaq-${index}`
                              : `faq_title catg_2 d-none headfaq-${index}`
                          }
                          key={index}
                        >
                          <img src={fq.image} alt="" />
                          {fq.name_en}
                        </div>
                        {subcat}
                      </>
                    );
                  })}
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export { FaqCategory };
