import React, { Fragment, useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
// import { connect,useSelector, } from 'react-redux';
import { Link } from "react-router-dom";
import { getLandingBusinessTab } from "../../../actions";
import Item from "../../../item";
import { appConstants } from "../../../helpers/app-constants";
import isEmpty from "../../../isEmpty";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import "../../../index.css";

function BusinessTab(props) {
  const { landingbusinessTab } = useSelector((state) => {
    return state.Business;
  });

  const dispatch = useDispatch();

  const [homeSlider2, SetHomeSlider2] = useState([]);
  const [responsive, SetResponsive] = useState({
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4,
      slidesToSlide: 1, // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1024, min: 768 },
      items: 2,
      slidesToSlide: 1, // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 550, min: 1 },
      items: 1,
      slidesToSlide: 1, // optional, default to 1.
    },
  });

  const [responsive2, SetResponsive2] = useState({
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 2,
      slidesToSlide: 1, // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1024, min: 768 },
      items: 2,
      slidesToSlide: 1, // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 550, min: 1 },
      items: 1,
      slidesToSlide: 1, // optional, default to 1.
    },
  });
  const [sliderHeading, SetSliderHeading] = useState("");
  const [slides, SetSlides] = useState([]);

  useEffect(() => {
    // dispatch(getLanuageList());
    dispatch(getLandingBusinessTab(props.itemid));
  }, [dispatch]);

  useEffect(() => {
    if (!isEmpty(landingbusinessTab)) {
      const items = landingbusinessTab.items;
      const slides = landingbusinessTab.slides;
      if (!isEmpty(items)) {
        SetSliderHeading(items[0].name_en);
      }

      if (!isEmpty(slides)) {
        SetSlides(slides);
      }
    }
  }, [landingbusinessTab]);

  return (
    <section class="section_space buytravel_outer testimonial_outer general_inquires professional_uls">
      <div class="container custom_container">
        <div class="row">
          <div class="col-md-12">
            <ul class="professional_ul">
              {slides.map((item, index) => (
                <li>
                  <a href={item.url}>{item.name_en}</a>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </section>
  );
}

export { BusinessTab };
