import React, { Fragment, useState, useEffect } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { Link } from 'react-router-dom';
import { getLandingFaqBanner } from '../../../actions';
import Item from '../../../item';
import { appConstants } from '../../../helpers/app-constants';
import isEmpty from "../../../isEmpty";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import "../../../index.css";

function FaqSection(props) {

  const { faqLandingSection } = useSelector((state) => {
    return state.Faq;
  });

  const dispatch = useDispatch();

  const [section, SetSection] = useState([]);
  const [heading, SetHeading] = useState("");
  const [backImage, SetBackImage] = useState("");

  useEffect(() => {
    dispatch(getLandingFaqBanner(props.itemid));

  }, [dispatch]);

  useEffect(() => {

    if (!isEmpty(faqLandingSection)) {
      const items = faqLandingSection.items;
      if (!isEmpty(items)) {
        SetHeading(items[0].name_en);
        SetBackImage(items[1].image);
      }
    }
  }, [faqLandingSection]);

  const bannerstyle = { backgroundImage: 'url(' + backImage + ')' };
  return (
    <section class="section_space banner_outer why_choose contact_banner" style={bannerstyle} >
      <div class="overlay"></div>
      <div class="container">
        <div class="row align-items-center">
          <div class="col-md-12">
            <div class="banner_inner">
              <div
                class="travel_title why_choose_title aos-item"
                data-aos="fade-up"
              >
                {heading}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export { FaqSection };

