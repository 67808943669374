import React, { Fragment, useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import isEmpty from "../../isEmpty";

import { getLandingServiceMetatag } from "../../actions";
import Carousel from "react-elastic-carousel";
import Item from "../../item";
import  Header  from "../Comman/Header";
import { Footer } from "./../Comman/Footer";
import { BusinessBanner } from "./Sections/BusinessBanner";
import { BusinessService } from "./Sections/BusinessService";
import { BusinessBottom } from "./Sections/BusinessBottom";
import { BusinessTab } from "./Sections/BusinessTab";
import { BusinessGroups } from "./Sections/BusinessGroups";

function ShopLanding(props) {
  const { landingservicemetatag } = useSelector((state) => {
    return state.Business;
  });

  const dispatch = useDispatch();
  const [metakey, SetMetakey] = useState("");
  const [metadesc, SetMetadesc] = useState("");
  const [metatitle, SetMetatitle] = useState("");
  const [metalang, SetMetalang] = useState("");
  const [metatags, SetMetatags] = useState("");
  useEffect(() => {
    // dispatch(getLanuageList());
    for (var i = 0; i < props.sections.length; i++) {
      if (props.sections[i].copy_section_id === 217) {
        dispatch(getLandingServiceMetatag(props.sections[i].id));
      }
    }
  }, [dispatch]);

  useEffect(() => {
    if (!isEmpty(landingservicemetatag)) {
      const items = landingservicemetatag.items;
      if (!isEmpty(items)) {
        SetMetakey(landingservicemetatag.items[0].name);
        SetMetadesc(landingservicemetatag.items[1].name);
        SetMetatitle(landingservicemetatag.items[2].name);
        SetMetalang(landingservicemetatag.items[3].name);
        SetMetatags(landingservicemetatag.items[4].name);
      }
    }
  }, [landingservicemetatag]);

  return (
    <div>
      <Header
        metatitle={metatitle}
        metadesc={metadesc}
        metakey={metakey}
        metalang={metalang}
        metatags={metatags}
      />
      {props.sections.map((sec) => (
        <>
          {(() => {
            switch (sec.copy_section_id) {
              case 10:
                return <BusinessBanner itemid={sec.id} />;
              case 11:
                return <BusinessService itemid={sec.id} />;

              case 13:
                return <BusinessTab itemid={sec.id} />;
              case 14:
                return (
                  <BusinessGroups
                    pfgsections={props.sections}
                    itemid={sec.id}
                  />
                );
              case 12:
                return (
                  <BusinessBottom
                    pfgsections={props.sections}
                    itemid={sec.id}
                  />
                );
              default:
                return "";
            }
          })()}
        </>
      ))}
      <Footer />

      <div
        class="modal custom_modal fade"
        id="requestinfoModal"
        tabindex="-1"
        aria-labelledby="selectplanModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog">
          <div class="modal-content">
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
            <div class="modal-body select_plan_body select_plan_body1 request_info_body">
              <div class="agent_title buytravel_title">Request information</div>
              <p class="p_text">
                A member of our team will reach out to you within one business
                day.
              </p>
            </div>
            <div class="modal-body select_plan_body select_plan_body1 select_plan_body2">
              <div class="row">
                <div class="col-md-12">
                  <div class="check_apply">
                    <p class="p_text">
                      I want to know more about (check all that apply):
                    </p>
                    <div class="request_check_outer">
                      <div class="request_check">
                        <label class="signup_check">
                          BUSINESS/GROUP SERVICE 1
                          <input type="checkbox" checked="checked" />
                          <span class="checkmark"></span>
                        </label>
                        <label class="signup_check">
                          BUSINESS/GROUP SERVICE 2
                          <input type="checkbox" checked="checked" />
                          <span class="checkmark"></span>
                        </label>
                        <label class="signup_check">
                          BUSINESS/GROUP SERVICE 3
                          <input type="checkbox" checked="checked" />
                          <span class="checkmark"></span>
                        </label>
                      </div>
                      <div class="request_check">
                        <label class="signup_check">
                          BUSINESS/GROUP SERVICE 4
                          <input type="checkbox" checked="checked" />
                          <span class="checkmark"></span>
                        </label>
                        <label class="signup_check">
                          BUSINESS/GROUP SERVICE 5
                          <input type="checkbox" checked="checked" />
                          <span class="checkmark"></span>
                        </label>
                        <label class="signup_check">
                          BUSINESS/GROUP SERVICE 6
                          <input type="checkbox" checked="checked" />
                          <span class="checkmark"></span>
                        </label>
                      </div>
                    </div>
                    <div class="contact_input_inner">
                      <div class="contact_info_first">
                        <label class="custom_label">Your Name</label>
                        <input
                          class="contact_input_field"
                          type="text"
                          placeholder=""
                          name=""
                        />
                      </div>
                      <div class="contact_info_first">
                        <label class="custom_label">Your Email</label>
                        <input
                          class="contact_input_field"
                          type="text"
                          placeholder=""
                          name=""
                        />
                      </div>
                    </div>
                    <div class="view_plan_inner submit_outr">
                      <a
                        class="go_btn view_plan_btn"
                        data-aos="fade-up"
                        data-bs-dismiss="modal"
                        data-bs-toggle="modal"
                        data-bs-target="#thankyouModal"
                      >
                        Submit
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        class="modal custom_modal fade"
        id="thankyouModal"
        tabindex="-1"
        aria-labelledby="thankyouModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog">
          <div class="modal-content">
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
            <div class="modal-body select_plan_body">
              <div class="agent_title buytravel_title">
                Thank you for signing up!
              </div>
              <p class="thankyou_p">
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy
                text ever since the 1500s, when an unknown printer took a galley
                of type and scrambled it to make a type specimen book.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export { ShopLanding };
