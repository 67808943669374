import React, { Fragment, useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
// import { connect,useSelector, } from 'react-redux';
import { Link } from "react-router-dom";
import MetaTags from "react-meta-tags";

import {
  getMenus,
  getSelectedLanguage,
  setSelectedLanguage,
  sendQuoteMail
} from "../../actions";
import Item from "../../item";
import { appConstants } from "../../helpers/app-constants";
import isEmpty from "../../isEmpty";
import { Select } from "react-select";
import { getBusinessPopup } from './../../actions/bussiness.action';
import { Modal } from "react-bootstrap";
import { getContactForm, saveContactForm } from "../../actions";
import ReactHtmlParser from "react-html-parser";

import { bounce, flipInX, flipInY, flipOutX, flip } from 'react-animations';
import Radium, { StyleRoot } from 'radium';

// include styles
import 'rodal/lib/rodal.css';


function ProductPop(props) {
  const {
    insstatictext,
    sendmail,
  } = useSelector((state) => {
    return state.Home;
  });

  const [emailLabel, SetEmailLabel] = useState("");
  const [emailValidation, SetEmailValidation] = useState("");
  const [submitText, SetSubmitText] = useState("");
  const [cmessage, SetCMessage] = useState("");
  const [emailvalue, SetEmailvalue] = useState("");
  const [emailId, SetEmailId] = useState("");
  const [errors, SetErrors] = useState({});
  const [formsData, SetFormsData] = useState({
    email: "",
    itemid: "",
  });


  useEffect(() => {
    // alert(props.itemId);
    SetFormsData({
      ...formsData,
      itemid: props.itemId,
    });

    //   alert(d);
  }, []);

  useEffect(() => {

    if (!isEmpty(insstatictext)) {
      SetEmailLabel(insstatictext.items[14].name);
      SetEmailValidation(insstatictext.items[15].name);
      SetSubmitText(insstatictext.items[16].name);
      SetCMessage(insstatictext.items[17].name);
    }
  }, [insstatictext]);


  function handlesValidation(e) {
    let errors = {};
    let formIsValid = true;

    if (!emailvalue) {
      formIsValid = false;
      errors["emails"] = emailValidation;
    } else {
      let regEmail =
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (!regEmail.test(emailvalue)) {
        formIsValid = false;
        errors["emails"] = "Invalid Email Address";
      }
    }

    SetErrors(errors);
    return formIsValid;
  }

  const handleemailChange = (event) => {

    SetEmailvalue(event.target.value);
    SetFormsData({
      ...formsData,
      [event.target.name]: event.target.value,
    });
  };

  const [show, setShow] = useState(false);

  const handleClose = () => {
    setShow(false);
    props.closeShow();
  };

  const sendMail = (event) => {
    if (handlesValidation()) {
      dispatch(sendQuoteMail(formsData));
      handleClose();
    }
  };

  useEffect(() => {

    if (sendmail.success) {
      var m = cmessage;
      var p = m.replace(/(<([^>]+)>)/ig, '');
      alert(p);
      handleClose();
    }
  }, [sendmail]);


  const styles = {
    bounce: {
      animation: 'x 1s',
      animationName: Radium.keyframes(flipInX, 'flip')
    }
  }

  const dispatch = useDispatch();

  return (

    <div>

      <StyleRoot>
        <Modal
          show={props.show}
          className="fade custom_modal modal "
          onHide={handleClose}
        >
          <div style={styles.bounce}>
            <div className="modal-body select_plan_body select_plan_body1 select_plan_body2">
              <div className="row">
                <div className="col-md-12">

                  {/* <Modal.Title>{emailLabel}:</Modal.Title> */}
                  <button
                    type="button"
                    className="btn-close"
                    onClick={handleClose}
                  ></button>
                  <div className="modal-body select_plan_body select_plan_body1 select_plan_body2">

                    <input
                      type="email"
                      value={emailvalue}
                      name="email"
                      onChange={handleemailChange}
                      placeholder={emailLabel}
                      class="select_custom last_popup_input"
                    />
                    <span class="error_contact" style={{ color: "red" }}>
                      {errors["emails"]}
                    </span>
                    <div className="row">
                      <div className="col-md-12">
                        <div className="view_plan_inner">
                          <input
                            variant="primary"
                            class="btn go_btn view_plan_btn "
                            type="submit"
                            onClick={sendMail}
                            value={submitText}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal>
      </StyleRoot>
    </div>
  );
}


export { ProductPop };
