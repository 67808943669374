import React, { Fragment, useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import isEmpty from "../../isEmpty";

import { getServiceMetatag } from "../../actions";
import  Header  from "../Comman/Header";
import { Footer } from "../Comman/Footer";

import { BusinessBanner } from "./BusinessBanner";
import { BusinessService } from "./BusinessService";
import { BusinessBottom } from "./BusinessBottom";
import { BusinessTab } from "./BusinessTab";
import { BusinessGroups } from "./BusinessGroups";

function ShopInsuranceService(props) {
  const { servicemetatag } = useSelector((state) => {
    return state.Business;
  });

  const dispatch = useDispatch();
  const [metakey, SetMetakey] = useState("");
  const [metadesc, SetMetadesc] = useState("");
  const [metatitle, SetMetatitle] = useState("");
  const [metalang, SetMetalang] = useState("");
  const [metatags, SetMetatags] = useState("");

  useEffect(() => {
    // dispatch(getLanuageList());
    dispatch(getServiceMetatag());
  }, [dispatch]);

  useEffect(() => {
    if (!isEmpty(servicemetatag)) {
      const items = servicemetatag.items;
      if (!isEmpty(items)) {
        SetMetakey(servicemetatag.items[0].name);
        SetMetadesc(servicemetatag.items[1].name);
        SetMetatitle(servicemetatag.items[2].name);
        SetMetalang(servicemetatag.items[3].name);
        SetMetatags(servicemetatag.items[4].name);
      }
    }
  }, [servicemetatag]);

  return (
    <div>
      <Header
        metatitle={metatitle}
        metadesc={metadesc}
        metakey={metakey}
        metalang={metalang}
        metatags={metatags}
      />

      <BusinessBanner />
      <BusinessService />

      <BusinessTab />
      <BusinessGroups />

      <BusinessBottom />
      <Footer />
    </div>
  );
}

export { ShopInsuranceService };
