import React, { Fragment, useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { getAgentLanding } from "../../../actions";
import Item from "../../../item";
import { appConstants } from "../../../helpers/app-constants";
import isEmpty from "../../../isEmpty";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import "../../../index.css";
import ReactHtmlParser, {
  processNodes,
  convertNodeToElement,
  htmlparser2,
} from "react-html-parser";

function AgentSection(props) {
  const { viewLandingAgent } = useSelector((state) => {
    return state.Home;
  });

  const dispatch = useDispatch();
  const [section, SetSection] = useState([]);
  const [heading, SetHeading] = useState("");
  const [heading2, SetHeading2] = useState("");
  const [btn, SetBtn] = useState({});

  const [backImage, SetBackImage] = useState("");
  const [slides, SetSlides] = useState([]);

  useEffect(() => {
    // dispatch(getLanuageList());
    dispatch(getAgentLanding(props.itemid));
  }, [dispatch]);

  useEffect(() => {
    if (!isEmpty(viewLandingAgent)) {
      const items = viewLandingAgent.items;
      if (!isEmpty(items)) {
        SetHeading(items[0].name);
        SetHeading2(items[1].name);
        SetBtn(items[2]);
      }
    }
  }, [viewLandingAgent]);

  return (
    <section className="section_space agent_outer">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="agent_inner aos-item" data-aos="fade-up">
              <div className="agent_title">{heading}</div>
              <div className="agent_subtitle">{ReactHtmlParser(heading2)}</div>
              <a href={btn.url} className="go_btn view_plan_btn">
                <i className="fal fa-long-arrow-right"></i>
                {btn.name_en}
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export { AgentSection };
