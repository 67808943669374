import React, { Fragment, useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getLandingAgentSection } from "../../../actions";
import Item from "../../../item";
import { appConstants } from "../../../helpers/app-constants";
import isEmpty from "../../../isEmpty";
import "react-multi-carousel/lib/styles.css";
import "../../../index.css";
import ReactHtmlParser, {
  processNodes,
  convertNodeToElement,
  htmlparser2,
} from "react-html-parser";

function ContactAgentSection(props) {
  const { landingcontagentSection } = useSelector((state) => {
    return state.Contact;
  });

  const dispatch = useDispatch();

  const [heading1, SetHeading1] = useState("");
  const [subHeading1, SetSubHeading1] = useState("");

  const [heading2, SetHeading2] = useState("");
  const [subHeading2, SetSubHeading2] = useState("");

  const [button1, SetButton1] = useState("");
  const [button2, SetButton2] = useState("");

  useEffect(() => {
    // dispatch(getLanuageList());
    dispatch(getLandingAgentSection(props.itemid));
  }, [dispatch]);

  useEffect(() => {
    if (!isEmpty(landingcontagentSection)) {
      const items = landingcontagentSection.items;
      if (!isEmpty(items)) {
        SetHeading1(items[0].name_en);
        SetSubHeading1(items[2].name_en);
        SetButton1(items[4]);
        SetHeading2(items[1].name_en);
        SetSubHeading2(items[3].name_en);
        SetButton2(items[5]);
      }
    }
  }, [landingcontagentSection]);

  return (
    <>
      <section class="section_space buytravel_outer testimonial_outer club_outer contact_club">
        <div class="inhouse_right ready_insurance aos-item" data-aos="zoom-in">
          <div
            class="agent_title buytravel_title question_title aos-item"
            data-aos="fade-up"
          >
            {heading1}
          </div>
          <div class="insurance_title fixed_height_insurance aos-item" data-aos="fade-up">
            {ReactHtmlParser(subHeading1)}
          </div>
          <div class="view_plan_inner aos-item" data-aos="fade-up">
            <a href={button1.url} class="go_btn view_plan_btn chat_btn">
              <i class="fal fa-long-arrow-right"></i>
              {button1.name_en}
            </a>
          </div>
        </div>
        <div class="inhouse_right ready_insurance ready_insuranceright">
          <div
            class="agent_title buytravel_title question_title aos-item"
            data-aos="fade-up"
          >
            {heading2}
          </div>
          <div
            class="insurance_title fixed_height_insurance  aos-item"
            data-aos="fade-up"
          >
            {ReactHtmlParser(subHeading2)}
          </div>
          <div class="view_plan_inner aos-item" data-aos="fade-up">
            <a href={button2.url} class="go_btn view_plan_btn chat_btn">
              <i class="fal fa-long-arrow-right"></i>
              {button2.name_en}
            </a>
          </div>
        </div>
      </section>
    </>
  );
}

export { ContactAgentSection };
