import React, { Fragment, useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getInhouseSection } from "../../actions";
import isEmpty from "../../isEmpty";
import "react-multi-carousel/lib/styles.css";
import "../../index.css";

function InhouseSection(props) {
  const { inhouseSection } = useSelector((state) => {
    return state.Choose;
  });

  const dispatch = useDispatch();

  const [heading, SetHeading] = useState("");

  useEffect(() => {
    dispatch(getInhouseSection());
  }, [dispatch]);

  useEffect(() => {
    if (!isEmpty(inhouseSection)) {
      const items = inhouseSection.items;
      console.log(items);
      if (!isEmpty(items)) {
        SetHeading(items[0].name_en);
      }
    }
  }, [inhouseSection]);
  return (
    <section class="section_space buytravel_outer">
      <div class="container custom_container">
        <div class="row">
          <div class="col-md-12">
            <div
              class="agent_title buytravel_title in_house_title aos-item"
              data-aos="fade-up"
            >
              {heading}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export { InhouseSection };
