import { appConstants } from "../helpers/app-constants";

const intialState = {
  legalBanner: [],
  termsPolicy: [],
  regulateInfo: [],
  termsService: [],
  landingtermsPolicy: [],
  landingregulateInfo: [],
  landingtermsService: [],
  legalmetatag: [],
  landinglegalBanner: [],
  landinglegalmetatag: [],
};

// contact reducer
export const Legal = (state = intialState, action) => {
  switch (action.type) {
    case appConstants.GET_LEGAL_BANNER:
      return {
        ...state,
        legalBanner: action.payload,
      };
    case appConstants.GET_LANDING_LEGAL_BANNER:
      return {
        ...state,
        landinglegalBanner: action.payload,
      };

    case appConstants.GET_TERMS_POLICY:
      return {
        ...state,
        termsPolicy: action.payload,
      };
    case appConstants.GET_REGULATE_INFO:
      return {
        ...state,
        regulateInfo: action.payload,
      };
    case appConstants.GET_TERMS_SERVICE:
      return {
        ...state,
        termsService: action.payload,
      };
    case appConstants.GET_LANDING_TERMS_POLICY:
      return {
        ...state,
        landingtermsPolicy: action.payload,
      };
    case appConstants.GET_LANDING_REGULATE_INFO:
      return {
        ...state,
        landingregulateInfo: action.payload,
      };
    case appConstants.GET_LANDING_TERMS_SERVICE:
      return {
        ...state,
        landingtermsService: action.payload,
      };
    case appConstants.GET_LEGAL_METATAG:
      return {
        ...state,
        legalmetatag: action.payload,
      };
    case appConstants.GET_LANDING_LEGAL_METATAG:
      return {
        ...state,
        landinglegalmetatag: action.payload,
      };
    default:
      return state;
  }
};
