import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import ReCAPTCHA from "react-google-recaptcha";
import { useHistory } from "react-router-dom";
import { getContactForm, saveContactForm } from "../../actions";
import isEmpty from "../../isEmpty";
import "react-multi-carousel/lib/styles.css";
import "../../index.css";
import Swal from "sweetalert2";


function ContactForm(props) {
  let history = useHistory();
  const { contactForm } = useSelector((state) => {
    return state.Contact;
  });

  const { saveContact } = useSelector((state) => {
    return state.Contact;
  });

  const [formData, SetFormData] = useState({
    name: "",
    email: "",
    phone: "",
    msg: "",
    submitted: false,
  });
  const dispatch = useDispatch();

  const [names, SetNames] = useState([]);
  const [namevalid, SetNamevalid] = useState("");
  const [mailvalid, SetMailvalid] = useState("");
  const [msgvalid, SetMsgvalid] = useState("");
  const [captchavalid, SetCaptchavalid] = useState("");

  const [name, SetName] = useState("");
  const [email, SetEmail] = useState("");
  const [emails, SetEmails] = useState([]);
  const [phones, SetPhones] = useState([]);
  const [phone, SetPhone] = useState("");
  const [message, SetMessage] = useState([]);
  const [sign, SetSign] = useState([]);
  const [button, SetButton] = useState([]);
  const [msg, SetMsg] = useState("");
  const [errors, SetErrors] = useState({});
  const [check, SetCheck] = useState("");
  const [captcha, SetCaptcha] = useState("");
  function handleValidation(e) {
    let errors = {};
    let formIsValid = true;

    if (!name) {
      formIsValid = false;
      errors["name"] = namevalid;
    }
    if (!email) {
      formIsValid = false;
      errors["email"] = mailvalid;
    } else {
      let regEmail =
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (!regEmail.test(email)) {
        formIsValid = false;
        errors["email"] = mailvalid;
      }
    }

    if (!msg) {
      formIsValid = false;
      errors["msg"] = msgvalid;
    }
    if (!captcha) {
      formIsValid = false;
      errors["captcha"] = captchavalid;
    }

    SetErrors(errors);
    return formIsValid;
  }

  const handleChange = (event) => {
    if (event.target.name == "name") {
      SetName(event.currentTarget.value);
    }
    if (event.target.name == "email") {
      SetEmail(event.currentTarget.value);
    }
    if (event.target.name == "phone") {
      SetPhone(event.currentTarget.value);
    }
    if (event.target.name == "msg") {
      SetMsg(event.currentTarget.value);
    }
    if (event.target.name == "check") {
      SetCheck(!check);
    }

    SetFormData({
      ...formData,
      [event.target.name]: event.currentTarget.value,
    });
  };

  function handleSubmit() {
    console.log(captcha);
    console.log(formData)
    if (handleValidation()) {
      dispatch(saveContactForm(formData));
    }
  }

  function onChangecaptcha(value) {
    SetCaptcha(value);
  }

  useEffect(() => {
    dispatch(getContactForm());
  }, [dispatch]);

  useEffect(() => {
    if (!isEmpty(contactForm)) {
      const items = contactForm.items;
      if (!isEmpty(items)) {
        SetNames(items[0].name_en);
        SetEmails(items[1].name_en);
        SetPhones(items[2].name_en);
        SetMessage(items[3].name_en);
        SetSign(items[4].name_en);
        SetButton(items[5].name_en);
        SetNamevalid(items[6].name_en);
        SetMailvalid(items[7].name_en);
        SetMsgvalid(items[8].name_en);
        SetCaptchavalid(items[9].name_en);
      }
        
        
    }
    if (saveContact.success) {
      // alert(saveContact.message);
      Swal.fire({
        title: "Success",
        text: saveContact.message,
        icon: "success",
      }).then(function () {
        history.push("/");
        
      })
      // history.push("/");
    }
  }, [contactForm, saveContact]);
  return (
    <section class="section_space contact_title_outer contact_inputs_outer">
      <div class="container">
        <div class="row">
          <div class="col-md-12">
            <div class="contact_input_inner">
              <div class="contact_info_first">
                <label class="custom_label">{names}</label>
                <input
                  class="contact_input_field"
                  type="text"
                  placeholder=""
                  name="name"
                  onChange={handleChange}
                  value={name}
                />
              </div>
              <span class="error_contact" style={{ color: "red" }}>
                {errors["name"]}
              </span>

              <div class="contact_info_first">
                <label class="custom_label">{emails}</label>
                <input
                  class="contact_input_field"
                  type="email"
                  placeholder=""
                  name="email"
                  onChange={handleChange}
                  value={email}
                />
              </div>
              <span class="error_contact" style={{ color: "red" }}>
                {errors["email"]}
              </span>
              <div class="contact_info_first">
                <label class="custom_label">{phones}</label>
                <input
                  class="contact_input_field"
                  type="tel"
                  placeholder=""
                  name="phone"
                  onChange={handleChange}
                  value={phone}
                />
              </div>
              <div class="contact_info_first">
                <label class="custom_label">{message}</label>
                <textarea
                  class="contact_input_field"
                  value={msg}
                  onChange={handleChange}
                  name="msg"
                ></textarea>      
              </div>
              <span class="error_contact" style={{ color: "red" }}>
                {errors["msg"]}
              </span>
              <div class="contact_info_first">
                <label class="custom_label"></label>
                <div class="check_label">
                  {/* <label class="signup_check">
                    {sign}
                    <input
                      type="checkbox"
                      name="check"
                      checked={check}
                      onChange={handleChange}
                    />
                    <span class="checkmark"></span>
                  </label> */}
                  <div className="recaptha_div">
                    <ReCAPTCHA
                      sitekey="6Le7nPQdAAAAABDXDqw2eMloP6CcOPwjwoUiPN25"
                      onChange={onChangecaptcha}
                    />
                    <span class="" style={{ color: "red" }}>
                      {errors["captcha"]}
                    </span>
                  </div>
                  <button class="go_btn contact_submit" onClick={handleSubmit}>
                    <i class="fal fa-long-arrow-right"></i>
                    {button}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export { ContactForm };
