import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getStorySection } from "../../actions";
import Item from "../../item";
import isEmpty from "../../isEmpty";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import "../../index.css";
import ReactHtmlParser from "react-html-parser";

function StorySection(props) {
  const { storySection } = useSelector((state) => {
    return state.Choose;
  });

  const dispatch = useDispatch();

  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
      slidesToSlide: 1, // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1024, min: 768 },
      items: 1,
      slidesToSlide: 1, // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 768, min: 1 },
      items: 1,
      slidesToSlide: 1, // optional, default to 1.
    },
  };
  const [heading, SetHeading] = useState("");
  const [slides, SetSlides] = useState([]);
  const [storyImg, SetStoryImg] = useState("");
  const [sliderSpeed, SetSliderSpeed] = useState(0);


  useEffect(() => {
    dispatch(getStorySection());
  }, [dispatch]);

  useEffect(() => {
    if (!isEmpty(storySection)) {
      const items = storySection.items;
      const slides = storySection.reviews;

      if (!isEmpty(items)) {
        SetHeading(items[0].name_en);
        SetStoryImg(items[0].image);
        SetSliderSpeed(items[1].name_en);
      }

      if (!isEmpty(slides)) {
        SetSlides(slides);
      }
    }
  }, [storySection]);

  return (
    <Carousel
          responsive={responsive}
          additionalTransfrom={0}
          arrows
          autoPlay={true}
          autoPlaySpeed={sliderSpeed}
          centerMode={false}
          draggable
          focusOnSelect={false}
          infinite
          keyBoardControl
          minimumTouchDrag={80}
          renderButtonGroupOutside={false}
          renderDotsOutside={false}
          showDots
          sliderClass=""
          slidesToSlide={1}
          swipeable
          containerClass="carousel-container"
          // removeArrowOnDeviceType={["tablet", "mobile", "desktop"]}
          deviceType={props.deviceType}
          dotListClass="rec-dot"
          itemClass="carousel-item-padding-40-px"
        >
          {slides.map((item, index) => (
            <Item key={index}>
    <section class="section_space buytravel_outer testimonial_outer club_outer">  
      <div class="inhouse_left aos-item" data-aos="zoom-in">
        <img className="inhouse_left_img" src={item.image} alt="in_house" />
      </div>
      <div class="inhouse_right">
          <div class="buyer_inner Superlative_exp">
            <div class="buyer_title clientstory_title">
              {item.review_by}
            </div>
            <div class="buyer_title">{ReactHtmlParser(item.name_en)}</div>
          </div>  
      </div>
    </section>
    </Item>
      ))}
    </Carousel>
  );
}

export { StorySection };
