import axios from "axios";
import { returnErrors } from "../actions/error.action";
import { appConstants,config } from "../helpers/app-constants";
import { loaderDispatch } from "../helpers/app-constants";

export const getLegalBanner = () => (dispatch) => {
  loaderDispatch(dispatch, true);
  axios
    .get(appConstants.BACKEND_URL + "getHomeSectionItem/34",config)
    .then((res) => {
      loaderDispatch(dispatch, false);
      dispatch({ type: appConstants.GET_LEGAL_BANNER, payload: res.data.data });
    })
    .catch((err) => {
      loaderDispatch(dispatch, false);
      dispatch(returnErrors(err.response, err.response));
    });
};
export const getLandingLegalBanner = (id) => (dispatch) => {
  loaderDispatch(dispatch, true);
  axios
    .get(appConstants.BACKEND_URL + "getHomeSectionItem/" + id,config)
    .then((res) => {
      loaderDispatch(dispatch, false);
      dispatch({
        type: appConstants.GET_LANDING_LEGAL_BANNER,
        payload: res.data.data,
      });
    })
    .catch((err) => {
      loaderDispatch(dispatch, false);
      dispatch(returnErrors(err.response, err.response));
    });
};

export const getLegalMetatag = () => (dispatch) => {
  loaderDispatch(dispatch, true);
  axios
    .get(appConstants.BACKEND_URL + "getHomeSectionItem/214",config)
    .then((res) => {
      loaderDispatch(dispatch, false);
      dispatch({
        type: appConstants.GET_LEGAL_METATAG,
        payload: res.data.data,
      });
    })
    .catch((err) => {
      loaderDispatch(dispatch, false);
      dispatch(returnErrors(err.response, err.response));
    });
};
export const getLandingLegalMetatag = (id) => (dispatch) => {
  loaderDispatch(dispatch, true);
  axios
    .get(appConstants.BACKEND_URL + "getHomeSectionItem/" + id,config)
    .then((res) => {
      loaderDispatch(dispatch, false);
      dispatch({
        type: appConstants.GET_LANDING_LEGAL_METATAG,
        payload: res.data.data,
      });
    })
    .catch((err) => {
      loaderDispatch(dispatch, false);
      dispatch(returnErrors(err.response, err.response));
    });
};

export const getTermsPolicy = () => (dispatch) => {
  loaderDispatch(dispatch, true);
  axios
    .get(appConstants.BACKEND_URL + "getHomeSectionItem/35",config)
    .then((res) => {
      loaderDispatch(dispatch, false);
      dispatch({ type: appConstants.GET_TERMS_POLICY, payload: res.data.data });
    })
    .catch((err) => {
      loaderDispatch(dispatch, false);
      dispatch(returnErrors(err.response, err.response));
    });
};
export const getLandingTermsPolicy = (id) => (dispatch) => {
  loaderDispatch(dispatch, true);
  axios
    .get(appConstants.BACKEND_URL + "getHomeSectionItem/" + id,config)
    .then((res) => {
      loaderDispatch(dispatch, false);
      dispatch({
        type: appConstants.GET_LANDING_TERMS_POLICY,
        payload: res.data.data,
      });
    })
    .catch((err) => {
      loaderDispatch(dispatch, false);
      dispatch(returnErrors(err.response, err.response));
    });
};

export const getRegulateInfo = () => (dispatch) => {
  loaderDispatch(dispatch, true);
  axios
    .get(appConstants.BACKEND_URL + "getHomeSectionItem/36",config)
    .then((res) => {
      loaderDispatch(dispatch, false);
      dispatch({
        type: appConstants.GET_REGULATE_INFO,
        payload: res.data.data,
      });
    })
    .catch((err) => {
      loaderDispatch(dispatch, false);
      dispatch(returnErrors(err.response, err.response));
    });
};
export const getLandingRegulateInfo = (id) => (dispatch) => {
  loaderDispatch(dispatch, true);
  axios
    .get(appConstants.BACKEND_URL + "getHomeSectionItem/" + id,config)
    .then((res) => {
      loaderDispatch(dispatch, false);
      dispatch({
        type: appConstants.GET_LANDING_REGULATE_INFO,
        payload: res.data.data,
      });
    })
    .catch((err) => {
      loaderDispatch(dispatch, false);
      dispatch(returnErrors(err.response, err.response));
    });
};

export const getTermsService = () => (dispatch) => {
  loaderDispatch(dispatch, true);
  axios
    .get(appConstants.BACKEND_URL + "getHomeSectionItem/37",config)
    .then((res) => {
      loaderDispatch(dispatch, false);
      dispatch({
        type: appConstants.GET_TERMS_SERVICE,
        payload: res.data.data,
      });
    })
    .catch((err) => {
      loaderDispatch(dispatch, false);
      dispatch(returnErrors(err.response, err.response));
    });
};
export const getLandingTermsService = (id) => (dispatch) => {
  loaderDispatch(dispatch, true);
  axios
    .get(appConstants.BACKEND_URL + "getHomeSectionItem/" + id,config)
    .then((res) => {
      loaderDispatch(dispatch, false);
      dispatch({
        type: appConstants.GET_LANDING_TERMS_SERVICE,
        payload: res.data.data,
      });
    })
    .catch((err) => {
      loaderDispatch(dispatch, false);
      dispatch(returnErrors(err.response, err.response));
    });
};
