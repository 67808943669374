import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getHomeAgent } from "../../actions";
import isEmpty from "../../isEmpty";
import "react-multi-carousel/lib/styles.css";
import "../../index.css";
import ReactHtmlParser from "react-html-parser";

function AgentSection(props) {
  const { agentSection } = useSelector((state) => {
    return state.Home;
  });

  const dispatch = useDispatch();
  const [heading, SetHeading] = useState("");
  const [heading2, SetHeading2] = useState("");
  const [btn, SetBtn] = useState({});

  useEffect(() => {
    dispatch(getHomeAgent());
  }, [dispatch]);

  useEffect(() => {
    if (!isEmpty(agentSection)) {
      const items = agentSection.items;
      if (!isEmpty(items)) {
        SetHeading(items[0].name);
        SetHeading2(items[1].name);
        SetBtn(items[2]);
      }
    }
  }, [agentSection]);

  return (
    <section className="section_space agent_outer">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="agent_inner aos-item" data-aos="fade-up">
              <div className="agent_title">{heading}</div>
              <div className="agent_subtitle">{ReactHtmlParser(heading2)}</div>
              <a href={btn.url} className="go_btn view_plan_btn">
                <i className="fal fa-long-arrow-right"></i>
                {ReactHtmlParser(btn.name)}
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export { AgentSection };
