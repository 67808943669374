import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getContactTitle } from "../../actions";
import isEmpty from "../../isEmpty";
import "react-multi-carousel/lib/styles.css";
import "../../index.css";
import ReactHtmlParser from "react-html-parser";

function ContactTitle(props) {
  const { contactTitle } = useSelector((state) => {
    return state.Contact;
  });

  const dispatch = useDispatch();

  const [heading, SetHeading] = useState("");
  const [subHeading, SetSubHeading] = useState("");

  useEffect(() => {
    dispatch(getContactTitle());
  }, [dispatch]);

  useEffect(() => {
    if (!isEmpty(contactTitle)) {
      const items = contactTitle.items;
      if (!isEmpty(items)) {
        SetHeading(items[0].name_en);
        SetSubHeading(items[1].name_en);
      }
    }
  }, [contactTitle]);

  return (
    <section class="section_space contact_title_outer">
      <div class="container custom_container">
        <div class="row">
          <div class="col-md-8 offset-md-2 col-sm-12">
            <div
              class="agent_title buytravel_title contact_title aos-item"
              data-aos="fade-up"
            >
              {heading}
            </div>
            <p class="p_text contact_subtitle">{ReactHtmlParser(subHeading)}</p>
          </div>
        </div>
      </div>
    </section>
  );
}

export { ContactTitle };
