import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { userActions } from '../../actions';


class ForgotPassword extends Component {
  constructor(props) {
    super(props);
    this.props.logout();
    this.state = {
      email: '',
      password: '',
      submitted: false
    };
    this.handleInputChange = this.handleInputChange.bind(this);
    this.submitLogin = this.submitLogin.bind(this);

  }

  componentDidMount() {
    document.body.className += "bg-log";
  }

  componentWillUnmount() {
    document.body.className = document.body.className.replace("bg-log", "");
  }

  handleInputChange(e) {
    let name = e.target.name;
    let value = e.target.value;
    this.setState({
      [name]: value
    });
  }

  submitLogin(e) {
    e.preventDefault();
    this.setState({ submitted: true });
    const { email, password } = this.state;
    if (email && password) {
      this.props.login(email, password);
    }
  }

  render() {
    const { loggingIn } = this.props;
    const { email, password, submitted } = this.state;
    return (
      <div className="log-main">
        <div className="log-logo">
          <img src="assets/images/logo.png" alt="logo" />
        </div>
        <div className="log-head">Enter your login details</div>
        <form >
          <div className="form-group login-form">
            <i className="fa fa-user"></i>
            <input
              type="text"
              className="form-control"
              placeholder="email"
              name="email"
              id="username"
              value={email}
              onChange={this.handleInputChange}
            />
            {submitted && !email && <div className="help-block">Email is required</div>}

          </div>
          <div className="form-group login-form">
            <i className="fa fa-lock"></i>
            <input
              type="password"
              className="form-control"
              name="password"
              id="password"
              placeholder="Password"
              value={password}
              onChange={this.handleInputChange}
            />
            {submitted && !password && <div className="help-block">Password is required</div>}

          </div>
          <div className="form-check">
            <input
              className="form-check-input"
              type="checkbox"
              value=""
              id="defaultCheck1"
            />
            <label className="form-check-label" htmlFor="defaultCheck1">
              Remember Me
            </label>
            <span
              className="set-rht"
              data-toggle="modal"
              data-target="#myModal"
            >
              <Link href="#">Forgot Password?</Link>
            </span>
          </div>
          <div className="form-group sub-btn">
            <input
              type="submit"
              className="btn btn-block btn-lg"
              value="Sign in"
              onClick={this.submitLogin}
            />
          </div>
        </form>
      </div>

    )
  }
}
function mapState(state) {
  const { loggingIn } = state.authentication;
  return { loggingIn };
}

const actionCreators = {
  login: userActions.login,
  logout: userActions.logout
};

const connectedForgotPassword = connect(mapState, actionCreators)(ForgotPassword);
export { connectedForgotPassword as ForgotPassword };
