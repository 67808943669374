import axios from "axios";
import { returnErrors } from "../actions/error.action";
import { appConstants, config } from "../helpers/app-constants";
import { loaderDispatch } from "../helpers/app-constants";



export const getBusinessBanner = () => (dispatch) => {
  loaderDispatch(dispatch, true);
  axios
    .get(appConstants.BACKEND_URL + "getHomeSectionItem/10",config)
    .then((res) => {
      loaderDispatch(dispatch, false);
      dispatch({
        type: appConstants.GET_BUSINESS_BANNER,
        payload: res.data.data,
      });
    })
    .catch((err) => {
      loaderDispatch(dispatch, false);
      dispatch(returnErrors(err.response, err.response));
    });
};
export const getLandingBusinessBanner = (id) => (dispatch) => {
  loaderDispatch(dispatch, true);
  axios
    .get(appConstants.BACKEND_URL + "getHomeSectionItem/" + id,config)
    .then((res) => {
      loaderDispatch(dispatch, false);
      dispatch({
        type: appConstants.GET_LANDING_BUSINESS_BANNER,
        payload: res.data.data,
      });
    })
    .catch((err) => {
      loaderDispatch(dispatch, false);
      dispatch(returnErrors(err.response, err.response));
    });
};

export const getLandingBusinessService = (id) => (dispatch) => {
  loaderDispatch(dispatch, true);
  axios
    .get(appConstants.BACKEND_URL + "getHomeSectionItem/" + id,config)
    .then((res) => {
      loaderDispatch(dispatch, false);
      dispatch({
        type: appConstants.GET_LANDING_BUSINESS_SERVICE,
        payload: res.data.data,
      });
    })
    .catch((err) => {
      loaderDispatch(dispatch, false);
      dispatch(returnErrors(err.response, err.response));
    });
};
export const getBusinessService = () => (dispatch) => {
  loaderDispatch(dispatch, true);
  axios
    .get(appConstants.BACKEND_URL + "getHomeSectionItem/11",config)
    .then((res) => {
      loaderDispatch(dispatch, false);
      dispatch({
        type: appConstants.GET_BUSINESS_SERVICE,
        payload: res.data.data,
      });
    })
    .catch((err) => {
      loaderDispatch(dispatch, false);
      dispatch(returnErrors(err.response, err.response));
    });
};

export const getBusinessBottom = () => (dispatch) => {
  loaderDispatch(dispatch, true);
  axios
    .get(appConstants.BACKEND_URL + "getHomeSectionItem/12",config)
    .then((res) => {
      loaderDispatch(dispatch, false);
      dispatch({
        type: appConstants.GET_BUSINESS_BOTTOM,
        payload: res.data.data,
      });
    })
    .catch((err) => {
      loaderDispatch(dispatch, false);
      dispatch(returnErrors(err.response, err.response));
    });
};
export const getBusinessBottom2 = () => (dispatch) => {
  loaderDispatch(dispatch, true);
  axios
    .get(appConstants.BACKEND_URL + "getHomeSectionItem/48",config)
    .then((res) => {
      loaderDispatch(dispatch, false);
      dispatch({
        type: appConstants.GET_BUSINESS_BOTTOM2,
        payload: res.data.data,
      });
    })
    .catch((err) => {
      loaderDispatch(dispatch, false);
      dispatch(returnErrors(err.response, err.response));
    });
};
export const getBusinessBottom3 = () => (dispatch) => {
  loaderDispatch(dispatch, true);
  axios
    .get(appConstants.BACKEND_URL + "getHomeSectionItem/49",config)
    .then((res) => {
      loaderDispatch(dispatch, false);
      dispatch({
        type: appConstants.GET_BUSINESS_BOTTOM3,
        payload: res.data.data,
      });
    })
    .catch((err) => {
      loaderDispatch(dispatch, false);
      dispatch(returnErrors(err.response, err.response));
    });
};
export const getLandingBusinessBottom = (id) => (dispatch) => {
  loaderDispatch(dispatch, true);
  axios
    .get(appConstants.BACKEND_URL + "getHomeSectionItem/" + id,config)
    .then((res) => {
      loaderDispatch(dispatch, false);
      dispatch({
        type: appConstants.GET_LANDING_BUSINESS_BOTTOM,
        payload: res.data.data,
      });
    })
    .catch((err) => {
      loaderDispatch(dispatch, false);
      dispatch(returnErrors(err.response, err.response));
    });
};
export const getLandingBusinessBottom2 = (id) => (dispatch) => {
  loaderDispatch(dispatch, true);
  axios
    .get(appConstants.BACKEND_URL + "getHomeSectionItem/" + id,config)
    .then((res) => {
      loaderDispatch(dispatch, false);
      dispatch({
        type: appConstants.GET_LANDING_BUSINESS_BOTTOM2,
        payload: res.data.data,
      });
    })
    .catch((err) => {
      loaderDispatch(dispatch, false);
      dispatch(returnErrors(err.response, err.response));
    });
};
export const getLandingBusinessBottom3 = (id) => (dispatch) => {
  loaderDispatch(dispatch, true);
  axios
    .get(appConstants.BACKEND_URL + "getHomeSectionItem/" + id,config)
    .then((res) => {
      loaderDispatch(dispatch, false);
      dispatch({
        type: appConstants.GET_LANDING_BUSINESS_BOTTOM3,
        payload: res.data.data,
      });
    })
    .catch((err) => {
      loaderDispatch(dispatch, false);
      dispatch(returnErrors(err.response, err.response));
    });
};

export const getBusinessPopup = (id="") => (dispatch) => {
  loaderDispatch(dispatch, true);
  axios
    .get(appConstants.BACKEND_URL + "getHomeSectionItem/"+id,config)
    .then((res) => {
      loaderDispatch(dispatch, false);
      dispatch({
        type: appConstants.GET_BUSINESS_POPUP,
        payload: res.data.data,
      });
    })
    .catch((err) => {
      loaderDispatch(dispatch, false);
      dispatch(returnErrors(err.response, err.response));
    });
};

export const getServiceMetatag = () => (dispatch) => {
  loaderDispatch(dispatch, true);
  axios
    .get(appConstants.BACKEND_URL + "getHomeSectionItem/217",config)
    .then((res) => {
      loaderDispatch(dispatch, false);
      dispatch({
        type: appConstants.GET_SERVICE_METATAG,
        payload: res.data.data,
      });
    })
    .catch((err) => {
      loaderDispatch(dispatch, false);
      dispatch(returnErrors(err.response, err.response));
    });
};
export const getLandingServiceMetatag = (id) => (dispatch) => {
  loaderDispatch(dispatch, true);
  axios
    .get(appConstants.BACKEND_URL + "getHomeSectionItem/" + id,config)
    .then((res) => {
      loaderDispatch(dispatch, false);
      dispatch({
        type: appConstants.GET_LANDING_SERVICE_METATAG,
        payload: res.data.data,
      });
    })
    .catch((err) => {
      loaderDispatch(dispatch, false);
      dispatch(returnErrors(err.response, err.response));
    });
};

export const getBusinessTab = () => (dispatch) => {
  loaderDispatch(dispatch, true);
  axios
    .get(appConstants.BACKEND_URL + "getBusinessSilder",config)
    .then((res) => {
      // console.log(res);
      loaderDispatch(dispatch, false);
      dispatch({ type: appConstants.GET_BUSINESS_TAB, payload: res.data.data });
    })
    .catch((err) => {
      // console.log(err);
      loaderDispatch(dispatch, false);
      dispatch(returnErrors(err.response, err.response));
    });
};
export const getLandingBusinessTab = (id) => (dispatch) => {
  loaderDispatch(dispatch, true);
  axios
    .get(appConstants.BACKEND_URL + "getLandingBusinessSilder/" + id,config)
    .then((res) => {
      // console.log(res);
      loaderDispatch(dispatch, false);
      dispatch({
        type: appConstants.GET_LANDING_BUSINESS_TAB,
        payload: res.data.data,
      });
    })
    .catch((err) => {
      // console.log(err);
      loaderDispatch(dispatch, false);
      dispatch(returnErrors(err.response, err.response));
    });
};

export const getBusinessGroups = () => (dispatch) => {
  loaderDispatch(dispatch, true);
  axios
    .get(appConstants.BACKEND_URL + "getBusinessGroups",config)
    .then((res) => {
      // console.log(res);
      loaderDispatch(dispatch, false);
      dispatch({
        type: appConstants.GET_BUSINESS_GROUPS,
        payload: res.data.data,
      });
    })
    .catch((err) => {
      loaderDispatch(dispatch, false);
      dispatch(returnErrors(err.response, err.response));
    });
};
export const getBusinessGroups1 = () => (dispatch) => {
  loaderDispatch(dispatch, true);
  axios
    .get(appConstants.BACKEND_URL + "getBusinessGroups1",config)
    .then((res) => {
      // console.log(res);
      loaderDispatch(dispatch, false);
      dispatch({
        type: appConstants.GET_BUSINESS_GROUPS1,
        payload: res.data.data,
      });
    })
    .catch((err) => {
      loaderDispatch(dispatch, false);
      dispatch(returnErrors(err.response, err.response));
    });
};
export const getBusinessGroupsAll = () => (dispatch) => {
  loaderDispatch(dispatch, true);
  axios
    .get(appConstants.BACKEND_URL + "getBusinessGroupsAll",config)
    .then((res) => {
      // console.log(res);
      loaderDispatch(dispatch, false);
      dispatch({
        type: appConstants.GET_BUSINESS_GROUPS_ALL,
        payload: res.data.data,
      });
    })
    .catch((err) => {
      loaderDispatch(dispatch, false);
      dispatch(returnErrors(err.response, err.response));
    });
};
export const getBusinessGroups2 = () => (dispatch) => {
  loaderDispatch(dispatch, true);
  axios
    .get(appConstants.BACKEND_URL + "getBusinessGroups2",config)
    .then((res) => {
      // console.log(res);
      loaderDispatch(dispatch, false);
      dispatch({
        type: appConstants.GET_BUSINESS_GROUPS2,
        payload: res.data.data,
      });
    })
    .catch((err) => {
      loaderDispatch(dispatch, false);
      dispatch(returnErrors(err.response, err.response));
    });
};
export const getLandingBusinessGroups = (id) => (dispatch) => {
  loaderDispatch(dispatch, true);
  axios
    .get(appConstants.BACKEND_URL + "getLandingBusinessGroups/" + id,config)
    .then((res) => {
      // console.log(res);
      loaderDispatch(dispatch, false);
      dispatch({
        type: appConstants.GET_LANDING_BUSINESS_GROUPS,
        payload: res.data.data,
      });
    })
    .catch((err) => {
      loaderDispatch(dispatch, false);
      dispatch(returnErrors(err.response, err.response));
    });
};
export const getLandingBusinessGroups1 = (id) => (dispatch) => {
  loaderDispatch(dispatch, true);
  axios
    .get(appConstants.BACKEND_URL + "getLandingBusinessGroups1/" + id,config)
    .then((res) => {
      // console.log(res);
      loaderDispatch(dispatch, false);
      dispatch({
        type: appConstants.GET_LANDING_BUSINESS_GROUPS1,
        payload: res.data.data,
      });
    })
    .catch((err) => {
      loaderDispatch(dispatch, false);
      dispatch(returnErrors(err.response, err.response));
    });
};
export const getLandingBusinessGroups2 = (id) => (dispatch) => {
  loaderDispatch(dispatch, true);
  axios
    .get(appConstants.BACKEND_URL + "getLandingBusinessGroups2/" + id,config)
    .then((res) => {
      // console.log(res);
      loaderDispatch(dispatch, false);
      dispatch({
        type: appConstants.GET_LANDING_BUSINESS_GROUPS2,
        payload: res.data.data,
      });
    })
    .catch((err) => {
      loaderDispatch(dispatch, false);
      dispatch(returnErrors(err.response, err.response));
    });
};

//   export const getStorySection = () => (dispatch) => {
//     loaderDispatch(dispatch, true);
//     axios
//       .get(appConstants.BACKEND_URL+'getStoryReviews')
//       .then((res) => {
//           // console.log(res);
//         loaderDispatch(dispatch, false);
//        dispatch({ type: appConstants.GET_STORY_SECTION, payload: res.data.data });
//       })
//       .catch((err) => {

//         loaderDispatch(dispatch, false);
//         dispatch(returnErrors(err.response, err.response))
//       }
//       );
//   };
