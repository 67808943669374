import React, { Fragment, useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  getLandingTermsPolicy,
  getLandingRegulateInfo,
  getLandingTermsService,
} from "../../../actions";
import Item from "../../../item";
import { appConstants } from "../../../helpers/app-constants";
import isEmpty from "../../../isEmpty";
import "react-multi-carousel/lib/styles.css";
import "../../../index.css";
import ReactHtmlParser, {
  processNodes,
  convertNodeToElement,
  htmlparser2,
} from "react-html-parser";

function TermsPolicy(props) {
  const { landingtermsPolicy, landingregulateInfo, landingtermsService } =
    useSelector((state) => {
      return state.Legal;
    });

  const dispatch = useDispatch();

  const [mainhead, SetMainhead] = useState("");
  const [heading1, SetHeading1] = useState("");
  const [subHeading1, SetSubHeading1] = useState("");
  const [secHeading1, SetSecHeading1] = useState("");
  const [secSubHeading1, SetSecSubHeading1] = useState("");

  const [mainhead2, SetMainhead2] = useState("");
  const [heading2, SetHeading2] = useState("");
  const [subHeading2, SetSubHeading2] = useState("");
  const [secHeading2, SetSecHeading2] = useState("");
  const [secSubHeading2, SetSecSubHeading2] = useState("");

  const [mainhead3, SetMainhead3] = useState("");
  const [heading3, SetHeading3] = useState("");
  const [subHeading3, SetSubHeading3] = useState("");
  const [secHeading3, SetSecHeading3] = useState("");
  const [secSubHeading3, SetSecSubHeading3] = useState("");

  useEffect(() => {
    for (var i = 0; i < props.pfgsections.length; i++) {
      if (props.pfgsections[i].copy_section_id === 35) {
        dispatch(getLandingTermsPolicy(props.pfgsections[i].id));
      }
      if (props.pfgsections[i].copy_section_id === 36) {
        dispatch(getLandingRegulateInfo(props.pfgsections[i].id));
      }
      if (props.pfgsections[i].copy_section_id === 37) {
        dispatch(getLandingTermsService(props.pfgsections[i].id));
      }
    }
  }, [dispatch]);

  useEffect(() => {
    console.log(landingregulateInfo);
    if (
      !isEmpty(landingtermsPolicy) &&
      !isEmpty(landingregulateInfo) &&
      !isEmpty(landingtermsService)
    ) {
      const items = landingtermsPolicy.items;
      const regulate = landingregulateInfo.items;
      const terms = landingtermsService.items;
      if (!isEmpty(items) && !isEmpty(regulate)) {
        SetMainhead(items[4].name);
        SetMainhead2(regulate[4].name);
        SetMainhead3(terms[4].name);
        SetHeading1(items[0].name);
        SetSubHeading1(items[1].name);
        SetSecHeading1(items[2].name);
        SetSecSubHeading1(items[3].name);
        SetHeading2(regulate[0].name);
        SetSubHeading2(regulate[1].name);
        SetSecHeading2(regulate[2].name);
        SetSecSubHeading2(regulate[3].name);
        SetHeading3(terms[0].name);
        SetSubHeading3(terms[1].name);
        SetSecHeading3(terms[2].name);
        SetSecSubHeading3(terms[3].name);
      }
    }
  }, [landingtermsPolicy, landingregulateInfo, landingtermsService]);

  return (
    <section class="section_space legal_outer">
      <div class="container custom_container">
        <div class="row">
          <div class="col-md-4">
            <div class="legal_left">
              <ul class="nav nav-tabs" role="tablist">
                <li class="nav-item">
                  <a
                    class="nav-link active"
                    data-bs-toggle="tab"
                    href="#tabs-1"
                    role="tab"
                  >
                    {mainhead}
                  </a>
                </li>
                <li class="nav-item">
                  <a
                    class="nav-link"
                    data-bs-toggle="tab"
                    href="#tabs-2"
                    role="tab"
                  >
                    {mainhead2}
                  </a>
                </li>
                <li class="nav-item">
                  <a
                    class="nav-link"
                    data-bs-toggle="tab"
                    href="#tabs-3"
                    role="tab"
                  >
                    {mainhead3}
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div class="col-md-8">
            <div class="legal_right">
              <div class="tab-content">
                <div class="tab-pane active" id="tabs-1" role="tabpanel">
                  <div
                    class="agent_title buytravel_title aos-item aos-init aos-animate"
                    data-aos="fade-up"
                  >
                    {mainhead}
                  </div>
                  <div class="privacy_subtitle">{heading1}</div>
                  <p class="privacy_p">{ReactHtmlParser(subHeading1)}</p>
                  <div class="privacy_subtitle">{secHeading1}</div>
                  <p class="privacy_p">{ReactHtmlParser(secSubHeading1)}</p>
                </div>

                <div class="tab-pane" id="tabs-2" role="tabpanel">
                  <div
                    class="agent_title buytravel_title aos-item aos-init aos-animate"
                    data-aos="fade-up"
                  >
                    {mainhead2}
                  </div>
                  <div class="privacy_subtitle">{heading2}</div>
                  <p class="privacy_p">{ReactHtmlParser(subHeading2)}</p>
                  <div class="privacy_subtitle">{secHeading2}</div>
                  <p class="privacy_p">{ReactHtmlParser(secSubHeading2)}</p>
                </div>

                <div class="tab-pane" id="tabs-3" role="tabpanel">
                  <div
                    class="agent_title buytravel_title aos-item aos-init aos-animate"
                    data-aos="fade-up"
                  >
                    {mainhead3}
                  </div>
                  <div class="privacy_subtitle">{heading3}</div>
                  <p class="privacy_p">{ReactHtmlParser(subHeading3)}</p>
                  <div class="privacy_subtitle">{secHeading3}</div>
                  <p class="privacy_p">{ReactHtmlParser(secSubHeading3)}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export { TermsPolicy };
