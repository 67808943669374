import React, { Fragment, useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { getLandingExpertSection } from "../../../actions";
import Item from "../../../item";
import { appConstants } from "../../../helpers/app-constants";
import isEmpty from "../../../isEmpty";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import "../../../index.css";
import ReactHtmlParser, {
  processNodes,
  convertNodeToElement,
  htmlparser2,
} from "react-html-parser";

function ExpertSection(props) {
  const { landingexpertSection } = useSelector((state) => {
    return state.Faq;
  });

  const dispatch = useDispatch();

  const [section, SetSection] = useState([]);
  const [heading, SetHeading] = useState("");
  const [subHeading, SetSubHeading] = useState("");
  const [call, SetCall] = useState("");
  const [btn, SetBtn] = useState("");
  const [url, SetUrl] = useState("");

  useEffect(() => {
    dispatch(getLandingExpertSection(props.itemid));
  }, [dispatch]);

  useEffect(() => {
    if (!isEmpty(landingexpertSection)) {
      const items = landingexpertSection.items;
      if (!isEmpty(items)) {
        SetHeading(items[0].name);
        SetSubHeading(items[1].name);
        SetCall(items[2].name);
        SetBtn(items[3].name);
        SetUrl(items[0].url);
      }
    }
  }, [landingexpertSection]);

  return (
    <div class="faq_left talk_experts">
      <div
        class="panel-group mobile_questions"
        id="accordion_question"
        role="tablist"
        aria-multiselectable="true"
      >
        <div class="panel panel-default">
          <div class="panel-heading" role="tab" id="heading1">
            <h4 class="panel-title">
              <a
                class="accordion-toggle"
                role="button"
                data-bs-toggle="collapse"
                data-bs-parent="#accordion_question"
                href="#collapse1"
                aria-expanded="true"
                aria-controls="collapse1"
              >
                <div
                  class="agent_title buytravel_title question_title aos-item aos-init aos-animate"
                  data-aos="fade-up"
                >
                  {heading}
                </div>
              </a>
            </h4>
          </div>
          <div
            id="collapse1"
            class="panel-collapse collapse in"
            role="tabpanel"
            aria-labelledby="headingOne"
          >
            <div class="panel-body">
              <div
                class="insurance_title aos-item aos-init aos-animate"
                data-aos="fade-up"
              >
                {ReactHtmlParser(subHeading)}
              </div>
              <div class="call_title aos-item aos-init" data-aos="fade-up">
                {call}
              </div>
              <div class="view_plan_inner aos-item aos-init" data-aos="fade-up">
                <a class="go_btn view_plan_btn chat_btn">
                  <i class="fal fa-long-arrow-right"></i>
                  {btn}
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="desktop_questions">
        <div
          class="agent_title buytravel_title question_title aos-item aos-init aos-animate"
          data-aos="fade-up"
        >
          {heading}
        </div>
        <div
          class="insurance_title aos-item aos-init aos-animate"
          data-aos="fade-up"
        >
          {ReactHtmlParser(subHeading)}
        </div>
        <div class="call_title aos-item aos-init" data-aos="fade-up">
          {call}
        </div>
        <div class="view_plan_inner aos-item aos-init" data-aos="fade-up">
          <a class="go_btn view_plan_btn chat_btn">
            <i class="fal fa-long-arrow-right"></i>
            {btn}
          </a>
        </div>
      </div>
    </div>
  );
}

export { ExpertSection };
