import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getLiveStory } from "../../actions";
import isEmpty from "../../isEmpty";
import "react-multi-carousel/lib/styles.css";
import "../../index.css";
import ReactHtmlParser from "react-html-parser";
function LiveStory(props) {
  const { liveStory } = useSelector((state) => {
    return state.Choose;
  });

  const dispatch = useDispatch();

  const [heading, SetHeading] = useState("");
  const [head1, SetHead1] = useState("");
  const [head2, SetHead2] = useState("");
  const [subhead1, SetSubhead1] = useState("");
  const [subhead2, SetSubhead2] = useState("");
  const [btn1, SetBtn1] = useState("");
  const [btn2, SetBtn2] = useState("");
  const [url, SetUrl] = useState("");
  const [backImage, SetBackImage] = useState("");
  const [parag, SetParag] = useState("");

  useEffect(() => {
    dispatch(getLiveStory());
  }, [dispatch]);

  useEffect(() => {
    if (!isEmpty(liveStory)) {
      const items = liveStory.items;
      console.log(items);
      if (!isEmpty(items)) {
        SetHeading(items[0].name_en);
        SetHead1(items[1].name);
        SetHead2(items[4].name);
        SetSubhead1(items[2].name);
        SetSubhead2(items[5].name);
        SetBtn1(items[3]);
        SetBtn2(items[6]);
        
        SetUrl(items[6].url);

        SetParag(items[7].name_en);
      }
    }
  }, [liveStory]);

  return (
    <>
      <div
        class="agent_title buytravel_title in_house_title live_conf aos-item"
        data-aos="fade-up"
      >
        {heading}
      </div>

      <section class="section_space buytravel_outer testimonial_outer club_outer">
        <div class="inhouse_right ready_insurance fix_bottom aos-item" data-aos="zoom-in">
          <div
            class="agent_title buytravel_title  question_title aos-item"
            data-aos="fade-up"
          >
            {head1}
          </div>
          <div class="insurance_title fixed_height_insurance   aos-item" data-aos="fade-up">
            {ReactHtmlParser(subhead1)}       
          </div>
          <div className="inhouse_bottom">
          <div class="call_title fix_call_title aos-item" data-aos="fade-up">
                 
          </div>
          <div class="view_plan_inner aos-item" data-aos="fade-up">
            <a href={btn1.url} class="go_btn view_plan_btn chat_btn">
              <i class="fal fa-long-arrow-right"></i>
              {btn1.name}
            </a>
          </div>
          </div>
        
        </div>


        <div class="inhouse_right ready_insuranceright fix_bottom">
          <div
            class="agent_title buytravel_title question_title aos-item"
            data-aos="fade-up"
          >
            {head2}
          </div>
          <div class="insurance_title fixed_height_insurance  aos-item" data-aos="fade-up">
            {ReactHtmlParser(subhead2)}
          </div>
          <div className="inhouse_bottom">
          <div class="call_title fix_call_title aos-item" data-aos="fade-up">
            {ReactHtmlParser(parag)}         
          </div>
         
          <div class="view_plan_inner aos-item" data-aos="fade-up">
            <a href={btn2.url} class="go_btn view_plan_btn chat_btn">
              <i class="fal fa-long-arrow-right"></i>
              {btn2.name}
            </a>
          </div>
          </div>
        </div>
      </section>
    </>
  );
}

export { LiveStory };
